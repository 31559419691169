import moment from "moment";

const createDate = (date) => {
  return moment.unix(date).format("DD/MM/YYYY HH:mm");
};

export const displayTable = (data) => {
  let list = [];
  const table = data.map((val, i) => {
    return list.push({
      service: val?.service,
      description: val?.servicedesc,
      orderRef: val?.orderref,
      date: createDate(val?.createddate),
      category: val?.ordcatOrdrugclass,
      qty: val?.qty,
      uom: val?.chargeuom,
      rate: val?.rate,
      amt: val?.gross,
      status: val?.chargestatus,
      billed: val?.billstatus,
      checked: false,
      id: val?._id,
      orderdispenseid: val?.orderdispenseid,
      orderrefid: val?.orderrefid,
      chargecodeid: val?.chargeid
    });
  });
  return list;
};

export const constructNewOrder = (data) => {
  const returnData = {
    service: data?.chargeCode?.totalValue?.service ?? "---",
    description: data?.chargeCode?.totalValue?.servicedesc ?? "no-desc",
    orderRef: "no-ref",
    date: createDate(new Date(new Date()) / 1000),
    category: "--nocat--",
    qty: data?.qty?.length > 0 ? data?.qty : "1",
    uom: data?.uom?.value ?? "NaN",
    rate: data?.chargeCode?.totalValue?.rate ?? "--",
    amt: "-",
    status: "",
    billed: false,
    checked: true,
    isNew: true,
    chargecodeid: data?.chargeCode?.totalValue?._id
  };
  return returnData;
};

export const validate = (data, paymentDetails) => {
  if (
    data.billno &&
    data.billdate &&
    data.patientid &&
    data.encounterid &&
    paymentDetails?.settleNow
  ) {
    debugger;
    if (paymentDetails?.paymentType === "") {
      return {
        status: false,
        msg: "Please select the payment type",
      };
    } else {
      if (
        (paymentDetails?.paymentType?.value === "Cheque") &&
        (!paymentDetails?.docRefNo)
      ) {
        return {
          status: false,
          msg: "Please add the Doctor Ref No.",
        };
      } else {
        return {
          status: true,
          msg: "Saved",
        };
      }
    }
  } else {
    if (!paymentDetails?.settleNow) {
      return {
        status: false,
        msg: "Please settle the pending amount and then save",
      };
    } else if (!data.billno) {
      return {
        status: false,
        msg: "Bill No. has not been generated",
      };
    }
  }
};
