/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc withQueue HOC will send all the necessary Context such as AlertContext,
 * DialogContext, BackdropContext and DrawerContext as a prop.
 */

 import React, { useEffect } from "react";
 import { QueueDrawer } from "../components";
 import { Icons, Modal } from "qdm-component-library";
 import {
   AlertContext,
   DrawerMeetingContext,
   QueueManagementContext,
   VisitDetails,
 } from "../contexts";
 import { actions } from "primarycare-binder";
 import { useSelector, useDispatch } from "react-redux";
 import moment from "moment";
 import {
   AlertProps,
   checkError,
   getRole,
   getUserInfo,
   getUtcTime,
   makeQueueData,
 } from "../utils";
 import { Routes } from "../router/routes";
 import { checkWithCasbin } from "../utils/permissonHandling";
 import AssignReassign from "../screens/dashboard/assignReassign";
 import { UIColor } from "../utils";

 const withQueue = (Component) => (props) => {
   const [permission, setPermission] = React.useState({});
   const [permission_, setPermission_] = React.useState({});
  React.useEffect(() => {
    const initFun = async () => {
      let permission = await checkWithCasbin(["queueSideNav"]);
      let permission_ = await checkWithCasbin(["dashboard"]);
      setPermission(permission);
      setPermission_(permission_);
    };
     initFun();
   }, []);
   const loggedUserInfo = useSelector(
     (state) => state?.authSlice?.loggedUserInfo
   );
   const assignData = useSelector(
     (state) => state?.dashboardApiSlice
   );
   const practitionerType = useSelector(
     (state) => state?.dashboardApiSlice?.assignPractitionerType
   );
   const practitionerRole = useSelector(
     (state) => state?.dashboardApiSlice?.assignPractitionerRole
   );
   const datas = useSelector((state) => state?.dashboardApiSlice);
   const dispatch = useDispatch();
   const {
     data,
     allData,
     onHoldData,
     previousData,
     displayData,
     setData,
     setOnHoldData,
     setDisplayData,
     setPreviousData,
     setAllData,
   } = React.useContext(QueueManagementContext);
   const { setSnack } = React.useContext(AlertContext);
   const drawerMeetingContext = React.useContext(DrawerMeetingContext);
   const { setVisitDetails } = React.useContext(VisitDetails);
 
   const [isOpen, setOpen] = React.useState(false);
   const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
   const OpenSideBar = (bool) => {
     setOpen(bool);
     forceUpdate();
   };
   const [state, setState] = React.useState({
     assignModal: false,
     assignModalType: null,
     encounter_id: null,
     appointmentStart: null,
     appointmentEnd: null,
     appID: null
   });
   const putOnHold = (index = 0) => {
     const masterData = [...data];
     const newData = [...displayData];
     const onHoldVal = newData.splice(index, 1);
     updateStatus(
       onHoldVal[0].type === "nurse" ? "onhold_nurse" : "onhold",
       onHoldVal[0].token
     );
     const statusChnage = masterData.find((d) => d.token === onHoldVal[0].token);
     statusChnage.comment =
       onHoldVal[0].type === "nurse" ? "onhold_nurse" : "onhold";
     if (newData[0]) {
       const changeData = masterData.find((d) => d.token === newData[0].token);
       changeData.showBringtoTop = false;
       newData.splice(1);
       if (newData.length <= 1) {
         const newQueueData = masterData.find(
           (d) =>
             d.index > newData[0].index &&
             !(d.comment === "onhold" || d.comment === "completed_assemble")
         );
         if (newQueueData) {
           newData.push(newQueueData);
         }
       }
     }
     const newOnHoldData = [...onHoldData, ...onHoldVal];
     setData(masterData);
     setDisplayData(newData);
     setOnHoldData(newOnHoldData);
   };
 
   const updateStatus = async (status, appointmentId) => {
     const data = await dispatch(
       actions.UPDATE_APPOINTMENT_STATUS({
         appointmentId,
         status,
       })
     );
     const { isError } = checkError(data?.payload);
     if (isError) {
       return false;
     }
     return true;
   };
 
   const fetchUserInfo = async (email) => {
     if (!loggedUserInfo?.data?.orgId) {
       if (!email) {
         const info = getUserInfo();
         email = info.email;
       }
       const userData = await dispatch(actions.SEARCH_PRACTIONER({
         email,
       }));
    
       const info = {
         orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
         name: {
           given: userData?.payload?.data?.[0]?.name?.[0]?.given,
           family: userData?.payload?.data?.[0]?.name?.[0]?.family,
           text: userData?.payload?.data?.[0]?.name?.[0]?.text,
         },
         practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
         role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
         org_id: userData?.payload?.data?.[0]?.org_id,
       };
       await dispatch(actions.LOGGED_USER_INFO(info));
       return info;
     }
     return loggedUserInfo?.data;
   };
 
   const fetchData = async () => {
    const permission__ = await checkWithCasbin(["dashboard"]);
     await dispatch(actions.DISPOSITION_TYPE());
     await dispatch(actions.FOLLOWUPUOM());
     await dispatch(actions.REASON_MASTER());
    //  if (
    //    (data || []).length === 0 &&
    //    (previousData || []).length === 0 &&
    //    (onHoldData || []).length === 0
    //  ) {
       const info = getUserInfo();
       const userData = await fetchUserInfo(info.email);
       const specialtyId = await await dispatch(actions?.GET_SPECIALITY_ID_BY_PRCTITIONER_ID({
        practitionerId: userData?.practionerId
       }));
       debugger
       const isDoctor =  await permission__?.write?.indexOf("doctorQueue") > -1;
       const isNurse = await permission__?.write?.indexOf("nurseQueue") > -1;
       const isFrontDesk = await permission__?.write?.indexOf("frontDeskQueue") > -1;
       const fetchData = await dispatch(
         actions.QUEUE_MANAGEMENT({
           start: getUtcTime(moment().startOf("day")),
           end: getUtcTime(moment().endOf("day")),
           OrgID: userData?.payload?.data?.[0]?.[0]?.OrgID?.[0] || 2,
           login: userData?.practionerId,
           specialtyId: specialtyId?.payload?.data?.result ?? 0,
           isDoctor: isDoctor,
           isNurse: isNurse,
           isFrontDesk: isFrontDesk,
           nameOfOrg:localStorage.getItem("nameOfOrg")
         })
       );
       const { isError } = checkError(fetchData.payload);
       if (isError) {
         setSnack({
           open: true,
           severity: AlertProps.severity.error,
           msg: "Unable to fetch the Queue's.",
           vertical: AlertProps.vertical.top,
           horizontal: AlertProps.horizontal.right,
         });
       } else {
         if (Array.isArray(fetchData?.payload?.data)) {
           const appInfo = JSON.parse(JSON.stringify(fetchData.payload.data));
           const permissionA = await checkWithCasbin(["queueSideNav"]);
           const permissionData = permissionA.write.find(
             (d) =>
               d === "appointmentQueueDataN" ||
               d === "appointmentQueueDataD" ||
               d === "appointmentQueueDataF"
           );
           const [
             returnData,
             displayData,
             onHoldData,
             completedData,
             everyData,
           ] = makeQueueData(permissionData, appInfo, userData);
           
           setAllData(everyData);
           setData(returnData);
           setDisplayData(displayData);
           setOnHoldData(onHoldData);
           setPreviousData(completedData);
         }
       }
    //  } else {
    //    const userData = await fetchUserInfo();
    //    const permissionA = await checkWithCasbin(["queueSideNav"]);
    //    const permissionData = permissionA.write.find(
    //      (d) =>
    //        d === "appointmentQueueDataN" ||
    //        d === "appointmentQueueDataD" ||
    //        d === "appointmentQueueDataF"
    //    );
    //    const [, displayData, onHoldData, completedData] = makeQueueData(
    //      permissionData,
    //      allData,
    //      userData,
    //      false
    //    );
    //    setDisplayData(displayData);
    //    setOnHoldData(onHoldData);
    //    setPreviousData(completedData);
    //  }
   };
 
   useEffect(() => {
     fetchData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
 
   const attendPatient = async(
     personId,
     patientId,
     appointmentId,
     encounterId,
     type,
     pId,
     encounter_id,
     app
   ) => {
      let status;
      let isCompleted;
      if (app[0]?.type === "nurse") {
        status = "inprogress_nurse";
        isCompleted = false;
      } else if (app[0]?.type === "doctor") {
        status = "doctor_inprogress";
        isCompleted = false;
      }
      
      await dispatch(actions.UPDATE_APPOINTMENT_STATUS({
        appointmentId,
        status,
        isCompleted,
      }))
     
     props.history.push({
       pathname: Routes.patientDetail,
       state: {
         encounter_id,
         personId,
         patientId,
         appointmentId,
         encounterId,
         type,
         pId,
         app_id: app?.[0]?.info?._id,
       },
     });
   };
 
   const registerPatient = (personId, patientId, appointmentId, isPatient) => {
     setVisitDetails({
       encounter: {
         mode: "direct",
       },
       practitioner: {},
       device: {},
       helthcare: {},
       accompained: {},
     });
     if (isPatient) {
       props.history.push({
         pathname: Routes.patientVisitDetails,
         state: {
           personId,
           from: 1,
           patientId,
           appointmentId,
           Ispatient: true,
           patientData: displayData?.[0]?.mobileNo,
         },
       });
     } else {
       props.history.push({
         pathname: Routes.assemblePatioentRegister,
         state: {
           nextRoute: Routes.patientVisitDetails,
           personId,
           patientId,
           from: 1,
           appointmentId,
           patientData: displayData?.[0]?.mobileNo,
           Ispatient: false,
           idedit: false,
           fromQueue: true,
         },
       });
     }
   };
 
   const closeConsultation = async (type, data_) => {
      if (data_?.encounter_status?.toLowerCase() === "finished") {
        setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Close Consultation Done Already.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
     }
     let payload = {
       start: data_?.info?.start ?? "",
       end: data_?.info?.end,
       encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
     };
     await dispatch(actions.CLOSE_CONSULTATION(payload));
    
     setOpen(false);
     setState({
       ...state,
       assignModal: true,
       assignModalType: "close",
       encounter_id: data_?.info?.encounter?.[0]?._key,
       appointmentStart: data_?.info?.start,
       appointmentEnd: data_?.info?.end,
     });
     
   };
   const assignPractitioner = async (type, data_) => {
     debugger
     let d = await dispatch(
       actions.ASSIGN_LIST({
         start: data_?.info?.start ?? "",
         end: data_?.info?.end,
         encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
         type: type,
       })
     );
 
     setOpen(false);
     setState({
       ...state,
       assignModal: true,
       assignModalType: type,
       encounter_id: data_?.info?.encounter?.[0]?._key,
       appointmentStart: data_?.info?.start,
       appointmentEnd: data_?.info?.end,
       appID: data_?.info?._key
     });
   };
   const onModalClose = async (value) => {
     setState({
       assignModal: value,
       assignModalType: null,
       encounter_id: null,
       appID: null
     });
   };
   return (
     <div>
       <div className="queue-style">
         <QueueDrawer
           permission={permission}
           previous={
             previousData.length > 0
               ? [
                   {
                     ...previousData[0],
                     title: "Previous",
                     role_: displayData[0]?.type,
                     assignButtonAction: () => {
                       assignPractitioner("reassign", previousData[0]);
                     },
                   },
                 ]
               : []
           }
           attend={
             displayData[0]
               ? [
                   {
                     title: "To Attend ",
                     token: displayData[0]["token"],
                     profile_pic: displayData[0]["profile_pic"],
                     name: displayData[0]["name"],
                     role: displayData[0]["role"],
                     role_: displayData[0]?.type,
                     encounter: displayData[0].encounter,
                     patientPriority: displayData[0].patientPriority,
                     consultationMode: displayData[0]?.consultationMode,
                     start:
                       displayData[0]?.type === "nurse" ||
                       displayData[0]?.type === "doctor"
                         ? ""
                         : displayData[0].start,
                     primaryButtonName:
                       displayData[0]?.type === "nurse" ||
                       displayData[0]?.type === "doctor"
                         ? "Attend"
                         : !displayData[0]?.isPatient
                         ? "Register Patient"
                         : "Register Visit",
                     isRegister:
                       displayData[0]?.type === "nurse" ||
                       displayData[0]?.type === "doctor"
                         ? `EN-${displayData[0]?.encounter} • New Patient`
                         : displayData[0]?.isPatient
                         ? "Registered"
                         : "Unregistered",
                     primaryButtonAction: () => {
                       if (
                         displayData?.[0]?.consultationMode ===
                           "Video Consultation" &&
                         !drawerMeetingContext?.open
                       ) {
                         props.history.push({
                           pathname: Routes.videoCall,
                           state: {
                             appointmentId: displayData[0].info?._key,
                             name: loggedUserInfo?.data?.name?.text,
                             role: loggedUserInfo?.data?.role,
                             data: displayData[0],
                           },
                         });
                       } else {
                         if (
                           displayData[0]?.type === "nurse" ||
                           displayData[0]?.type === "doctor"
                         ) {
                           attendPatient(
                             displayData[0].personId,
                             displayData[0].patientId,
                             displayData[0].token,
                             displayData[0].encounterId,
                             displayData[0]?.type,
                             displayData[0].pId,
                             displayData[0].encounter_id,
                             displayData
                           );
                         } else {
                           registerPatient(
                             displayData[0].personId,
                             displayData[0].patientId,
                             displayData[0].token,
                             displayData[0]?.isPatient
                           );
                         }
                       }
                     },

                     secondaryButtonName: "Put on Hold",
                     secondaryButtonAction: (indx) => putOnHold(indx),
                     secondaryButtonIcon: "images/icons8-pause-button.svg",
                     isEmergency: false,
                     assignButtonAction: () => {
                       let assigned =
                         displayData[0]?.info?.encounter?.[0]?.participant
                           ?.length > 0
                           ? true
                           : false;
                       assignPractitioner(
                         assigned ? "reassign" : "assign",
                         displayData[0]
                       );
                     },
                     assignButtonIcon:
                       displayData[0]?.info?.encounter?.[0]?.participant
                         ?.length > 0
                         ? "images/icons8-repeat.svg"
                         : "images/icons8-change-user.svg",
                     assigned:
                       displayData[0]?.info?.encounter?.[0]?.participant
                         ?.length > 0
                         ? true
                         : false,
                     //assignButtonName: "Assign",

                     totalData: displayData[0],
                   },
                 ]
               : []
           }
           next={[...displayData].splice(1, 1).map((n) => {
             return {
               title: "Up Next",
               token: n.token,
               profile_pic: n?.["profile_pic"],
               name: n.name,
               role: n.role + (n.type === "nurse" ? " • New Patient" : ""),
               isEmergency: n.isEmergency,
               start: n.type === "nurse" ? "" : n.start,
               encounter: n.encounter,
               patientPriority: n.patientPriority,
               assignButtonAction: () => {
                 let assigned =
                   n.info?.encounter?.[0]?.participant?.length > 0
                     ? true
                     : false;
                 assignPractitioner(assigned ? "reassign" : "assign", n);
               },
               assignButtonIcon:
                 n.info?.encounter?.[0]?.participant?.length > 0
                   ? "images/icons8-repeat.svg"
                   : "images/icons8-change-user.svg",
               assigned:
                 n.info?.encounter?.[0]?.participant?.length > 0 ? true : false,
               role_: n.type,
               totalData: n,
               // isclinic: displayData[0]?.info?.resourcetype === "Practitioner" ? false : true,
             };
           })}
           pendingData={data}
           onHoldData={onHoldData}
           pastData={previousData}
           open={isOpen}
           onClose={() => OpenSideBar(false)}
           assignPractitioner={assignPractitioner}
           closeConsultation={(t, v) => closeConsultation(t, v)}
         />
       </div>
       {permission_?.write?.indexOf("queue") > -1 && (
         <div
           style={{
             position: "fixed",
             right: "0px",
             top: "139px",
             background: UIColor.primaryColor.color,
             padding: "10px 6px 10px 9px",
             borderTopLeftRadius: "12px",
             borderBottomLeftRadius: "12px",
             color: "white",
             cursor: "pointer",
             zIndex: 3,
           }}
           onClick={() => OpenSideBar(true)}
         >
           <Icons fontIcon="chevron-left" />
         </div>
       )}
       <div>
         <Component {...props}>{props.children}</Component>
       </div>

       <Modal
         id={`assign_modal_popup`}
         open={state?.assignModal}
         onClose={() => onModalClose(false)}
         width={1000}
         inLineStyles={{
           borderRadius: 16,
         }}
       >
         <AssignReassign
           parent_id={"doctor_card_detail"}
           title={
             state?.assignModalType === "assign"
               ? "Assign Practitioner"
               : state?.assignModalType === "reassign"
               ? "Re-Assign Practitioner"
               : "Close Consultation"
           }
           type={state?.assignModalType}
           encounter_id={state?.encounter_id}
           appID={state?.appID}
           assignData={
             state?.assignModalType === "close"
               ? assignData?.closeConsultation?.data
               : assignData?.assignList?.data
           }
           practitionerType={practitionerType?.data ?? []}
           practitionerRole={practitionerRole?.data ?? []}
           onModalClose={() => onModalClose(false)}
           appointmentStart={state?.appointmentStart}
           appointmentEnd={state?.appointmentEnd}
           dispositionData={datas?.dispositionType?.data ?? []}
           followupUOMData={datas?.followupUOM?.data ?? []}
           reasonData={datas?.reasonMaster?.data ?? []}
           showPractitioner={state?.assignModalType === "close" ? true : false}
           Role={(permission?.write?.indexOf("closeConsultationN") > -1) ? 2 :(permission?.write?.indexOf("closeConsultationD") > -1) ? 1 :null }
           fetQueData={fetchData}
         />
       </Modal>
     </div>
   );
 };
 
 export default withQueue;
 
