import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Popup from "@material-ui/core/Popover";
import {
	Card,
	Row,
	Col,
	Div,
	Avatar,
	H6,
	Divider,
	Text,
	Icons,
	WeekCalendar,
	Tab,
	Tabpanel,
	Button,
	Modal,
} from "qdm-component-library";
import { DoctorProfile } from "../../components";
import moment from "moment";
import { Calendar } from "../../assets/img/calendar.js";
import {
	checkError,
	convertTime,
	getTimeSlot,
	getTimeString,
	getImgUrl,
} from "../../utils/helperFunctions";
import Place from "../../assets/img/place.svg";
import Tapcomp from "./TapSelect";
import "react-calendar/dist/Calendar.css";
import Calendars from "react-calendar";
import ClinicProfile from "./clinicInfo";
import { getUtcTime, UIColor, utcTOLocal } from "../../utils";
import CustomDatePicker from "../customDatePicker";
const icons = {
	video: (
		<Icons
			key={"0"}
			fontIcon="video-camera"
			ariaHidden="true"
			size="small"
			style={{ color: "#B6B6B6" }}
		></Icons>
	),
	direct: (
		<Icons
			key={"0"}
			fontIcon="user"
			ariaHidden="true"
			size="small"
			style={{ color: "#B6B6B6" }}
		></Icons>
	),
	suggested: (
		<Icons
			key={"0"}
			fontIcon="star"
			ariaHidden="true"
			size="small"
			style={{ color: "#0071F2" }}
		></Icons>
	),
};

const styles = (theme) => ({
	weekcalendar: {
		"& span": {
			fontSize: 14,
			"& i": {
				fontSize: "24px !important",
			},
		},
		"& .month": {
			fontSize: 12,
			fontFamily: "pc_regular",
			backgroundColor: UIColor.lineBorderFill.color,
			color: '#6A7888'
		},
		"& .fadedDay": {
			color: "#B6B6B6",
			fontSize: 12,
			fontFamily: "pc_regular",
		},
		"& .activeDay": {
			color: "#000000",
			fontSize: 12,
			fontFamily: "pc_regular",
		},
		"& .fadedDate": {
			color: "#B6B6B6",
			// fontSize:16,
			fontFamily: "pc_medium",
		},
		"& .activeDate": {
			// color:'#000000',
			// fontSize:16,
			fontFamily: "pc_medium",
		},
	},
	TapSelect: {
		"& div": {
			padding: "2px 6px",
			"& span": {
				fontSize: 13,
				fontWeight: "600",
				letterSpacing: "0.9px",
			},
		},
	},
	hrTag: {
		margin: "0 !important",
	},

	monthCalendar: {
		"& strong": {
			fontSize: 13,
		},
		"& span": {
			fontSize: 13,
		},
		backgroundColor: "#fff",
	},
	clear: {
		backgroundColor: "transparent",
		border: "none",
		padding: "9px 12px",
		cursor: "pointer",
		color: "#6F6F6F",
		fontSize: 14,
	},
	apply: {
		backgroundColor: "transparent",
		border: "none",
		padding: "9px 12px",
		cursor: "pointer",
		color: "#0071F2",
		fontSize: 14,
	},
	divider: {
		minWidth: "0px",
		marginRight: "10px",
		marginLeft: "10px",
		color: "#B6B6B6",
		marginBottom: 0,
		marginTop: 0,
	},
	tabs: {
		"& .inActiveChildHead": {
			fontSize: 14,
			fontFamily: "pc_regular",
		},
		"& .inActiveChildSubHead": {
			fontFamily: "pc_regular",
		},
		"& .activeChildSubHead": {
			fontFamily: "pc_regular",
		},
		"& .activeChildHead": {
			fontSize: 14,
			fontFamily: "pc_medium",
		},
	},
	earliestInput: {
		fontFamily: "pc_regular",
		fontSize: 14,
		"&::placeholder": {
			fontFamily: "pc_regular",
			fontSize: 14,
			color: "#b6b6b6",
		},
	},
});
class DoctorCardDetail extends React.Component {
	constructor(props) {
		super(props);
		const data = JSON.parse(JSON.stringify(this.props.data));
		const obj = this.filterSlots(
			data.resourceInfo,
			data.slots,
			data.morning,
			data.afternoone,
			data.evening,
			data.night,
			data.allSession,
			data.isClinic,
			data.healthCareId,
			data.isUpdate,
			data.selectedSlot
		);
		this.state = {
			data: { ...data } || {},
			date: moment(
				this.props.selectedDate
					? this.props.selectedDate
					: this.props.data?.weekCalendar
			).format("MM-DD-YYYY"),
			tempdate: moment(
				this.props.selectedDate
					? this.props.selectedDate
					: this.props.data?.weekCalendar
			).format("MM-DD-YYYY"),
			showCotButton: false,
			...obj,
			slotSelect: null,
			openCloseCalendar: false,
			monthCalendarInputValue: "",
			openDoctorProfile: false,
			openClinicProfile: false,
			dirty: false,
			clinicData: {},
		};
	}

	filterSlots = (
		resourceInfo,
		slots,
		morning,
		afternoone,
		evening,
		night,
		allSession,
		isClinic,
		healthCareId,
		isUpdate = false,
		selectedSlot = 0
	) => {
		const obj = {
			selectedTimeSlot: {},
			selectedTime: getTimeSlot(
				moment(this.props.data?.weekCalendar).format("DD-MM-YYYY")
			),
		};
		let oldSlotData;
		slots &&
			slots.forEach((slot) => {
				if (slot) {
					let {
						start,
						id: slotId,
						status,
						end,
						isdirect = false,
						issuggest = false,
						isvideo = false,
						Maxbooking = 0,
						Maxwaiting = 0,
						bookedCount = 0,
						maxgeneral = 0,
						maxwalkin = 0,
						appointmentType,
						_id,
					} = slot;
					const preferedSlotType = appointmentType
						.split(",")
						.map((a) => a.toLowerCase());
					if (status !== "closed" && status !== "booked") {
						//const slotTime = new Date(start * 1000);
						const slotTime = utcTOLocal(start).toDate();
						const hours = slotTime.getHours();
						let label = getTimeString(hours, slotTime.getMinutes());
						if (isClinic) {
							const endTime = new Date(end * 1000);
							const endHours = endTime.getHours();
							const endTimeLabel = getTimeString(
								endHours,
								endTime.getMinutes()
							);
							label = `${label} - ${endTimeLabel}`;
						}
						if (
							preferedSlotType.includes("direct") ||
							preferedSlotType.includes("both")
						) {
							isdirect = true;
						}
						if (
							preferedSlotType.includes("video") ||
							preferedSlotType.includes("both")
						) {
							isvideo = true;
						}
						const iconsArr = [];
						// const icon = <VideoConsultation />;
						if (isdirect) {
							iconsArr.push(icons["direct"]);
						}
						if (isvideo) {
							iconsArr.push(icons["video"]);
						}
						if (issuggest) {
							iconsArr.push(icons["suggested"]);
						}
						const slotData = {
							value: slotId,
							label,
							_id,
							date: slotTime.getTime(),
							dateEnd: end,
							dateStart: start,
							status,
							isdirect,
							issuggest,
							isvideo,
							booked: bookedCount,
							maxBooking: Maxbooking,
							maxWaiting: Maxwaiting,
							waiting: bookedCount > Maxbooking ? bookedCount - Maxbooking : 0,
							icon: iconsArr,
							healthCareId,
							maxgeneral,
							isWalkin:
								moment()
									.startOf("day")
									.diff(moment(start * 1000), "d") === 0
									? true
									: false,
							maxwalkin,
							...resourceInfo,
						};
						if (isClinic) {
							allSession.push(slotData);
						} else {
							if (slot?.DayType?.display?.toLowerCase() === "morning") {
								morning.push(slotData);
							} else if (slot?.DayType?.display?.toLowerCase() === "afternoon") {
								afternoone.push(slotData);
							} else if (slot?.DayType?.display?.toLowerCase() === "evening") {
								evening.push(slotData);
							} else if (slot?.DayType?.display?.toLowerCase() === "night") {
								night.push(slotData);
							} else {
								morning.push(slotData);
							}
						}
						if (isUpdate) {
							if (slotId === selectedSlot) {
								oldSlotData = {
									isUpdate: true,
									info: slotData,
									hospitalInfo: this.props.data,
									appInfoFetched: {},
								};
								obj.selectedTime = getTimeSlot(start, "unix", true);
							}
						}
					}
				}
			});
		if (isUpdate) {
			if (!oldSlotData) {
				const {
					start,
					id: slotId,
					status,
					end,
					isdirect = false,
					issuggest = false,
					isvideo = false,
					Maxbooking = 0,
					Maxwaiting = 0,
					bookedCount = 0,
					maxgeneral = 0,
					maxwalkin = 0,
					_id,
				} = (this.props.oldAppointment?.Slots?.[0] || {});
				const slotTime = start ? new Date(start * 1000) : new Date();
				const hours = slotTime.getHours();
				let label = getTimeString(hours, slotTime.getMinutes());
				const slotInfo = {
					value: slotId,
					label,
					_id,
					date: slotTime.getTime(),
					dateEnd: end,
					dateStart: start,
					status,
					isdirect,
					issuggest,
					isvideo,
					booked: bookedCount,
					maxBooking: Maxbooking,
					maxWaiting: Maxwaiting,
					waiting: bookedCount > Maxbooking ? bookedCount - Maxbooking : Maxwaiting,
					icon: [],
					healthCareId,
					maxgeneral,
					isWalkin:
						moment()
							.startOf("day")
							.diff(moment(start * 1000), "d") === 0
							? true
							: false,
					maxwalkin,
				};
				oldSlotData = {
					isUpdate: true,
					info: slotInfo,
					hospitalInfo: this.props.data,
					appInfoFetched: {},
				};
			}
			this.props.handleChange("oldSlot", oldSlotData);
		}
		return obj;
	};

	updateSlot = (info, date) => {
		const slot = JSON.parse(JSON.stringify(info));
		this.filterSlots(
			slot.resourceInfo,
			slot.slots,
			slot.morning,
			slot.afternoone,
			slot.evening,
			slot.night,
			slot.allSession,
			slot.isClinic,
			slot.healthCareId
		);
		const state = { ...this.state };
		state.data.morning = slot.morning;
		state.data.afternoone = slot.afternoone;
		state.data.evening = slot.evening;
		state.data.night = slot.night;
		state.data.allSession = slot.allSession;
		state.date = date;
		state.selectedTime = getTimeSlot(date);
		this.setState(state);
	};

	changeState = async (key, value, _key) => {
		if (key === "openClinicProfile") {
			const data = await this.props.READ_CLINICINFO({ key: _key });
			this.setState({
				...this.state,
				clinicData: data?.payload,
			});
		}
		this.setState({
			[key]: value,
		});
	};

	async componentDidMount() {
		const data = await this.props.READ_CLINICINFO();
		this.setState({
			...this.state,
			clinicData: data?.payload,
		});
	}

	async handleSlotAvailability(obj) {
		if (obj.value) {
			this.changeState("dirty", true);
			const data = await this.props.SLOT_AVAILABILITY({ slotId: obj.value });
			const { isError, errMsg } = checkError(data?.payload);
			if (!isError) {
				if (
					Array.isArray(data?.payload?.data) &&
					data.payload.data[0]?.status !== "closed" &&
					data.payload.data[0]?.status !== "booked"
				) {
					const selectedSlot = {
						info: JSON.parse(JSON.stringify(obj)),
						hospitalInfo: JSON.parse(JSON.stringify(this.props.data)),
					};
					this.setState({
						...this.state,
						selectedTimeSlot: selectedSlot,
					});
					// this.props.handleChange("selectedSlot", selectedSlot);
					this.props.handleChange("selectedSlotId", obj._id);
					this.props.handleChange("selectedSlotOrd", this.props.data._id);
				} else {
					alert("Slot is Overbooked");
				}
			} else {
				this.setState({
					...this.state,
					selectedTimeSlot: {},
					slotSelect: "",
				});
				console.error(errMsg);
			}
			this.setState({
				...this.state,
				showCotButton: true,
				// selectedTimeSlot: {},
				slotSelect: obj.value,
			});
		} else {
			this.setState({
				...this.state,
				showCotButton: false,
				selectedTimeSlot: {},
				slotSelect: "",
			});
			this.props.handleChange("selectedSlot", {});
		}
	}

	filterCalendar = (e) => {
		this.setState({
			...this.state,
			openCloseCalendar: e.currentTarget,
		});
	};
	handleClose = (val) => {
		this.setState({
			...this.state,
			openCloseCalendar: val,
		});
	};

	handlepapoverclose = (val) => {
		if (val) {
			this.setState({ opencalendar: null });
		}
	};
	shouldComponentUpdate(props, state) {
		return true;
	}
	componentDidUpdate(prevProps) {
		if (this.props.selectedDate !== prevProps.selectedDate) {
			this.setState({
				date: this.props.selectedDate,
			});
		}
	}
	funtionClick = async () => {
		await Promise.all([
			this.props.GENERAL_INSTRUCTION({
				org_id: this.props.data.org_Id,
			}),
		]);
		this.props.rightSideAllCardfun(true, this.state.selectedTimeSlot);
		this.props.resetMulti();
		this.setState({
			...this.state,
			showCotButton: false,
		});
	};

	onKeyDown = (e) => {
		if (
			!(
				(e.keyCode >= 48 && e.keyCode <= 57) ||
				e.keyCode === 8 ||
				e.keyCode === 46 ||
				e.keyCode === 37 ||
				e.keyCode === 39
			)
		) {
			e.preventDefault();
		}
	};
	async handleApply() {
		const { tempdate, monthCalendarInputValue, data } = this.state;
		let date = tempdate;
		if (monthCalendarInputValue) {
			date = moment(
				new Date(
					moment(tempdate, "MM-DD-YYYY").add(
						parseInt(monthCalendarInputValue),
						"days"
					)
				)
			).format("MM-DD-YYYY");
		}
		const startdate = moment(date).startOf("day")
		const enddate = moment(date).endOf("day")
		const slotInfo = await this.props.READ_SLOT({
			id: data?.isClinic ? data.clinicId : data.id,
			startdate: getUtcTime(startdate),
			enddate: getUtcTime(enddate),
			type: data?.isClinic ? "clinicid" : "practionerid",
		});
		if (slotInfo?.payload?.data) {
			this.updateSlot(slotInfo?.payload?.data, date);
		}
		this.setState({ date }, () => {
			this.handleClose(false);
		});
	}
	render() {
		const { data, selectedTime, clinicData } = this.state;
		const { classes, parent_id } = this.props;
		const open = Boolean(this.state.openCloseCalendar);
		const id = open ? "simple-popover" : undefined;
		console.log("keyvalue_load", this.props.data);
		return (
			<div id={`${parent_id}_doctorcard_parent_div`}
				style={{
					backgroundColor: "#fff",
					padding: this.props.totlenght === 1 ? "10px" : this.props.keydata === 0 ? "12px 12px 0px" : this.props.keydata+1 === this.props.totlenght ? "12px 12px 20px" : "12px 12px 0px",
					// padding: "12px",
					borderRadius: this.props.totlenght === 1 ? "10px" : this.props.keydata === 0 ? "10px 10px 0px 0px" : this.props.keydata+1 === this.props.totlenght ? "0px 0px 10px 10px" : 0,
					boxShadow: "0px 10px 25px #0000000A"
				}}
			  >
				<Card
					id={`${parent_id}_doctorcard_parent_card`}
					key={"0"}
					children="Card"
					style={{
						padding: "16px",
						// marginBottom: "16px",
						backgroundColor: "#fff",
						borderRadius: "8px",
						width: "100%",
						border: "1px solid #e0e0e0",
						boxShadow: "none",
					}}
				>
					<Row id={`${parent_id}_doctorcard_parent_row`} key={"0"}>
						<Col
							id={`${parent_id}_doctorcard_parent_col`}
							key={"0"}
							name="qdm-col-12"
							alignItems="stretch"
							direction="row"
							justify="flex-start"
							textAlign="left"
							lg="5"
							xs="12"
							md="5"
							sm="12"
							style={{ padding: "0px", display: "flex" }}
						>
							<Div
								id={`${parent_id}_doctorcard_img_div`}
								key={"0"}
								style={{ width: "100%" }}
							>
								<Col
									id={`${parent_id}_doctorcard_img_col`}
									key={"0"}
									name="qdm-col-12"
									alignItems="stretch"
									direction="row"
									justify="flex-start"
									textAlign="left"
									lg="12"
									xs="12"
									md="12"
									sm="12"
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "flex-start",
										paddingLeft: 0,
									}}
								>
									<Div
										id={`${parent_id}_doctorcard_img_sub_div`}
										key={"0"}
										inLineStyles=""
										className="qdm"
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "flex-start",
											marginBottom: 16,
										}}
									>
										<Avatar
											id={`${parent_id}_doctorcard_image`}
											key={"0"}
											variant="circle"
											letter="Avatar"
											src={
												data?.img ||
												"https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"
											}
											alt="Image"
											style={{
												margin: "0px",
												width: 40,
												height: 40,
												backgroundColor: "#6F6F6F",
											}}
										></Avatar>

										<Div
											id={`${parent_id}_doctorcard_title_div`}
											key={"1"}
											style={{
												marginLeft: 16,
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Text
												id={`${parent_id}_${data?.name}_doctorcard_text`}
												style={{
													fontSize: 16,
													color: "#001C3C",
													letterSpacing: 0,
												}}
												key={"0"}
												name={data?.name}
												className={"pc_medium"}
											></Text>
											{/* <br /> */}
											{data?.subtext && (
												<Text
													id={`${parent_id}_${data?.subtex}_doctorcard_text`}
													key={"1"}
													name={data?.subtext}
													style={{
														fontSize: "12px",
														marginTop: 4,
														color: data.isDoctor ? "#001C3C" : "#6f6f6f",
													}}
													className={"pc_regular"}
												></Text>
											)}
											{/* <br /> */}
											{data?.title && (
												<Text
													id={`${parent_id}_${data?.title}_doctorcard_text`}
													key={"2"}
													name={data?.title}
													style={{
														fontSize: "12px",
														color: "#6F6F6F",
														marginTop: "4px",
													}}
												></Text>
											)}
										</Div>
									</Div>

									<Div
										id={`${parent_id}_${
											(data?.price ? "₹ " : "") + data?.price
										}_doctorcard_div`}
										key={"1"}
									>
										<Text
											id={`${parent_id}_${
												(data?.price ? "₹ " : "") + data?.price
											}_doctorcard_text`}
											style={{ fontSize: 16, fontWeight: "800" }}
											key={"0"}
											name={(data?.price ? "₹ " : "") + data?.price}
										></Text>
									</Div>
								</Col>
								<div
									id={`${parent_id}_doctorcard_divider_div`}
									key={"1"}
									style={{
										padding: "0px",
										margin: 0,
										height: 1,
										width: "100%",
									}}
								>
									<Divider
										id={`${parent_id}_doctorcard_divider`}
										key={"0"}
										className={classes.hrTag}
									></Divider>
								</div>
								{data.isDoctor && (
									<Col
										id={`${parent_id}_doctorcard_language_col`}
										key={"2"}
										style={{ padding: "16px 0px" }}
									>
										<Text
											id={`${parent_id}_doctorcard_language_text`}
											key={"0"}
											name="Languages :"
											style={{
												color: "#6A7888",
												marginRight: "8px",
												fontSize: 14,
												letterSpacing: ".9px",
											}}
											className={"pc_regular"}
										></Text>

										<Text
											id={`${parent_id}_doctorcard_${data?.language}_language_text`}
											key={"1"}
											style={{
												fontSize: 14,
												fontWeight: 500,
												letterSpacing: ".9px",
												color: "#6A7888",
											}}
											name={data?.language?.join(", ")}
											className={"pc_regular"}
										></Text>
									</Col>
								)}
								{data.isClinic && (
									<Col
										id={`${parent_id}_doctorcard_clinic_col`}
										key={"3"}
										name="qdm-col-12"
										alignItems="stretch"
										direction="row"
										justify="flex-start"
										textAlign="left"
										lg="12"
										xs="12"
										md="12"
										sm="12"
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											padding: "16px 0px",
										}}
									>
										<Div
											id={`${parent_id}_doctorcard_clinic_doctor_div`}
											key={"0"}
										>
											<Text
												id={`${parent_id}_doctorcard_clinic_doctor_text`}
												key={"0"}
												name={`Includes ${data?.memberIncludes || 0} Doctors`}
												// name={"Includes 6 Doctors"}
												style={{ fontSize: "16px", letterSpacing: 0 }}
												className={"pc_medium"}
											></Text>
										</Div>

										<Div
											id={`${parent_id}_doctorcard_clinic_image_div`}
											key={"1"}
											className="qdm"
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												marginLeft: "",
											}}
										>
											<Avatar
												id={`${parent_id}_doctorcard_clinic_image`}
												key={"0"}
												variant="circle"
												letter="Avatar"
												src={
													data?.memberIncludesImg?.find((img) => !!img) ||
													"https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"
												}
												alt="Image"
												style={{
													margin: "0px",
													marginRight: "0px",
													width: 40,
													height: 40,
												}}
											></Avatar>

											<Text
												id={`${parent_id}_doctorcard_clinic_text`}
												key={"1"}
												name={
													data?.memberIncludes > 1
														? `+ ${data?.memberIncludes - 1 || 0}`
														: ""
												}
												style={{
													marginLeft: "10px",
													fontSize: "14px",
													fontWeight: "600",
												}}
											></Text>
										</Div>
									</Col>
								)}
								<Col
									id={`${parent_id}_doctorcard_clinic_col`}
									key={"4"}
									name="qdm-col-12"
									alignItems="stretch"
									direction="row"
									justify="flex-start"
									textAlign="left"
									lg="12"
									xs="12"
									md="12"
									sm="12"
									style={{ padding: "0px" }}
								>
									<Divider
										id={`${parent_id}_doctorcard_clinic_bottom_divider`}
										key={"0"}
										className={classes.hrTag}
									></Divider>
								</Col>
								<Col
									id={`${parent_id}_doctorcard_hospitalname_col`}
									key={"5"}
									name="qdm-col-12"
									alignItems="stretch"
									direction="row"
									justify="flex-start"
									textAlign="left"
									lg="12"
									xs="12"
									md="12"
									sm="12"
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										padding: 0,
										marginTop: 16,
									}}
								>
									<Col
										id={`${parent_id}_doctorcard_hospitalname_col_one`}
										key={"0"}
										lg="9"
										xs="9"
										md="9"
										sm="9"
										style={{ padding: 0 }}
									>
										<Text
											id={`${parent_id}_doctorcard_${data?.hospitalName}_text`}
											key={"0"}
											name={data?.hospitalName}
											style={{
												fontSize: "14px",
												letterSpacing: 0,
												color: "#2A3C50",
											}}
											className={"pc_medium"}
										></Text>

										<H6
											id={`${parent_id}_doctorcard_${data?.hospitalLocation}_h6`}
											key={"1"}
											name={data?.hospitalLocation}
											style={{
												color: "#2A3C50",
												fontSize: "12px",
												letterSpacing: 0,
											}}
											className={"pc_medium"}
										></H6>
									</Col>

									<Col
										id={`${parent_id}_doctorcard_hospital_km_col`}
										lg="3"
										xs="3"
										md="3"
										sm="3"
										key={"1"}
										className="qdm"
										inLineStyles=""
										style={{
											// borderColor: "#E0E0E0",
											// borderWidth: "1px",
											// borderStyle: "solid",
											borderRadius: "6px",
											padding: "6px 8px",
											display: "flex",
											justifyContent: "center",
											backgroundColor: "#ECF0F7",
											color: "#2A3C50"
										}}
									>
										<img
											id={`${parent_id}_doctorcard_hospital_place_img`}
											alt="Place"
											src={Place}
										/>

										<Text
											id={`${parent_id}_doctorcard_hospital_${
												data?.kms ? `${data?.kms} Kms` : ""
											}_text`}
											key={"1"}
											name={data?.kms ? `${data?.kms} Kms` : ""}
											style={{ color: "", marginLeft: "6px", fontSize: "10px" }}
											className={"pc_semibold"}
										></Text>
									</Col>
								</Col>
								<Div
									id={`${parent_id}_doctorcard_hospital_div`}
									style={{ width: "100%", position: "relative" }}
								></Div>

								<Col
									id={`${parent_id}_doctorcard_view_clinic_col`}
									key={"6"}
									name="qdm-col-12"
									alignItems="stretch"
									direction="row"
									justify="flex-start"
									textAlign="left"
									lg="12"
									xs="12"
									md="12"
									sm="12"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: 0,
										position: "absolute",
										bottom: 0,
									}}
								>
									{data.isDoctor && (
										<Col
											id={`${parent_id}_doctorcard_view_doctor_col`}
											key={"2"}
											style={{
												width: "100%",
												textAlign: "center",
											}}
										>
											<Text
												id={`${parent_id}_doctorcard_view_doctor_text`}
												key={"1"}
												style={{
													fontSize: 14,
													cursor: "pointer",
													letterSpacing: ".9px",
													color: "#2A60BC",
													fontWeight: 500
												}}
												name={"View Doctor Profile"}
												className={"pc_medium"}
												onClick={() =>
													this.changeState("openDoctorProfile", true)
												}
											></Text>
										</Col>
									)}
									{data.isClinic && (
										<Col
											id={`${parent_id}_doctorcard_view_clinix_col`}
											key={"2"}
											style={{
												width: "100%",
												textAlign: "center",
											}}
										>
											<Text
												id={`${parent_id}_doctorcard_view_clinix_text`}
												key={"1"}
												style={{
													fontSize: 14,
													cursor: "pointer",
													letterSpacing: ".9px",
													color: "#0071F2",
												}}
												onClick={() =>
													this.changeState(
														"openClinicProfile",
														true,
														data.locationId
													)
												}
												name={"View Clinic Profile"}
												className={"pc_medium"}
											></Text>
										</Col>
									)}
								</Col>
							</Div>

							<Divider
								id={`${parent_id}_doctorcard_view_clinix_divider`}
								key={"1"}
								textWidth="50"
								textColor=""
								borderColor=""
								borderWidth="1"
								orientation="vertical"
								variant="middle"
								className=""
								label=""
								style={{ height: "100%" }}
							></Divider>
						</Col>

						<Col
							id={`${parent_id}_doctorcard_weekcalender_col`}
							key={"1"}
							name="qdm-col-12"
							alignItems="stretch"
							direction="row"
							justify="flex-start"
							textAlign="left"
							lg="7"
							xs="12"
							md="7"
							sm="12"
							style={{ padding: "0px" }}
						>
							<Row id={`${parent_id}_doctorcard_weekcalender_row`} key={"0"}>
								<Col
									id={`${parent_id}_doctorcard_weekcalender_format_col`}
									key={"0"}
									name="qdm-col-11"
									alignItems="stretch"
									direction="column"
									justify="flex-start"
									textAlign="left"
									lg="11"
									xs="11"
									md="11"
									sm="11"
									style={{ padding: "0px", position: "relative" }}
								>
									<WeekCalendar
										id={`${parent_id}_doctorcard_weekcalender`}
										activeColor={"#2A60BC"}
										key={"0"}
										weekStartDate={convertTime(
											Math.floor(Date.now() / 1000),
											"unix",
											"MM-DD-YYYY"
										)}
										// weekStartDate={"06-11-2021"}
										style={{ borderWidth: "0px", fontSize: 14 }}
										className={classes.weekcalendar}
										onChange={async (date) => {
											const startdate = moment(date).startOf("day")
											const enddate = moment(date).endOf("day")
											const slotInfo = await this.props.READ_SLOT({
												id: data?.isClinic ? data.clinicId : data.id,
												startdate: getUtcTime(startdate),
												enddate: getUtcTime(enddate),
												type: data?.isClinic ? "clinicid" : "practionerid",
											});
											if (slotInfo?.payload?.data) {
												this.updateSlot(slotInfo?.payload?.data, date);
											}
										}}
										currentDate={this.state.date}
										// dateValue={this.state.date}
									/>
								</Col>
								<Col
									id={`${parent_id}_doctorcard_pop_calender_col`}
									lg="1"
									xs="1"
									md="1"
									sm="1"
									inLineStyles={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										padding: 0,
									}}
								>
									<Div
										id={`${parent_id}_doctorcard_pop_calender_div`}
										style={{ height: 30, width: 2, backgroundColor: "#F0F0F0" }}
									/>
									<Popup
										id={id}
										open={open}
										anchorEl={this.state.openCloseCalendar}
										onClose={() => this.handleClose(false)}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										style={{ marginTop: "1%", marginLeft: "2%" }}
									>
										<div
											id={`${parent_id}_doctorcard_calender_div`}
											style={{
												width: 300,
												backgroundColor: "#F9F9F9",
												padding: "10px",
											}}
										>
											{/* <Calendars
												id={`${parent_id}_doctorcard_pop_calender`}
												minDate={new Date()}
												allowPartialRange
												value={new Date(this.state.tempdate)}
												onClickMonth={(value, event) => {
													this.handlepapoverclose(false);
												}}
												onClickYear={(value, event) => {
													this.handlepapoverclose(false);
												}}
												onClickWeekNumber={(value, event) => {
													this.handlepapoverclose(false);
												}}
												className={classes.monthCalendar}
												onChange={(value, event) => {
													const milli = value.getTime();
													const oldVal = this.state?.age?.milli;
													const oldDate = moment(oldVal).format("DD");
													const newDate = moment(milli).format("DD");
													const date = moment(value).format("MM-DD-YYYY");
													this.changeState("tempdate", date);
													if (oldDate !== newDate || milli === oldVal) {
														this.handlepapoverclose(true);
													}
												}}
											/> */}
											<CustomDatePicker
												minDate={new Date()}
												selectedDate={new Date(this.state.tempdate)}
												handleChange={(value) => {
													const milli = value.getTime();
													const oldVal = this.state?.age?.milli;
													const oldDate = moment(oldVal).format("DD");
													const newDate = moment(milli).format("DD");
													const date = moment(value).format("MM-DD-YYYY");
													this.changeState("tempdate", date);
													if (oldDate !== newDate || milli === oldVal) {
														this.handlepapoverclose(true);
													}
												}}
											/>
										</div>
										<div
											id={`${parent_id}_doctorcard_or_div`}
											style={{
												backgroundColor: "#F9F9F9",
											}}
										>
											<Divider
												id={`${parent_id}_doctorcard_or_divider`}
												variant="fullWidth"
												textWidth={0}
												label={"or"}
												textColor="#B6B6B6"
												borderColor="#B6B6B6"
												className={classes.divider}
											/>
										</div>
										<div
											id={`${parent_id}_doctorcard_description_div`}
											style={{
												backgroundColor: "#F9F9F9",
												textAlign: "center",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												paddingTop: "3%",
											}}
										>
											<div
												id={`${parent_id}_doctorcard_description_earliest_div`}
												style={{
													backgroundColor: "#fff",
													padding: "4px",
													border: "1px solid #E0E0E0",
													borderRadius: 4,
													width: "94%",
													display: "flex",
													justifyContent: "space-around",
													alignItems: "center",
													height: "40px",
												}}
											>
												<span
													id={`${parent_id}_doctorcard_earliest_span`}
													style={{ fontSize: 14 }}
												>
													Earliest available after
												</span>{" "}
												<input
													id={`${parent_id}_doctorcard_earliest_input`}
													style={{
														width: 40,
														height: 24,
														border: "1px solid #e0e0e0",
														outline: "none",
														borderRadius: 3,
													}}
													className={classes.earliestInput}
													value={this.state.monthCalendarInputValue}
													placeholder="000"
													maxLength={3}
													onChange={(e) =>
														this.changeState(
															"monthCalendarInputValue",
															e.target.value
														)
													}
													onKeyDown={(e) => this.onKeyDown(e)}
												/>{" "}
												<span
													id={`${parent_id}_doctorcard_days_span`}
													style={{ fontSize: 14, fontWeight: 600 }}
												>
													Days
												</span>
											</div>
										</div>
										<div
											id={`${parent_id}_doctorcard_button_div`}
											style={{ backgroundColor: "#F9F9F9" }}
										>
											<div
												id={`${parent_id}_doctorcard_button_clear_div`}
												style={{ display: "flex", justifyContent: "flex-end" }}
											>
												<button
													id={`${parent_id}_doctorcard_clear_button`}
													className={classes.clear}
													onClick={() =>
														this.changeState("monthCalendarInputValue", "")
													}
												>
													Clear
												</button>
												<button
													id={`${parent_id}_doctorcard_apply_button`}
													className={classes.apply}
													onClick={() => this.handleApply()}
												>
													Apply
												</button>
											</div>
										</div>
									</Popup>
									<Div
										id={`${parent_id}_doctorcard_filter_div`}
										style={{ marginRight: 14 }}
										onClick={(e) => this.filterCalendar(e)}
									>
										<Calendar
											id={`${parent_id}_doctorcard_filter_calender`}
											color={false ? "#0071F2" : "#6f6f6f"}
										/>
									</Div>
								</Col>
							</Row>

							<Col
								id={`${parent_id}_doctorcard_all_session_col`}
								key={"1"}
								name="qdm-col-12"
								alignItems="stretch"
								direction="row"
								justify="flex-start"
								textAlign="left"
								lg="12"
								xs="12"
								md="12"
								sm="12"
								style={{ marginTop: "14px", paddingLeft: 0, paddingRight: 0 }}
							>
								{!!data?.isAllSession ? (
									<div
										id={`${parent_id}_doctorcard_all_session_label_div`}
										key={"0"}
										label="ALL SESSION"
										title="ALL SESSION"
										style={{ minHeight: "23vh", marginBottom: "8%" }}
									>
										<div
											id={`${parent_id}_doctorcard_all_session_label_sub_div`}
										>
											<span
												id={`${parent_id}_doctorcard_all_session_label_span`}
												style={{
													color: "#0071F2",
													display: "block",
													fontSize: "14px",
													marginTop: "2px",
													borderBottom: "2px solid #0071f2",
													width: "24%",
													textAlign: "center",
													paddingBottom: 4,
													fontFamily: "pc_medium",
												}}
											>
												ALL SESSION
											</span>
										</div>
										{data?.allSession.length > 0 ? (
											data?.allSession.map((val, i) => (
												<Tapcomp
													parent_id={"doctor_listing_carddetails_" + i}
													type="clinic"
													val={val}
													dirty={this.state.dirty}
													selectedId={this.props?.data?.selectedSlot || 0}
													//stateVal={this.state.slotSelect}
													stateVal={this.props.selectedSlotId}
													isSelected={
														this.props.selectedSlotOrd === this.props.data._id
													}
													onChange={() => this.handleSlotAvailability(val)}
													rightSideAllCard={this.props.rightSideAllCard}
												/>
											))
										) : (
											<div
												style={{
													textAlign: "center",
													fontWeight: "bold",
													fontSize: "18px",
												}}
											>
												<p>Slots not available</p>
											</div>
										)}
									</div>
								) : (
									<Tab
										id={`${parent_id}_doctorcard_all_session_tap`}
										className={classes.tabs}
										key={"0"}
										align="center"
										activetabs={selectedTime}
										name=""
										label=""
										inLineTabTitleStyles=""
										fullWidth={true}
										size="small"
										backgroundColor="#3f51b5"
										elevation="0"
										color=""
										border={false}
										variant="withoutBordered"
										style={{ paddingLeft: 0, paddingRight: 0 }}
										activeColor={"#2A60BC"}
									>
										<Tabpanel
											id={`${parent_id}_doctorcard_MORNING_tabpanel`}
											key={"0"}
											label="MORNING"
											title="MORNING"
											subTitle={`${data?.morning?.length || "No"} slots`}
											color={data?.morning?.length ? "#38C20A" : "#EA1601"}
											style={{
												minHeight: "16vh",
												marginBottom: "12%",
												paddingLeft: 0,
												paddingRight: 0,
											}}
										>
											{data.morning.length > 0 ? (
												data?.morning.map((val, i) => (
													<Tapcomp
														parent_id={"doctor_listing_carddetails_" + i}
														val={val}
														dirty={this.state.dirty}
														selectedId={this.props?.data?.selectedSlot || 0}
														stateVal={this.props.selectedSlotId}
														isSelected={
															this.props.selectedSlotOrd === this.props.data._id
														}
														onChange={() => this.handleSlotAvailability(val)}
													/>
												))
											) : (
												<div
													style={{
														textAlign: "center",
														fontWeight: "bold",
														fontSize: "18px",
													}}
												>
													<p>Slots not available</p>
												</div>
											)}
										</Tabpanel>

										<Tabpanel
											id={`${parent_id}_doctorcard_AFTERNOON_tabpanel`}
											key={"1"}
											label="AFTERNOON"
											title="AFTERNOON"
											subTitle={`${data?.afternoone?.length || "No"} slots`}
											color={data?.afternoone?.length ? "#38C20A" : "#FF4F4A"}
											style={{
												minHeight: "16vh",
												marginBottom: "12%",
												paddingLeft: 0,
												paddingRight: 0,
											}}
										>
											{data?.afternoone.length > 0 ? (
												data?.afternoone.map((val, i) => (
													<Tapcomp
														parent_id={"doctor_listing_carddetails_" + i}
														val={val}
														dirty={this.state.dirty}
														selectedId={this.props?.data?.selectedSlot || 0}
														// stateVal={this.state.slotSelect}
														stateVal={this.props.selectedSlotId}
														isSelected={
															this.props.selectedSlotOrd === this.props.data._id
														}
														onChange={() => this.handleSlotAvailability(val)}
													/>
												))
											) : (
												<div
													style={{
														textAlign: "center",
														fontWeight: "bold",
														fontSize: "18px",
													}}
												>
													<p>Slots not available</p>
												</div>
											)}
										</Tabpanel>

										<Tabpanel
											id={`${parent_id}_doctorcard_EVENING_tabpanel`}
											key={"2"}
											label="EVENING"
											title="EVENING"
											subTitle={`${data?.evening?.length || "No"} slots`}
											color={data?.evening?.length ? "#38C20A" : "#FF4F4A"}
											style={{
												minHeight: "16vh",
												marginBottom: "12%",
												paddingLeft: 0,
												paddingRight: 0,
											}}
										>
											{data?.evening.length > 0 ? (
												data?.evening.map((val, i) => (
													<Tapcomp
														parent_id={"doctor_listing_carddetails_" + i}
														val={val}
														dirty={this.state.dirty}
														stateVal={this.props.selectedSlotId}
														isSelected={
															this.props.selectedSlotOrd === this.props.data._id
														}
														// stateVal={this.state.slotSelect}
														selectedId={this.props.data.selectedSlot || 0}
														onChange={() => this.handleSlotAvailability(val)}
													/>
												))
											) : (
												<div
													style={{
														textAlign: "center",
														fontWeight: "bold",
														fontSize: "18px",
													}}
												>
													<p>Slots not available</p>
												</div>
											)}
										</Tabpanel>

										<Tabpanel
											id={`${parent_id}_doctorcard_NIGHT_tabpanel`}
											key={"3"}
											label="NIGHT"
											title="NIGHT"
											subTitle={`${data?.night?.length || "No"} slots`}
											color={data?.night?.length ? "#38C20A" : "#FF4F4A"}
											style={{
												minHeight: "16vh",
												marginBottom: "12%",
												paddingLeft: 0,
												paddingRight: 0,
											}}
										>
											{data?.night.length ? (
												data?.night.map((val, i) => (
													<Tapcomp
														parent_id={"doctor_listing_carddetails_" + i}
														val={val}
														dirty={this.state.dirty}
														selectedId={this.props?.data?.selectedSlot || 0}
														isSelected={
															this.props.selectedSlotOrd === this.props.data._id
														}
														// stateVal={this.state.slotSelect}
														stateVal={this.props.selectedSlotId}
														onChange={() => this.handleSlotAvailability(val)}
													/>
												))
											) : (
												<div
													style={{
														textAlign: "center",
														fontWeight: "bold",
														fontSize: "18px",
													}}
												>
													<p>Slots not available</p>
												</div>
											)}
										</Tabpanel>
									</Tab>
								)}
							</Col>

							<Col
								id={`${parent_id}_doctorcard_bottom_col`}
								key={"2"}
								name="qdm-col-12"
								alignItems="stretch"
								direction="row"
								justify="flex-start"
								textAlign="left"
								lg="12"
								xs="12"
								md="12"
								sm="12"
								style={{
									display: "flex",
									justifyContent: "space-around",
									alignItems: "center",
									padding: "0px",
									position: "absolute",
									bottom: 0,
									backgroundColor: "#fff",
								}}
							>
								{/* <Div id={`${parent_id}_doctorcard_suggested_div`} key={"0"}>
									<Icons
										id={`${parent_id}_doctorcard_star_icons`}
										key={"0"}
										fontIcon="star"
										ariaHidden="true"
										size="small"
										style={{ color: "#0071F2" }}
									></Icons>

									<Text
										id={`${parent_id}_doctorcard_suggested_text`}
										key={"1"}
										className="pc_regular"
										name="Suggested Slot"
										style={{
											marginLeft: "8px",
											color: "#6F6F6F",
											fontSize: 12,
											letterSpacing: 0,
										}}
									></Text>
								</Div> */}
								{data?.isClinic ? null : (
									<>
										<Div
											id={`${parent_id}_doctorcard_Video_Consultation_div`}
											key={"1"}
										>
											<Icons
												id={`${parent_id}_doctorcard_Video_camera_icons`}
												key={"0"}
												fontIcon="video-camera"
												ariaHidden="true"
												size="small"
												style={{ color: "#6F6F6F" }}
											></Icons>

											<Text
												id={`${parent_id}_doctorcard_Video_Consultation_text`}
												key={"1"}
												name="Video Consultation"
												className="pc_regular"
												style={{
													marginLeft: "8px",
													color: "#6F6F6F",
													fontSize: 12,
													letterSpacing: 0,
												}}
											></Text>
										</Div>

										<Div
											id={`${parent_id}_doctorcard_Direct_Consultation_div`}
											key={"2"}
										>
											<Icons
												id={`${parent_id}_doctorcard_user_icons`}
												key={"0"}
												fontIcon="user"
												ariaHidden="true"
												size="small"
												style={{ color: "#6F6F6F" }}
											></Icons>

											<Text
												id={`${parent_id}_doctorcard_Direct_Consultation_text`}
												key={"1"}
												name="Direct Consultation"
												className="pc_regular"
												style={{
													marginLeft: "8px",
													color: "#6F6F6F",
													fontSize: 12,
													letterSpacing: 0,
												}}
											></Text>
										</Div>
									</>
								)}
							</Col>
							{this.state.showCotButton &&
							this.props.selectedSlotOrd === this.props.data._id ? (
								<div
									id={`${parent_id}_doctorcard_Continue_Booking_div`}
									style={{
										width: "100%",
										padding: "0px",
										backgroundColor: "#ffffffbf",
										position: "absolute",
										bottom: "0px",
									}}
								>
									<Button
										id={`${parent_id}_doctorcard_Continue_Booking_button`}
										// key={"7"}
										type="button"
										variant="outlined"
										name="Continue Booking"
										onClick={() => this.funtionClick()}
										style={{
											margin: "auto",
											backgroundColor: "#2A60BC",
											borderColor: "#2A60BC",
											borderRadius: "8px",
											display: "flex",
											justifyContent: "center",
											padding: "8px 14px",
											cursor: "pointer",
											marginTop: 8,
											fontSize: 14,
										}}
									></Button>
								</div>
							) : (
								""
							)}
						</Col>
					</Row>
				</Card>
				<Modal
					id={`${parent_id}_doctorcard_modal`}
					open={this?.state?.openDoctorProfile}
					onClose={() => this.changeState("openDoctorProfile", false)}
					width={520}
					inLineStyles={{
						borderRadius: 16,
					}}
				>
					{data.isDoctor && (
						<DoctorProfile
							parent_id={"doctor_card_detail"}
							name={data?.name}
							specialized="General Physician"
							courses={data?.subtext}
							speciality={data?.specialities ?? []}
							experience={data?.experience || "-----"}
							languages={
								(data?.language && data?.language?.map((_) => _)?.join(", ")) ||
								"---"
							}
							role={data?.role || "----"}
							hospitalName={data?.hospitalName}
							hospitalLocation={data?.hospitalLocation}
							mobile={data?.mobile}
							profilePic={data?.img || "https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"}
							bio={data?.bio}
						/>
					)}
				</Modal>
				<Modal
					id={`${parent_id}_${
						"_" + data?.name.replaceAll(" ", "_")
					}_clinic_card_detail_right_card_modal`}
					open={this?.state?.openClinicProfile}
					onClose={() => this.changeState("openClinicProfile", false)}
					width={500}
					inLineStyles={{
						borderRadius: 8,
					}}
				>
					{data.isClinic && (
						<ClinicProfile
							open={this.state.openClinicProfile}
							parent_id={"Clinic_card_detail"}
							facilityName={clinicData?.data?.managingOrgID[0]?.name}
							imgUrl={getImgUrl(clinicData?.data?.photo?.[0]?.fileid)}
							facilityType={
								clinicData?.data?.managingOrgEntityType?.[0]?.coding?.[0]
									?.display
							}
							currentDate={this.state.date}
							clinicName={clinicData?.data?.shortdesc}
							speciality={clinicData?.data?.specialty[0]?.display}
							description={clinicData?.data?.longdesc}
							timings={clinicData?.data?.workingdayyear}
							overRide={
								clinicData?.data?.workingdayyearoverride
							}
							workigDays={clinicData?.data?.workingdayyear}
						/>
					)}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
	connect(mapStateToProps, actions)(withRouter(DoctorCardDetail))
);
