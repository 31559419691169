import React, { useState } from "react";
import {
  Button,
  Div,
  Row,
  Col,
  Select,
  TextInput,
  MultiSelect,
  Text,
  Avatar,
} from "qdm-component-library";
import { InputBase, Grid, Popover, makeStyles } from "@material-ui/core";
import FormHeader from "./formHeader";
import { withRouter } from "react-router-dom";
import { UnitDoseForm, IVForm } from "./forms";
import {
  SelectCard,
  RequestorDetailsForm,
  InputGroup,
  SlidingScale,
  ChangeStatus,
  FrequencyList,
} from "./components";
import { CalendarIcon, SelectBox } from "../../../components";
import { withAllContexts } from "../../../HOCs";
import { AlertProps, getOrderName, UIColor } from "../../../utils";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  generateTapparing,
  generateDose,
  TwiceDaily,
  getErrorValidation,
} from "./func";
import { actions } from "primarycare-binder";
import { CreateJson, getStatusHistory } from "./generateJson";
import CustomDatePicker from "../../../components/customDatePicker";
import {setHours, setMinutes} from "date-fns";
import { NewOrderComponent } from "clmm-masters";
import Dialog from '@material-ui/core/Dialog';

const isShowTapIcon = ["Twice Daily", "EVERY THURSDAY AND FRIDAY", "TAPERING"];

const styles = {
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelBtn: {
    padding: "8px",
    fontSize: 14,
    marginRight: 10,
    borderColor: UIColor.lineBorderFill.color,
    color: UIColor.primaryColor.color,
    borderRadius: 8,
  },
  filledBtn: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    padding: "8px",
    fontSize: 14,
    borderRadius: 8,
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
  },
  labelStyle_: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
    margin: "6px 5px",
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: UIColor.error.color,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  colPadding: {
    padding: "7px",
  },
  label: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
  required: {
    color: UIColor.error.color,
    marginLeft: 5,
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  dateWrapper: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    padding: 5,
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    "&:hover": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  calenderText: {
    marginLeft: 5,
    cursor: "pointer",
    display: "flex",
  },
  dateText: {
    fontSize: 14,
    color: UIColor.secondaryText.color,
    flex: 1,
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  iconWrapper: {
    borderRadius: 8,
    width: 40,
    height: 40,
    backgroundColor: UIColor.lineBorderFill.color,
    margin: 0,
    position: "absolute",
    marginTop: 22,
  },
  patientInstructionDiv: {
    height: "82px",
    padding: "5px",
    border: "1px solid #c3c3c3",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
};

const statusColors = {
  Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Discontinue: { color: "#d60000", background: UIColor.lineBorderFill.color },
  Continue: {
    color: UIColor.primaryColor.color,
    background: UIColor.primaryColor.color,
  },
  //"Active": { color: UIColor.primaryColor.color, background: "#D4E7FD" },
  Active: { color: UIColor.success.color, background: "#fff" },
  // "Cancel": { color: "#FF4D4A", background: "#FFE1E0" }
};

const useStyles = makeStyles((theme) => ({
  deskPadding: {
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  addNewMedicationPaper: {
    padding: "16px",
    minWidth: "1100px"
  }
}));

const getAllDiagnosiis = (state, list) => {
  let arr = null;
  if (state && state.length > 0) {
    arr = list.filter((val) => state.indexOf(val.value) > -1);
  }
  return arr;
};

const MedicationForm = (props) => {
  let dispatch = useDispatch();
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const stateList = useSelector((state) => state?.MedicationMastersSlice);
  // const rulesvalidatedData = useSelector(
  //   (state) => state?.validateRulesSlice?.PFMedData
  // );
  const rulesvalidatedData = useSelector(
    (state) => state?.validateRulesSlice?.PFMedData_
  );
  const personData = useSelector(
    (state) => state?.personApiSlice.searchPractioner.data
  );
  const encounter = useSelector(
    (state) => state?.chiefCompilenceMastersSlice.encounter_Details
  );

  const patient_banner = useSelector(
    (state) => state?.medicationsSlice.patientBanner?.data
  );
  const encounter_banner = useSelector(
    (state) => state?.medicationsSlice.Encounterbanner?.data
  );

  const isFirstVisit =
    (encounter?.data?.type?.toLocaleLowerCase() ?? "") === "first visit";
  const medicationsData = props.medicationsData;
  const { list, editIndex, setMedication } = medicationsData;
  let EditData = list[editIndex];

  const getData = (value, stateName) => {
    let data = null;
    if (EditData[value]) {
      data = stateList?.[stateName]?.data.filter(
        (val) => val.value === EditData[value]
      )[0];
    }
    if (
      !data &&
      (value === "ivInfusionRateUOM" || value === "IVDosageDurationUOM")
    ) {
      data = stateList.DurationType_masters.data.filter(
        (val) => val.label === "min"
      )[0];
    }
    if (!data && value === "priority") {
      data = stateList.Priority_masters.data.filter(
        (val) => val.label === "Routine"
      )[0];
    }
    if (!data && value === "presMode") {
      data = stateList.OrderMode_masters.data.filter(
        (val) => val.label === "Regular"
      )[0];
    }
    return data;
  };
  const getAdditiveDetails = (value) => {
    let arr = [];
    if (EditData[value]) {
      EditData[value].map((val) => {
        let dose = stateList?.drugMaster_masters?.data.filter(
          (value) => value.value === val.drugcode
        )[0];
        arr.push({
          description: dose,
          dosage: {
            quantity: val.dosageQty,
            unit: stateList?.strengthUom_masters?.data.filter(
              (value) => value.value === val.dosageQtyUOM
            )[0],
          },
          strength: dose?.StrengthValues + " " + dose?.StrengthUOM,
          dosageForm: dose.FormCode,
        });
      });
    }
    return arr;
  };

  const fetchDropData = async () => {
    if (EditData.requestor?.[0]) {
      const orgId = stateList?.Organization_masters?.data.filter(
        (value) => value?.value === EditData.requestor?.[0].organization
      )?.[0]?.id;
      if (orgId) {
        const role = await dispatch(
          actions.ORDER_TYPE_REQ_DETAILS_ROLE({
            id: orgId,
          })
        );
        const practRole = role?.payload?.data?.filter?.(
          (value) => value?._id === EditData.requestor?.[0].practRole
        )?.[0];
        const pract = await dispatch(
          actions.ORDER_TYPE_REQ_DETAILS_LAB({
            id: practRole?.id,
            _id: practRole?._id,
            orgId,
          })
        );
        const practioner = pract?.payload?.data?.filter?.(
          (value) => value?.value === EditData.requestor?.[0].practId
        )?.[0];
        const requestorDetails = {
          organization: EditData.requestor?.[0]
            ? stateList?.Organization_masters?.data.filter(
                (value) => value?.value === EditData.requestor?.[0].organization
              )?.[0]
            : null,
          practitionerRole: practRole,
          orderBy: practioner,
        };
        setState({
          ...state,
          requestorDetails,
        });
      }
    }
  };

  const [state, setState] = React.useState({
    open: true,
    ivForm: {
      name: EditData.name,
      orderDescription: EditData.name?.label ?? "",
      route: getData("routeCode", "DrugRoutes_masters"),
      baseSolution: EditData.name,
      strength:
        EditData.name?.StrengthValues + " " + EditData.name?.StrengthUOM,
      volume: {
        unit: EditData.unitValue ?? EditData.name?.StrengthValues,
        quantity: EditData.doseMeasurementValue ?? EditData.name?.StrengthUOM,
      },
      additiveDetails: getAdditiveDetails("components"),
      infusionRate: {
        unit: getData("ivInfusionRateUOM", "InfusionRateUoM_masters"),
        quantity: EditData.ivInfusionRateValue,
      },
      duration: {
        unit: getData("IVDosageDurationUOM", "DurationType_masters"),
        quantity: EditData.IVDosageDurationValue,
      },
      totalVolume: {
        unit: getData("totPresQtyUOM", "strengthVolumnUom_masters"),
        quantity: EditData.totPresQty,
      },
      prn: EditData.PRNYN ?? false,
      prnCommnts: EditData.prnComment,
    },
    unitDose: {
      name: EditData.name,
      description: EditData.name?.label,
      dosage: {
        min: {
          unit: EditData.unitValue,
          quantity: EditData.doseMeasurementValue,
        },
        max: {
          quantity: "",
          unit: "",
        },
      },
      strength: {
        min: {
          quantity: EditData.name?.StrengthValues,
          unit: EditData.name?.StrengthUOM,
        },
        max: {
          quantity: "",
          unit: "",
        },
      },
      dosageForm: EditData?.name?.FormCode,
      route: getData("routeCode", "DrugRoutes_masters"),
      prn: EditData.PRNYN ?? false,
      prnCommnts: EditData.prnComment,
    },
    formType: EditData?.name?.drugType,
    drugType: {
      label: EditData?.drugType?.drugType,
      value: EditData?.drugType?._id,
    },
    statusData: EditData?.status ?? null,
    statusReason: EditData?.statusreason ?? "",
    statusperiod: EditData?.statusperiod
      ? moment.unix(EditData?.statusperiod).format("YYYY-MM-DD,hh:mm A")
      : "",
    selectDiagnosis: getAllDiagnosiis(
      EditData.diagnosis,
      stateList.DiagnosisAgainstEncounter_masters?.data
    ),
    // selectDiagnosis: EditData.diagnosis,
    indication: getData("indication", "Reasoncode_masters"),
    patientInstruction: getData("patientInstruction", "Patient_Instruction"),
    patientInstructionDes: EditData.patientInstructiondesc,
    requestorDetails: {
      organization: EditData.requestor?.[0]
        ? stateList?.Organization_masters?.data.filter(
            (value) => value?.value === EditData.requestor?.[0].organization
          )?.[0]
        : null,
      practitionerRole: EditData.requestor?.[0]
        ? stateList?.PractitionerRole_masters?.data.filter(
            (value) => value?.value === EditData.requestor?.[0].practRole
          )?.[0]
        : null,
      orderBy: EditData.requestor?.[0]
        ? stateList?.Practitioner_masters?.data.filter(
            (value) => value?.value === EditData.requestor?.[0].practId
          )?.[0]
        : null,
    },
    startDate: EditData.occurStartDateTime
      ? moment.unix(EditData.occurStartDateTime).format("YYYY-MM-DD,hh:mm A")
      : moment().format("YYYY-MM-DD,hh:mm A"),
    initailDate: EditData.occurStartDateTime
      ? moment.unix(EditData.occurStartDateTime).format("YYYY-MM-DD,hh:mm A")
      : moment().format("YYYY-MM-DD,hh:mm A"),
    endDate: EditData.occurEndDateTime
      ? moment.unix(EditData.occurEndDateTime).format("YYYY-MM-DD,hh:mm A")
      : "",
    priority: getData("priority", "Priority_masters"),
    drugCategory: getData("drug_category", "DrugCategory_masters"),
    nature: getData("presNature", "OrderNature_masters"),
    mode: getData("presMode", "OrderMode_masters"),
    dispensingPharmacy: null,
    // dispensingPharmacy: getData("priority", ""),
    slidingScale: {
      row: [
        "Blood sugar (mg/dl)",
        "Low dose scale",
        "Mod dose scale",
        "High dose scale",
      ],
      column: [
        ["70-130", 0, 0, 0],
        ["131-180", 2, 4, 0],
        ["181-240", 4, 8, 12],
        ["241-300", 6, 10, 16],
        ["301-350", 8, 12, 20],
        ["351-400", 10, 16, 24],
        [">400", 12, 20, 28],
      ],
    },
    frequencyDuration: {
      quantity: EditData.dosetime,
      unit: EditData.doseOption,
    },
    frequencyList: {
      "Twice Daily": {
        title: "BID - Twice Daily",
        row: ["Time", "Date"],
        column: TwiceDaily(stateList, EditData.UseAsFreqDose ?? []),
      },
      "EVERY THURSDAY AND FRIDAY": {
        row: ["Frequency day", "Schedule", "Dose"],
        column: generateDose(stateList, EditData.UseAsFreqDose ?? []),
      },
      TAPERING: {
        row: ["From day", "To day", "startDate", "endDate", "schedule", "Dose"],
        column: generateTapparing(stateList, EditData.useAsTaperedDose ?? []),
      },
    },
    frequency: EditData.unitsTypes,
    notes: EditData.notes,
    // ...list[editIndex]
  });

  React.useEffect(() => {
    fetchDropData();
  }, []);
  React.useEffect(() => {
    if (rulesvalidatedData?.data.length > 0) {
      // getData("dispensing_pharmacy", "DrugStoreDispLoc_masters")
      let dispensing_pharmacy = EditData.dispensing_pharmacy;
      let phData = rulesvalidatedData?.data.filter(
        (val) => val.value === dispensing_pharmacy
      )[0];
      setState({
        ...state,
        dispensingPharmacy: phData,
      });
    }
  }, [rulesvalidatedData?.data]);
  React.useEffect(() => {
    if (state.drugType) {
      let userOrgId = props?.fetchUser?.org_id;
      if (userOrgId) {
        let payload = CreateJson(state, EditData);
        let statusHistory = getStatusHistory(
          state.statusData,
          EditData,
          personData?.[0]._id,
          state
        );
        let rulePayload = {
          ...payload,
          statushistory: statusHistory,
          orgId: personData[0]?.OrgID?.[0],
          patientid: encounter?.data?.pID,
          confirmedby: personData[0]?._id,
          encountertype: "op",
          createdby: EditData?.createdby?.[0]?._id,
          updatedby: personData?.[0]._id,
          encounterid: encounter?.data?.eId,
        };
        // dispatch(
        //   actions.FETCHPERFORMINGLOCATIONMED({ orgId: userOrgId, rulePayload })
        // );

        dispatch(
          actions.FETCHPERFORMINGLOCATIONMED_({ orgId: userOrgId })
        );
      }
    }
  }, [state.drugType]);

  const classes = useStyles();
  ///open header popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorIndex, setanchorIndex] = React.useState(null); //multiple calenders
  const [slidingModal, setSlidingModal] = React.useState(false);
  const handleClick = (index, event) => {
    setAnchorEl(event);
    setanchorIndex(index);
  };
  ///all actions click events
  const handleActionClick = (type, value) => {
    switch (type) {
      case "go-back":
        props.onCancel();
        break;
      case "change-form":
        state.drugType = value;
        state.formType = value?.label;
        setState({
          ...state,
        });
        break;
      case "frequency":
        state.frequency = value;
        setState({
          ...state,
        });
        break;
      case "sliding-scale":
        setSlidingModal(true);
        break;
    }
  };

  const [isErr, setIsErr] = useState(false);
  const UpdateMedication = async () => {
    let isError = getErrorValidation(state);
    if (isError) {
      setIsErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    if(state?.unitDose?.dosage?.min?.quantity < 0 || state?.frequencyDuration?.quantity < 0){
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Dosage & Duration value should be greater than 0",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Updating....",
    });
    let payload = CreateJson(state, EditData);
    let statusHistory = getStatusHistory(
      state.statusData,
      EditData,
      personData?.[0]._id,
      state
    );
    let res = await dispatch(
      actions.SAVE_MEDICATIONS({
        ...payload,
        statushistory: statusHistory,
        orgId: personData[0]?.OrgID?.[0],
        patientid: encounter?.data?.pID,
        confirmedby: personData[0]?._id,
        encountertype: "op",
        createdby: EditData?.createdby?.[0]?._id,
        updatedby: personData?.[0]._id,
        encounterid: encounter?.data?.eId,
      })
    );
    const { pId } = props?.location?.state;
    getMedication(pId);
    payload.editId = res.payload.data?.orderId;
    list[editIndex] = payload;
    setMedication({ ...props.medicationsData, list });

    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    props.onCancel();
  };
  console.log("medicaton", state);
  console.log("medIsErr", isErr);

  const getMedication = async (pId) => {
    await dispatch(actions.GET_MEDICATIONS({ id: pId }));
    //props.onCancel();
  }
  
  const getForm = (formType) => {
    switch (formType) {
      case "IV":
        return (
          <IVForm
            styles={styles}
            stateList={stateList}
            handleInputChange={handleInputChange}
            handleActionClick={handleActionClick}
            optionsList={optionsList}
            data={state?.ivForm}
            isErr={isErr}
          />
        );
      default:
        return (
          <UnitDoseForm
            styles={styles}
            handleInputChange={handleInputChange}
            handleActionClick={handleActionClick}
            optionsList={optionsList}
            data={state?.unitDose}
            isErr={isErr}
          />
        );
    }
  };

  ///all input changes
  const handleInputChange = (selector, value, parent) => {
    if (parent) state[parent][selector] = value; ///for common selector forms
    state[selector] = value;
    setState({
      ...state,
    });
  };
  ///duration changes
  const handleDurationInputChange = (selector, value, parent) => {
    console.clear()
    console.log(selector)
    console.log(value)
    console.log(parent)
    // if(value.quantity > 0){
        if (parent) state[parent][selector] = value; ///for common selector forms
        if (selector === "startDate") {
          let count = state.frequencyDuration.quantity;
          let pr = state?.frequencyDuration?.unit?.label?.[0];
          let end = moment(moment(value, "YYYY-MM-DD,hh:mm A"))
            .add(count, pr)
            .format("YYYY-MM-DD,hh:mm A");
          state.endDate = end;
        } else if (selector === "endDate") {
          // let count = state.frequencyDuration.quantity
          let pr = state?.frequencyDuration?.unit?.label?.[0];
          let duration = moment(moment(value, "YYYY-MM-DD,hh:mm A")).diff(
            moment(state.startDate, "YYYY-MM-DD,hh:mm A"),
            pr
          );
          state.frequencyDuration.quantity = duration;
        } else {
          let count = parseInt(value.quantity);
          if (count && value?.unit?.label?.[0]) {
            state.endDate = moment()
              .add(count, value?.unit?.label?.[0])
              .format("YYYY-MM-DD,hh:mm A");
          }
        }
        state[selector] = value;
        setState({
          ...state,
        });
    // }
  };

  // minimum Date Set
  const getMinDate = (date,type) => {
    debugger
    if(type === "startDate"){
      if(date.substring(0,10) === state?.initailDate.substring(0,10)){
        const newDate = moment(new Date()).format("YYYY-MM-DD,hh:mm A")
        const setDates = newDate.replace(",", " ")
        const hours = new Date(setDates).getHours();
        const minutes =  new Date(setDates).getMinutes(); 
        return setHours(setMinutes(new Date(), minutes), hours)
      }else{
        return setHours(setMinutes(new Date(), 0), 0)
      }
    }else{
      debugger
      if(state.startDate.substring(0,10) === (date.length > 0 ? date.substring(0,10) : state.startDate.substring(0,10))){
        debugger;
        const newDate = moment(new Date()).format("YYYY-MM-DD,hh:mm A").replace(",", " ")
        const newDateUnix =  moment(newDate, 'YYYY-MM-DD hh:mm A').unix();

        const minEndTime =  state.startDate.replace(",", " ");
        const minEndTimeUnix = moment(minEndTime, 'YYYY-MM-DD hh:mm A').unix()

        console.log("minEndTime",minEndTime);
        const hours = new Date(newDateUnix > minEndTimeUnix ? newDate : minEndTime).getHours();
        const minutes =  new Date(newDateUnix > minEndTimeUnix ? newDate : minEndTime).getMinutes();
        console.log("minHours",hours) 
        return setHours(setMinutes(new Date(minEndTime), minutes), hours)
      }else{
        return setHours(setMinutes(new Date(), 0), 0)
      }
    }
  }

  const handleFreqencyList = (label, value) => {
    state["frequencyList"][label] = value;
    setState({
      ...state,
    });
  };

  const handleClose = () => {
    props.onCancel();
  }

debugger
  const { parent_id } = props;
  return (
    <Div
      id={`${parent_id}_medication_parent_div`}
      inLineStyles={{ borderRadius: "10px" }}
    >
      {/* form header START*/}
      {/* <FormHeader
        parent_id={"medication_form"}
        stateList={stateList}
        date={EditData.createdAt + " - Created"}
        name={getOrderName(
          { accessionNum: EditData.createdName },
          loggedUserInfo?.data?.completeName || ""
        )}
        title={
          state?.formType == 1
            ? state?.unitDose?.name?.label
            : state?.ivForm?.name?.label
        }
        goBack={() => handleActionClick("go-back")}
        currentStatus={"inactive"}
        color={statusColors[state.statusData?.label]?.color}
        currentStatusText={
          state.statusData?.label === "Hold"
            ? `${state.statusData?.label} ${
                state.statusperiod
                  ? `till ${moment(
                      state.statusperiod,
                      "DD-MM-YYYY,h:mm a"
                    ).format("DD, MMM")}`
                  : ""
              }`
            : state.statusData?.label ?? ""
        }
        openStausInfo={
          isFirstVisit
            ? () => false
            : (event) => handleClick(4, event?.currentTarget)
        }
      /> */}

      {EditData?._id &&
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={state?.open} classes= {{paper: classes.addNewMedicationPaper}}>
              <NewOrderComponent
                  orderId={EditData?._id}
                  patientID={encounter?.data?.pID}
                  encounterID={
                      EditData.encounter_Id?._id ?? ""
                  }
                  encounterKey={
                    EditData.encounter_Id?._key ?? ""
                  }
                  encountertype={
                    EditData.encounter_Id?.type_id?.[0] ?? 0
                  }
                  statusID={"CodingMaster/10704"}
                  isAdd={false}
                  handleMedicationClose={() => {
                    getMedication(props?.location?.state?.pId ?? "");
                    handleClose();
                  }}
                  handleRefreshButtonClick={getMedication(props?.location?.state?.pId ?? "")}
                  // handleRefreshButtonClick={()=> null}

                  // Data for Requestor Details
                  organizationId={props?.fetchUser?.org_id} //mapped
                  practitionerRoleId={{
                    label: props?.fetchUser?.practitionerRoleLabel ?? "", //mapped
                    value:  props?.fetchUser?.practitionerRoleValue ?? "", //mapped
                    id:  props?.fetchUser?.practitionerRoleId ?? "" //mapped
                  }}
                  orderBy={{
                    label: props?.fetchUser?.orderByLabel ?? "",
                    value: props?.fetchUser?.practioner_id ?? ""
                  }}
                  practitionerId={props?.fetchUser?.practioner_id ?? ""} //mapped
                  practDetails={props?.loggerUserInfo ?? {}} //unmapped
                  organization={{
                    label: props?.fetchUser?.org_name ?? "", //mapped
                    value: props?.fetchUser?.org_ID ?? "", //mapped
                    id: props?.fetchUser?.orgId ?? 0 //mapped
                  }}
                  // locationData={{
                  //   label: "",
                  //   value: ""
                  //   // label: props?.loggerUserInfo?.location?.shortdesc ?? "", //unmapped
                  //   // value: props?.loggerUserInfo?.location?._id ?? "", //unmapped
                  // }}
                  patientInfoData={patient_banner?.[0] ?? {}}
                  encounterInfoData={encounter_banner?.[0] ?? {}}
                  practitionerCode={props?.fetchUser?.practioner_id ?? ""}
                  isUpdateRequstorDetails={true}
      />
      </Dialog>
      }
      {/* form header START*/}
      {/* <div
        id={`${parent_id}_medication_description_div`}
        style={{
          height: "calc(100vh - 292px)",
          overflow: "auto",
          background: "#fff",
          borderRadius: "10px",
          margin: "10px 0px",
        }}
      >
        <Row
          id={`${parent_id}_medication_description_row`}
          style={{ padding: 7 }}
        >
          <Col
            id={`${parent_id}_medication_Drug_type_col`}
            xs={12}
            sm={12}
            md={3}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Select
              id={`${parent_id}_medication_Drug_type_select`}
              getOptionLabel="label"
              showArrow
              label={"Drug Type"}
              isRequired
              placeholder={"Select..."}
              options={stateList.drugType_masters?.data ?? []}
              labelStyle={styles.labelStyle}
              inLineStyles={
                isErr && !state?.drugType?.value
                  ? styles.errorborderStyle
                  : styles.borderStyle
              }
              hoverColor={UIColor.lineBorderFill.color}
              value={
                (
                  (stateList.drugType_masters?.data ?? [])?.find(
                    (dt) => dt?.value === state?.drugType?.value
                  ) || {}
                )?.label || ""
              }
              onChangeValue={(value) => handleActionClick("change-form", value)}
              error={isErr && !state?.drugType?.value ? true : false}
            />
          </Col>
          {getForm(state?.formType)}
          <Col
            id={`${parent_id}_medication_Frequency_col`}
            xs={12}
            sm={12}
            md={3}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Select
              id={`${parent_id}_medication_Frequency_select`}
              getOptionLabel="label"
              showArrow
              label={"Frequency"}
              isRequired
              placeholder={"Select..."}
              options={stateList.FrequencyMaster_masters?.data}
              labelStyle={styles.labelStyle}
              inLineStyles={
                isErr && !state?.frequency?.value
                  ? styles.errorborderStyle
                  : styles.borderStyle
              }
              hoverColor={UIColor.lineBorderFill.color}
              value={state?.frequency?.label}
              onChangeValue={(value) => handleActionClick("frequency", value)}
            />
          </Col>
          <Col
            id={`${parent_id}_medication_tappering_col`}
            xs={4}
            sm={4}
            md={1}
            lg={1}
            xl={1}
            inLineStyles={{
              ...styles.colPadding,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text
              id={`${parent_id}_medication_tappering_space_text`}
              inLineStyles={styles.label}
            >
              &nbsp;
            </Text>
            {isShowTapIcon.indexOf(state?.frequency?.label ?? "") > -1 && (
              <Avatar
                id={`${parent_id}_medication_tappering_avatar`}
                style={{
                  ...styles.iconWrapper,
                  backgroundColor:
                    anchorIndex === 5
                      ? UIColor.primaryColor.color
                      : UIColor.lineBorderFill.color,
                }}
                onClick={(event) => handleClick(5, event?.currentTarget)}
              >
                <TapperingIcon
                  id={`${parent_id}_medication_tapperingicon`}
                  color={anchorIndex === 5 ? "white" : "black"}
                />
              </Avatar>
            )}
          </Col>
          <Col
            id={`${parent_id}_medication_Duration_col`}
            xs={12}
            sm={12}
            md={3}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_medication_Duration_text`}
              inLineStyles={styles.label}
            >
              Duration
              {
                <span
                  id={`${parent_id}_medication_Duration_star_span`}
                  style={styles.required}
                >
                  *
                </span>
              }
            </Text>
            <InputGroup
              parent_id={"medication_form"}
              title="Duration"
              isRequired={true}
              options={stateList.DurationType_masters?.data}
              handleChange={(selector, value, parent) => value.quantity > 0 && handleDurationInputChange(selector, value, parent)}
              data={state?.frequencyDuration}
              disabled={false}
              type={null}
              error={
                isErr &&
                !state?.frequencyDuration?.quantity &&
                !state?.frequencyDuration?.unit?.value
                  ? true
                  : false
              }
            />
          </Col> 
          <Col
            id={`${parent_id}_medication_Start_Date_col`}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_medication_Start_Date_text`}
              inLineStyles={styles.label}
            >
              Start Date{<span style={styles.required}>*</span>}
            </Text>
            <Div
              id={`${parent_id}_medication_Start_Date_div`}
              inLineStyles={styles.dateWrapper}
              style={{
                border:
                  isErr && !state?.startDate
                    ? `1px solid ${UIColor.error.color}`
                    : `1px solid ${UIColor.lineBorderFill.color}`,
              }}
            >
              <Text
                id={`${parent_id}_medication_${
                  state?.startDate ? state?.startDate : " ".replaceAll(" ", "_")
                }_text`}
                className="pc_regular"
                style={styles.dateText}
              >
                {state?.startDate ? state?.startDate : " "}
              </Text>
              <Text
                id={`${parent_id}_medication_CalendarIcon_text`}
                style={styles.calenderText}
                aria-describedby={"calender-popover"}
                onClick={(event) => handleClick(1, event?.currentTarget)}
              >
                <CalendarIcon
                  id={`${parent_id}_medication_CalendarIcon`}
                  color={UIColor.secondaryText.color}
                  width={"16"}
                  height={"16"}
                />
              </Text>
            </Div>
          </Col>
          <Col
            id={`${parent_id}_medication_End_Date_col`}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_medication_End_Date_text`}
              inLineStyles={styles.label}
            >
              End Date
            </Text>
            <Div
              id={`${parent_id}_medication_End_Date_div`}
              inLineStyles={styles.dateWrapper}
              style={{ border: `1px solid ${UIColor.lineBorderFill.color}` }}
            >
              <Text
                id={`${parent_id}_medication_${
                  state?.endDate ? state?.endDate : " ".replace(" ", "_")
                }_text`}
                className="pc_regular"
                style={styles.dateText}
              >
                {state?.endDate ? state?.endDate : " "}
              </Text>
              <Text
                id={`${parent_id}_medication_calender-popover_text`}
                style={styles.calenderText}
                aria-describedby={"calender-popover"}
                onClick={(event) => handleClick(2, event?.currentTarget)}
              >
                <CalendarIcon
                  id={`${parent_id}_medication_calender-popover_CalendarIcon`}
                  color={UIColor.secondaryText.color}
                  width={"16"}
                  height={"16"}
                />
              </Text>
            </Div>
          </Col>
          <Popover
            id={"calender-popover"}
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setanchorIndex(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{ paper: styles.muiPopovers }}
          >
            {(anchorIndex === 1 || anchorIndex === 2) && (
              // <DatePicker
              //     id={`${parent_id}_medication_DatePicker`}
              //     timeInputLabel="Time:"
              //     dateFormat="MM/dd/yyyy h:mm aa"
              //     showTimeInput
              //     selected={new Date()}
              //     inline
              //     onChange={date => {
              //         handleDurationInputChange((anchorIndex === 1 ? 'startDate' : 'endDate'), moment(date).format('DD-MM-YYYY,hh:mm A'));
              //         // handleInputChange((anchorIndex === 1 ? 'startDate' : 'endDate'), moment(date).format('DD-MM-YYYY,hh:mm A'));
              //         setAnchorEl(null);
              //     }}
              // />
              <CustomDatePicker
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}W
                //minDate = {anchorIndex === 2 && new Date(moment(state["startDate"]).format("DD-MM-YYYY ,hh:mm A")) }
                minDate={anchorIndex === 1 ? new Date(state["initailDate"]) : new Date(state["startDate"])}
                minTime={anchorIndex === 1 ? getMinDate(state.startDate,"startDate") : getMinDate(state.endDate,"endDate")}
                maxTime={setHours(setMinutes(new Date(), 59), 23)}
                // selectedDate={
                  
                //   anchorIndex === 1
                //     ? state["startDate"]
                //       ? new Date(moment(state["startDate"]).format("DD-MM-YYYY ,hh:mm A"))
                //       // ? alert(state["startDate"])
                //       : new Date()
                //     : state["endDate"]
                //     ? new Date(moment(state["endDate"]).format("DD-MM-YYYY ,hh:mm A"))
                //     : new Date()
                // }
                selectedDate={
                  anchorIndex === 1
                    ? state["startDate"]
                      ? new Date(state["startDate"])
                      : null
                    : state["endDate"]
                    ? new Date(state["endDate"])
                    : null
                }
                handleChange={(date, e) => {
                  // alert(date)
                  handleDurationInputChange(
                    anchorIndex === 1 ? "startDate" : "endDate",
                    moment(date).format("YYYY-MM-DD,hh:mm A")
                  );
                  if(e?.target === undefined)
                  {
                    setAnchorEl(null);
                    setanchorIndex(null);
                  }
                  
                }}
              />
            )}
            {anchorIndex === 4 && (
              <ChangeStatus
                parent_id={"medication_form"}
                cancelButton="Cancel"
                cancelButtonAction={() => setAnchorEl(null)}
                saveButton="Save"
                saveButtonAction={(data, reason, statusperiod) => {
                  setState({
                    ...state,
                    statusReason: reason,
                    statusData: data,
                    statusperiod: statusperiod,
                  });
                  setAnchorEl(null);
                }}
                statusOption={stateList?.status_masters?.data}
                changeStatusData={state?.statusData}
                statusReason={state?.statusReason}
                statusperiod={state?.statusperiod}
                statusHistory={EditData.statushistory}
              />
            )}
            {anchorIndex === 5 && (
              <FrequencyList
                parent_id={"medication_form"}
                title={state?.frequency?.label}
                row={state?.frequencyList[state?.frequency?.label]?.row ?? []}
                column={
                  state?.frequencyList[state?.frequency?.label]?.column ?? []
                }
                contentEditable={true}
                columnEditable={state?.frequency?.label === "TAPERING"}
                styles={styles}
                handleSave={handleFreqencyList}
              />
            )}
          </Popover>
          <Col
            id={`${parent_id}_medication_Priority_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <Row
              id={`${parent_id}_medication_Priority_row`}
              inLineStyles={{ display: "flex", flexDirection: "row" }}
            >
              <Grid
                id={`${parent_id}_medication_Priority_grid`}
                xs={12}
                sm={12}
                md={4}
                lg
              >
                <Select
                  id={`${parent_id}_medication_Priority_select`}
                  getOptionLabel="label"
                  showArrow
                  label={"Priority"}
                  isRequired
                  placeholder={"Select..."}
                  options={stateList?.Priority_masters?.data}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    isErr && !state?.priority?.label
                      ? styles.errorborderStyle
                      : styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.priority?.label}
                  onChangeValue={(value) =>
                    handleInputChange("priority", value)
                  }
                />
              </Grid>
              <Grid
                id={`${parent_id}_medication_drugCategory_grid`}
                xs={12}
                sm={12}
                md={4}
                lg
                className={classes.deskPadding}
              >
                <Select
                  id={`${parent_id}_medication_drugCategory_select`}
                  getOptionLabel="label"
                  showArrow
                  label={"Drug Category"}
                  isRequired
                  placeholder={"Select..."}
                  options={stateList?.DrugCategory_masters?.data}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    isErr && !state?.drugCategory?.label
                      ? styles.errorborderStyle
                      : styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.drugCategory?.label}
                  onChangeValue={(value) =>
                    handleInputChange("drugCategory", value)
                  }
                />
              </Grid>
              <Grid
                id={`${parent_id}_medication_nature_grid`}
                xs={12}
                sm={12}
                md={4}
                lg
                className={classes.deskPadding}
              >
                <Select
                  id={`${parent_id}_medication_nature_select`}
                  getOptionLabel="label"
                  showArrow
                  label={"Nature"}
                  isRequired
                  placeholder={"Select..."}
                  options={stateList.OrderNature_masters?.data}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    isErr && !state?.nature?.label
                      ? styles.errorborderStyle
                      : styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.nature?.label}
                  onChangeValue={(value) => handleInputChange("nature", value)}
                />
              </Grid>
              <Grid
                id={`${parent_id}_medication_mode_grid`}
                xs={12}
                sm={12}
                md={4}
                lg
                className={classes.deskPadding}
              >
                <Select
                  id={`${parent_id}_medication_mode_select`}
                  getOptionLabel="label"
                  showArrow
                  label={"Mode"}
                  isRequired
                  placeholder={"Select..."}
                  options={stateList.OrderMode_masters?.data}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    isErr && !state?.mode?.label
                      ? styles.errorborderStyle
                      : styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.mode?.label}
                  onChangeValue={(value) => handleInputChange("mode", value)}
                />
              </Grid>
              <Grid
                id={`${parent_id}_medication_dispensingPharmacy_grid`}
                xs={12}
                sm={12}
                md={4}
                lg
              >
                <Select
                  id={`${parent_id}_medication_dispensingPharmacy_select`}
                  getOptionLabel="label"
                  showArrow
                  label={"Dispensing Pharmacy"}
                  isRequired
                  placeholder={"Select..."}
                  options={rulesvalidatedData?.data ?? []}
                  // options={stateList.DrugStoreDispLoc_masters?.data}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    isErr && !state?.dispensingPharmacy?.label
                      ? styles.errorborderStyle
                      : styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.dispensingPharmacy?.label}
                  onChangeValue={(value) =>
                    handleInputChange("dispensingPharmacy", value)
                  }
                />
            </Row>
          </Col>
          <Col
            id={`${parent_id}_medication_selectDiagnosis_col`}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_medication_selectDiagnosis_selectbox`}
              requireStart={true}
              multi={true}
              top_title={"Select Diagnosis"}
              list={stateList.DiagnosisAgainstEncounter_masters?.data ?? []}
              placeholder={"Search here..."}
              value={state?.selectDiagnosis ?? []}
              onchange={(e, value) =>
                handleInputChange("selectDiagnosis", value)
              }
              error={isErr && !state?.selectDiagnosis ? true : false}
              medForm
            />
            {/* <MultiSelect
                            id={"mf-selectDiagnosis"}
                            label="Select Diagnosis"
                            getOptionLabel="label"
                            placeholder=""
                            options={stateList.DiagnosisAgainstEncounter_masters?.data}
                            showArrow
                            isRequired
                            labelStyle={styles.labelStyle}
                            inLineStyles={styles.borderStyle}
                            hoverColor={UIColor.lineBorderFill.color}
                            borderColor="#9b9b9b"
                            data={state?.selectDiagnosis ? [state?.selectDiagnosis] : null}
                            onChangeValue={(e, data) => { handleInputChange("selectDiagnosis", e) }}
                        /> 
          </Col>
          <Col
            id={`${parent_id}_medication_indication_col`}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.colPadding}
          >
            <Select
              id={`${parent_id}_medication_indication_select`}
              getOptionLabel="label"
              showArrow
              label={"Indication (Reason)"}
              isRequired
              placeholder={"Select Indication"}
              options={stateList.Reasoncode_masters?.data ?? []}
              labelStyle={styles.labelStyle}
              inLineStyles={
                isErr && !state?.indication?.label
                  ? styles.errorborderStyle
                  : styles.borderStyle
              }
              hoverColor={UIColor.lineBorderFill.color}
              value={state?.indication?.label}
              onChangeValue={(e) => handleInputChange("indication", e)}
            />
          </Col>
          <Col
            id={`${parent_id}_medication_patientInstruction_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <SelectCard
              parent_id={"medication_form"}
              label="Patient Instructions"
              placeholder="Select Patient instructions"
              contentPlaceHolder="Instructions"
              value={state?.patientInstruction?.label}
              content={state?.patientInstruction?.content}
              options={stateList?.Patient_Instruction.data}
              onChangeValue={(data) => {
                handleInputChange("patientInstruction", data);
                handleInputChange("patientInstructionDes", data?.display);
              }}
              isRequired={false}
            />
            {state?.patientInstruction?.display && (
              <Div
                id={`${parent_id}_medication_patientInstructionDes_div`}
                style={styles.patientInstructionDiv}
              >
                <InputBase
                  id={`${parent_id}_medication_patientInstructionDes_inputbase`}
                  className="pc_regular"
                  onChange={(data) =>
                    handleInputChange(
                      "patientInstructionDes",
                      data.target.value
                    )
                  }
                  value={state?.patientInstructionDes}
                  inputProps={{ "aria-label": "naked" }}
                  fullWidth
                />
              </Div>
            )}
          </Col>
          <Col
            id={`${parent_id}_medication_notes_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <Div style={styles.labelStyle} className="pc_regular">Notes</Div>
            <TextInput
              id={`${parent_id}_medication_notes_textinput`}
              label={""}
              placeholder="Type here...."
              hoverColor={UIColor.lineBorderFill.color}
              value={state?.notes}
              style={{ borderColor: UIColor.lineBorderFill.color }}
              onChange={(e) => {
                handleInputChange("notes", e.target.value);
              }}
            />
          </Col>
          <Col
            id={`${parent_id}_medication_requestorDetails_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <RequestorDetailsForm
              parent_id={"medication_form"}
              requestorDetails={state?.requestorDetails}
              title="Requestor Details"
              action="+ Add Requestor Details"
              stateList={stateList}
              actionButton={(data) =>
                handleInputChange("requestorDetails", data)
              }
            />
          </Col>
          <SlidingScale
            parent_id={"medication_form"}
            open={slidingModal}
            handleModal={setSlidingModal}
            row={state.slidingScale.row}
            column={state.slidingScale.column}
            handleSave={handleInputChange}
          />
        </Row>
            </div> */}
      {/* <Row
        id={`${parent_id}_medication_buttons_row`}
        inLineStyles={{ background: "#fff", borderRadius: "10px" }}
      >
        <Col
          id={`${parent_id}_medication_buttons_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margin: "10px 0px" }}
        >
          <Div
            id={`${parent_id}_medication_buttons_div`}
            inLineStyles={styles.flexEnd}
          >
            <Button
              id={`${parent_id}_medication_Cancel_button`}
              // onClick={() => goBack()}
              variant={"text"}
              onClick={() => props.onCancel()}
              inLineStyles={styles.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}_medication_Update_button`}
              variant={"outlined"}
              inLineStyles={styles.filledBtn}
              onClick={() => UpdateMedication()}
            >
              Update
            </Button>
          </Div>
        </Col>
      </Row> */}
    </Div>
  );
};

const optionsList = {};

export default withAllContexts(withRouter(MedicationForm));

function TapperingIcon(props) {
  return (
    <svg
      id="TapperingIcon_svg"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={props.color}
    >
      <path d="M11.951 9.268A2.955 2.955 0 009 12.219v13.415a2.955 2.955 0 002.951 2.951h7.274a6.933 6.933 0 01-.763-1.611h-6.511a1.343 1.343 0 01-1.341-1.341v-11h17.17v3.756a6.982 6.982 0 011.61.95v-7.12a2.955 2.955 0 00-2.951-2.951zm1.61 7.512a.805.805 0 100 1.61h1.073a.805.805 0 100-1.61zm4.293 0a.805.805 0 000 1.61h4.56a6.949 6.949 0 012.684-.537c.162 0 .321.013.48.024a.8.8 0 00-.748-1.1zm7.246 2.147a5.9 5.9 0 105.9 5.9 5.9 5.9 0 00-5.9-5.9zM13.561 20a.805.805 0 100 1.61h1.073a.805.805 0 100-1.61zm4.293 0a.805.805 0 100 1.61h1.06A6.986 6.986 0 0120.072 20zm7.244.537a.537.537 0 01.537.537v3.22h3.22a.537.537 0 010 1.073h-3.22v3.22a.537.537 0 11-1.073 0v-3.219h-3.22a.537.537 0 110-1.073h3.22v-3.227a.537.537 0 01.538-.532zm-11.537 2.682a.805.805 0 100 1.61h1.073a.805.805 0 100-1.61zm4.293 0a.805.805 0 000 1.61h.268a6.984 6.984 0 01.194-1.61z"></path>
    </svg>
  );
}
