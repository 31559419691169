import React from "react";
import { Div, Row, Col, H6, Paper } from "qdm-component-library";
import {
	TopNavbar,
	PatientListView,
	ApplicableCriteria,
	CardDetails,
	PatientStatusCard,
	TopHeader,
	LoadingComp,
} from "../../../components";
import { financialTitle } from "../../../utils/routesList";
import { Routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from "../../../contexts";
import { withQueue, withAllContexts } from "../../../HOCs";
import ShowList from "./adressList";
import moment from "moment";
import {
	AlertProps,
	fetchQueueData,
	fetchUserInfo,
	getUtcTime,
	makeQueueData,
	putOnHold,
	uploadImage,
	utcTOLocal,
} from "../../../utils";
import { checkWithCasbin } from "../../../utils/permissonHandling";

class FinancialRegistration extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Persondata: [],
			allMasters: [],
			Isopenform: false,
			loading: true,
			btnName: false
		};
		this.successfun = this.successfun.bind(this);
		this.fetchQueueData = fetchQueueData.bind(this);
		this.putOnHold = putOnHold.bind(this);
		this.fetchUserInfo = fetchUserInfo.bind(this);
	}
	onSave = (files) => {
		// ----------------------------------
		debugger;
		const finform = this.props.financialData;
		// if (!finform.payer?.reciver_type) {
		// 	finform.payer["error"] = {
		// 		reciver_type: true,
		// 		...finform.payer["error"],
		// 	};
		// }
		// if (!finform.payer?.reciver_code) {
		// 	finform.payer["error"] = {
		// 		reciver_code: true,
		// 		...finform.payer["error"],
		// 	};
		// }
		if (!finform.payer?.policy) {
			finform.payer["error"] = {
				policy: true,
				...finform.payer["error"],
			};
		}
		if (!finform.payer?.plan) {
			finform.payer["error"] = {
				plan: true,
				...finform.payer["error"],
			};
		}
		finform.setFinancialData({
			...finform,
		});
		if (
			// finform.payer?.reciver_type &&
			// finform.payer?.reciver_code &&
			//finform.payer?.policy &&
			//finform.payer?.plan
			true
		) {
			this.handlesubmit();
		}else{
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill all the mandatory fields",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.center,
				tone: true,
			});
		}
	};
	async successfun(files) {
		const { nextRoute } = this.props?.location?.state;
		const nr = nextRoute ? nextRoute : Routes.confirmBooking;
		this.setState({
			...this.state,
			btnName:true
		})
		let UploadedFiles = [];
		UploadedFiles = await uploadImage(files);

		let patientData = this.props.financialData;
		if (!patientData?.savedata?.length > 0) {
			this.setState({
				...this.state,
				btnName:false
			})
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill the details and save it.",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			return;
		}

		const res_ = await this.props.GET_PATIENT__ID_BY_ID({
			patient_id: this.props?.location?.state?.patientId ?? 0,
		});
		const accountData = patientData?.savedata?.map((v) => {
			return {
				id: 0,
				identifier: [],
				status: "",
				// type: "",
				// name: "",
				// servicePeriod: "",
				// coverage: [
				// 	{
				// 		coverage_id: 0,
				// 		priority: "",
				// 	},
				// ],
				// organization_id: 0,
				// description: "",
				// guarantor: [
				// 	{
				// 		onHold: false,
				// 		period: "",
				// 		relatedperson_id: 0,
				// 		patient_id: this.props?.location?.state?.patientId || 0,
				// 		organization_id: 0,
				// 	},
				// ],
				// account_id: [],
				// device_id: [],
				// payer_details: [
				// 	{
				// 		priority: v?.payer?.priority?.value ?? "",
				// 		receiver_type: v?.payer?.reciver_type?.value ?? "",
				// 		receiver_code: v?.payer?.reciver_code?.value ?? "",
				// 		guarantor_type: v?.payer?.guarantor_type?.value ?? "",
				// 		guarantor_code: v?.payer?.guarantor_code?.value ?? "",
				// 		membership_id: v?.payer?.membershipId ?? "",
				// 		policy: v?.payer?.policy?.value ?? "",
				// 		plan: v?.payer?.plan?.value ?? "",
				// 	},
				// ],
				// validity_details: [
				// 	{
				// 		policy_start: v?.validity?.policy_start
				// 			? getUtcTime(v?.validity?.policy_start)
				// 			: 0,
				// 		policy_expiry: v?.validity?.expiry
				// 			?getUtcTime(v?.validity?.policy_start)
				// 			: 0,
				// 		service_start: v?.validity?.service_start
				// 			?getUtcTime(v?.validity?.policy_start)
				// 			: 0,
				// 		service_end: v?.validity?.service_end
				// 			?getUtcTime(v?.validity?.policy_start)
				// 			: 0,
				// 	},
				// ],
				// membership_details: [
				// 	{
				// 		membership_type: v?.membership?.type?.value ?? "",
				// 		member_relationship: v?.membership?.relationship?.value ?? "",
				// 		prime_memeber_id: v?.membership?.member_id ?? "",
				// 	},
				// ],
				// eligibility_details: [
				// 	{
				// 		verification_required: v.eligibility.isverify
				// 			? v?.eligibility?.isverify
				// 			: false,
				// 		sponser_id: v?.eligibility?.sponsor_id ?? "",
				// 		sponser_name: v?.eligibility?.sponsor_name ?? "",
				// 	},
				// ],
				// financial_class: [
				// 	{
				// 		financial_class_type: v?.financial?.class_type?.value ?? "",
				// 		financial_class_value: v?.financial?.class_value?.value ?? "",
				// 	},
				// ],
				// applicable_criteria: [
				// 	{
				// 		encounter: v?.applicable?.encounter?.value ?? "",
				// 		encounter_type: v?.applicable?.encounter_type ?? "",
				// 		specilaty: v?.applicable?.speciality?.value ?? "",
				// 	},
				// ],
				//patient_id: this.props?.location?.state?.personId ?? 0,

				//NEW KEYS
				SourceSystemID: "",
				SourceSystemRefId: "",
				entitytype: "",
				entityname: "",
				linkidentype: "Patient",
				linkidenvalue: res_?.payload?.data?.result?.[0]?._id ?? "",
				applenc: v?.applicable?.encounter?.value ?? "",
				applenctype: v?.applicable?.encounter_type ?? "",
				applspecialty: v?.applicable?.speciality?.value ?? "",
				changeseqno: 0,
				payersprioritycoverage: [
					{
						priority: v?.payer?.priority?.value ?? "",
						receivertype: v?.payer?.reciver_type?.value ?? "",
						receiverid: v?.payer?.reciver_code?.value ?? "",
						guarantortype: v?.payer?.guarantor_type?.value ?? "",
						guarantorid: v?.payer?.guarantor_code?.value ?? "",
						membershipid: v?.payer?.membershipId ?? "",
						policyid: v?.payer?.policy?.value ?? "",
						planid: v?.payer?.plan?.value ?? "",

						policystart: v?.validity?.policy_start
							? getUtcTime(v?.validity?.policy_start)
							: 0,
							policyexpiry: v?.validity?.expiry
							?getUtcTime(v?.validity?.policy_start)
							: 0,
						servicestart: v?.validity?.service_start
							?getUtcTime(v?.validity?.policy_start)
							: 0,
						serviceend: v?.validity?.service_end
							?getUtcTime(v?.validity?.policy_start)
							: 0,

						membertype: v?.membership?.type?.value ?? "",
						memberrelid: v?.membership?.relationship?.value ?? "",
						primemembershipid: v?.membership?.member_id ?? "",

						verifreqd: v.eligibility.isverify
							? v?.eligibility?.isverify
							: false,
						sponserid: v?.eligibility?.sponsor_id ?? "",
						sponsername: v?.eligibility?.sponsor_name ?? "",

					  eligibilestatus: "",
					  financialclass: [
						{
							classtype: v?.financial?.class_type?.value ?? "",
						    classvalue: v?.financial?.class_value?.value ?? ""
						}
					  ],
					  files: []
					}
				  ],
				_key: v?._key ?? ""
			};
		});

		// const data = [
		//     {
		//         "doc": {
		//             "account": [
		//                 ...accountData
		//             ]
		//         }
		//     }
		// ]

		const data = [
			{
				doc: {
					...accountData,
				},
			},
		];

		const res = await this.props.ORGANIZATION_FINANCIAL_REGISTRATION({
			payloaddata: accountData,
			patient_id: this.props?.location?.state?.patientId ?? 0,
		});

		if (res?.payload?.data?.validation_error || res.payload?.data?.error) {
			this.setState({
				...this.state,
				btnName:false
			})
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg:
					res?.payload?.data?.validation_error?.[0]?.Errormsg ||
					res.payload?.data?.id?.error,
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			return;
		} else {
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.success,
				msg: "Financial registration done successfully",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			this.props.history.push({
				// pathname: ((this.props.location?.state?.guest) || (this.props?.location?.state?.Ispatient)) ? Routes.confirmBooking : Routes.PatientDashBoard,
				pathname: this.props.location?.state?.appointmentId
					? Routes.confirmBooking
					: Routes.PatientDashBoard,
				state: {
					from: this.props.location?.state?.from,
					visit: true,
					nextRoute: Routes.confirmBooking,
					prevRoute: Routes.patientVisitDetails,
					mobileNumber: this.props.location?.state?.mobileNumber,
					appointmentId: this.props.location?.state?.appointmentId,
					patientId: this.props.location?.state?.patientId,
				},
			});
		}
	}

	getallmasters = async () => {
		const data = await this.props.MASTER_SEARCH({
			type: [
				"Level Of Care",
				"Department",
				"VISITTYPE",
				"Occupation",
				"NAMEPREFIX",
				"NAMESUFFIX",
				"Patient Type",
				"Race",
				"Ethnicity",
				"Religion",
				"RELATIONSHIP",
				"MARITALSTATUS",
				"LANGUAGE",
				"Town",
				"District",
				"State",
				"Country",
				"ALTERNATEIDTYPE",
				"Blood Group",
				"SPECIALTY",
				"Document Type",
			],
		});
		this.setState({
			allMasters: data?.payload?.data,
		});
	};

	async componentDidMount() {
	    await this.getallmasters();
		const data = await this.props.ORGANIZATION_FILTER_BY_PATIENT({
			patientid: this.props.location?.state?.patientId,
		});
		const FINANCIALdata = await this.props.GET_FINANCIAL({
			patientId: this.props.location?.state?.patientId,
		});
		debugger
		let fin_data = FINANCIALdata.payload?.data.map((val, i) => {
			return {
				id: 0,
				identifier: [],
				status: "",
				type: "",
				name: "",
				servicePeriod: "",
				coverage: [
					{
						coverage_id: 0,
						priority: "",
					},
				],
				organization_id: 0,
				description: "",
				guarantor: {
					onHold: false,
					period: "",
					relatedperson_id: 0,
					patient_id: this.props?.location?.state?.patientId || 0,
					organization_id: 0,
				},
				account_id: [],
				device_id: [],
				payer: {
					priority: { value: val?.payersprioritycoverage?.[0]?.priority ?? "" },
					reciver_type: { value: val?.payersprioritycoverage?.[0]?.receivertype } ?? "",
					reciver_code: { value: val?.payersprioritycoverage?.[0]?.receiverid } ?? "",
					guarantor_type:
						{ value: val?.payersprioritycoverage?.[0]?.guarantortype } ?? "",
					guarantor_code:
						{ value: val?.payersprioritycoverage?.[0]?.guarantorid } ?? "",
					membershipId: val?.payersprioritycoverage?.[0]?.membershipid ?? "",
					policy: { value: val?.payersprioritycoverage?.[0]?.policyid } ?? "",
					plan: { value: val?.payersprioritycoverage?.[0]?.planid } ?? "",
				},

				validity: {
					policy_start: val?.payersprioritycoverage?.[0]?.policystart
						? utcTOLocal(val?.payersprioritycoverage?.[0]?.policystart,"YYYY-MM-DD")
						: 0,
					policy_expiry: val?.payersprioritycoverage?.[0]?.policyexpiry
						? utcTOLocal(val?.payersprioritycoverage?.[0]?.policyexpiry,"YYYY-MM-DD")
						: 0,
					service_start: val?.payersprioritycoverage?.[0]?.servicestart
						?utcTOLocal(val?.payersprioritycoverage?.[0]?.servicestart,"YYYY-MM-DD")
						: 0,
					service_end: val?.payersprioritycoverage?.[0]?.serviceend
						? utcTOLocal(val?.payersprioritycoverage?.[0]?.serviceend,"YYYY-MM-DD")
						: 0,
				},

				membership: {
					type: { value: val?.payersprioritycoverage?.[0]?.membertype } ?? "",
					relationship:
						{ value: val?.payersprioritycoverage?.[0]?.memberrelid } ?? "",
					member_id: val?.payersprioritycoverage?.[0]?.primemembershipid ?? "",
				},

				eligibility: {
					isverify: val?.payersprioritycoverage?.[0].verifreqd
						? val?.payersprioritycoverage?.[0].verifreqd
						: false,
					sponsor_id: val?.payersprioritycoverage?.[0].sponserid ?? "",
					sponsor_name: val?.payersprioritycoverage?.[0].sponsername ?? "",
				},

				financial: {
					class_type:
						{ value: val?.payersprioritycoverage?.[0]?.financialclass?.[0]?.classtype } ?? "",
					class_value:
						{ value: val?.payersprioritycoverage?.[0]?.financialclass?.[0]?.classvalue } ?? "",
				},

				applicable: {
					encounter: { value: val?.applenc } ?? "",
					encounter_type: val?.applenctype ?? "",
					speciality: { value: val?.applspecialty } ?? "",
				},

				patient_id: this.props?.location?.state?.personId ?? 0,
				_key: val?._key ?? ""
			};
		});
		await this.props?.GET_ENCOUNTER_HEADER({
			patientId: this.props.location?.state?.patientId,
			encounterId: this.props.location?.state?.encounterDetails?.Result?.[0]?.properties?.doc?.id
		})
		// let arr = fin_data.map( (val, i) => {return val.payer.reciver_type.value ==="Self Pay"});
		// if(arr.includes())

		let index = await fin_data.findIndex(object => {
			return object?.payer?.reciver_type?.value === 'Self Pay';
		});
		if(index === -1){
			await fin_data.push({
				id: 0,
				identifier: [],
				status: "",
				type: "",
				name: "",
				servicePeriod: "",
				coverage: [
					{
						coverage_id: 0,
						priority: "",
					},
				],
				organization_id: 0,
				description: "",
				guarantor: {
					onHold: false,
					period: "",
					relatedperson_id: 0,
					patient_id: this.props?.location?.state?.patientId || 0,
					organization_id: 0,
				},
				account_id: [],
				device_id: [],
				payer: {
					priority: "",
					reciver_type: {value: "Self Pay"},
					reciver_code: "",
					guarantor_type: "",
					guarantor_code: "",
					membershipId: "",
					policy: "",
					plan: "",
				},

				validity: {
					policy_start: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.policystart
						? utcTOLocal(FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.policystart,"YYYY-MM-DD")
						: 0,
					policy_expiry: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.policyexpiry
						? utcTOLocal(FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.policyexpiry,"YYYY-MM-DD")
						: 0,
					service_start: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.servicestart
						?utcTOLocal(FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.servicestart,"YYYY-MM-DD")
						: 0,
					service_end: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.serviceend
						? utcTOLocal(FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.serviceend,"YYYY-MM-DD")
						: 0,
				},

				membership: {
					type: { value: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.membertype } ?? "",
					relationship:
						{ value: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.memberrelid } ?? "",
					member_id: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.primemembershipid ?? "",
				},

				eligibility: {
					isverify: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.verifreqd
						? FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.verifreqd
						: false,
					sponsor_id: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.sponserid ?? "",
					sponsor_name: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.sponsername ?? "",
				},

				financial: {
					class_type:
						{ value: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.financialclass?.[0]?.classtype } ?? "",
					class_value:
						{ value: FINANCIALdata.payload?.data?.[0]?.payersprioritycoverage?.[0]?.financialclass?.[0]?.classvalue } ?? "",
				},

				applicable: {
					encounter: { value: FINANCIALdata.payload?.data?.[0]?.applenc } ?? "",
					encounter_type: FINANCIALdata.payload?.data?.[0]?.applenctype ?? "",
					speciality: { value: FINANCIALdata.payload?.data?.[0]?.applspecialty } ?? "",
				},

				patient_id: this.props?.location?.state?.personId ?? 0,
			})
		}
		this.props.financialData.setFinancialData({
			...this.props?.financialData,
			savedata: fin_data,
		});

		this.setState({
			Persondata: data?.payload?.data, 
			loading : false
		});
		console.log(data);

		// this.getallmasters();
		if (
			(this.props.queueManagement?.data || []).length === 0 &&
			(this.props.queueManagement?.previousData || []).length === 0 &&
			(this.props.queueManagement?.onHoldData || []).length === 0
		) {
			this.fetchQueueData();
		} else {
			const userData = await this.fetchUserInfo();
			const permissionA = await checkWithCasbin(["queueSideNav"]);
			const permissionData = permissionA.write.find(d => d === "appointmentQueueDataN" || d === "appointmentQueueDataD" || d === "appointmentQueueDataF");
			const [, displayData, onHoldData, completedData] = makeQueueData(
				permissionData,
				this.props.queueManagement.allData,
				userData,
				false
			);
			this.props.queueManagement.setDisplayData(displayData);
			this.props.queueManagement.setOnHoldData(onHoldData);
			this.props.queueManagement.setPreviousData(completedData);
		}
	}
	onCancel = () => {
		let patientData = this.props.financialData;
		patientData["membership"] = {};
		patientData["payer"] = {};
		patientData["validity"] = {};
		patientData["eligibility"] = {};
		patientData["financial"] = {};
		patientData["applicable"] = {};
		patientData.index = null;
		patientData.setFinancialData({
			...patientData,
		});
		this.setState({ Isopenform: false });
	};

	handlesubmit = async () => {
		let patientData = this.props.financialData;
		if (
			// Object.keys(patientData.membership).length === 0 &&
			// Object.keys(patientData.payer).length === 0 &&
			// Object.keys(patientData.validity).length === 0 &&
			// Object.keys(patientData.eligibility).length === 0 &&
			// Object.keys(patientData.financial).length === 0 &&
			// Object.keys(patientData.applicable).length === 0

			patientData?.payer?.error?.plan === true || patientData?.payer?.error?.policy === true
		) {
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill the details",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			return;
		}

		if (patientData.index !== null) {
			patientData.savedata[patientData.index] = {
				...patientData.savedata[patientData.index],
				membership: patientData.membership,
				payer: patientData.payer,
				validity: patientData.validity,
				eligibility: patientData.eligibility,
				financial: patientData.financial,
				applicable: patientData.applicable,
			};

			patientData.membership = {};
			patientData.payer = {};
			patientData.validity = {};
			patientData.eligibility = {};
			patientData.financial = {};
			patientData.applicable = {};
			patientData.index = null;

			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.success,
				msg: "Updated succsssfully",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			await patientData.setFinancialData({
				...patientData,
			});
			return;
		} else {
			await patientData.savedata.push({
				membership: patientData.membership,
				payer: patientData.payer,
				validity: patientData.validity,
				eligibility: patientData.eligibility,
				financial: patientData.financial,
				applicable: patientData.applicable,
			});

			patientData.membership = {};
			patientData.payer = {};
			patientData.validity = {};
			patientData.eligibility = {};
			patientData.financial = {};
			patientData.applicable = {};
			patientData.index = null;

			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.success,
				msg: "Added succsssfully",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			patientData.setFinancialData({
				...patientData,
			});
		}
	};
	onedit = async (v, i) => {
		let patientData = this.props.financialData;
		patientData["membership"] = v.membership;
		patientData["payer"] = v.payer;
		patientData["validity"] = v.validity;
		patientData["eligibility"] = v.eligibility;
		patientData["financial"] = v.financial;
		patientData["applicable"] = v.applicable;
		patientData.index = i - 1;

		await patientData.setFinancialData({
			...patientData,
		});
	};

	ondelete = async (v, i) => {
		debugger 
		if(v?._key){
			const response____ =  await this.props?.SOFT_DELETE_FINANCIAL_PAYER({
				_key: v?._key
			})
			debugger
			if(response____?.payload?.data?.Code === 201){
				let patientData = this.props.financialData;
				patientData.savedata.splice(i - 1, 1);
				patientData.setFinancialData({
					...patientData,
				});
				this.context.setSnack({
					open: true,
					severity: AlertProps.severity.success,
					msg: "Deleted succsssfully",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
			} else {
				this.context.setSnack({
					open: true,
					severity: AlertProps.severity.success,
					msg: "Something went wrong",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
			}
		} else {
			let patientData = this.props.financialData;
			patientData.savedata.splice(i - 1, 1);
			patientData.setFinancialData({
				...patientData,
			});
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.success,
				msg: "Deleted succsssfully",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
		}	
	};

	alertHold = () => {
		// this.context.setSnack({
		// 	open: true,
		// 	severity: AlertProps.severity.success,
		// 	msg: "Put on holded",
		// 	vertical: AlertProps.vertical.top,
		// 	horizontal: AlertProps.horizontal.right,
		// });
	};
	goBackFn = () => {
		const { prevRoute } = this.props.location.state;
		if (prevRoute && prevRoute === Routes.patientVisitDetails) {
			// this.props.history.push({
			// 	pathname: prevRoute,
			// 	state: {
			// 		isBack: true,
			// 		from: this.props.location?.state?.from,
			// 		nextRoute: Routes.financialRegistration,
			// 		encounterDetails:
			// 			this.props.location?.state?.encounterDetails?.Result,
			// 		from: this.props.location?.state?.from,
			// 		patientId: this.props.location?.state?.patientId,
			// 		appointmentId: this.props.location?.state?.appointmentId,
			// 		Ispatient: this.props?.location?.state?.Ispatient,
			// 		mobileNumber: this.props.location?.state?.mobileNumber,
			// 	},
			// });
			window.location.href = "/dashboard";
			return;
		} else if (prevRoute && prevRoute === Routes.assemblePatioentRegister) {
			this.props.history.push({
				// pathname: ((this.props.location?.state?.guest) || (this.props?.location?.state?.Ispatient)) ? Routes.confirmBooking : Routes.PatientDashBoard,
				pathname: prevRoute,
				state: {
					isBack: true,
					from: this.props.location?.state?.from,
					nextRoute: Routes.financialRegistration,
					prevRoute: Routes.financialRegistration,
					from: this.props.location?.state?.from,
					personId: this.props.location?.state?.personId,
					patientData: this.props.location?.state?.mobileNumber,
					Ispatient: true,
					appointmentId: this.props.location?.state?.appointmentId,
					patientId: this.props.location?.state?.patientId,
					patient_id: this.props.location?.state?.patient_id,
					patient_key: this.props.location?.state?.patient_key,
				},
			});
			return;
		}
		this.props.history.goBack();
	};

	getLanguage = (val) => {
		let languageArr = val?.Practitioner_id?.[0]?.communication?.map((i)=>{return i?.display});
        return languageArr?.join(", ");
	};
	
	render() {
		const EncounterDetails =
			this.props.location?.state?.encounterDetails?.Result;
		const EncounterHeader = this.props.encounterHeader?.data?.[0]
		const { practitioner } = this.props.location?.state?.visitData || {};
		return (
			<Div id="financial_parent_div" className="light-background-color">
				<TopHeader
					parent_id={'dashboard'}
					noHome={true} middleComponent={<></>}
				/>
				<TopNavbar
					goBack={this.goBackFn}
					parent_id={"Financial_Registration"}
					alertHold={this.alertHold}
					putOnHold={() =>
						this.putOnHold(
							"undefined",
							this.props.location?.state?.appointmentId
						)
					}
					newUser={this.props.location?.state?.newUser}
					successfun={this.successfun}
					navBarArr={[]}
					title={financialTitle}
					btn = {this.state.btnName ? "Registering" : "Register & continue"}
				/>
				<div style={{
					height: 'calc(100vh - 128px)', // 128px = 72px + 56px // 72 - topheader , 56 - topnav
					overflow: 'auto',
					display: this.state.loading ? "flex" : "block",
					alignItems: "center",
					justifyContent: "center"
				}}
				>
					<LoadingComp 
							loading={this.state.loading ? true : false}
					>
					<Div id="financial_parent_sub_div" className="rv-list-view">
						{(this.props.location?.state?.guest ||
							this.props?.location?.state?.Ispatient) &&
							EncounterDetails?.length > 0 ? (
							<Row id="financial_patientlist_row">
								<Col
									id="financial_patientlist_col"
									lg={6}
									md={6}
									inLineStyles={{ padding: 5 }}
								>
									<PatientListView
										parent_id={"Financial_Registration"}
										noright={true}
										Persondata={this.state.Persondata?.[0]}
									/>
								</Col>
								<Col
									id="financial_patientstatus_col"
									lg={6}
									md={6}
									inLineStyles={{ padding: 5 }}
								>
									<PatientStatusCard
										parent_id={"Financial_Registration"}
										EncounterDetails={EncounterDetails}
										pic={
											"https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
										}
										titles={[
											`${EncounterHeader?._id ?? ""}`,
											`${EncounterHeader?.type?.[0] ?? ""
											}`,
											`${EncounterHeader?.visit_type ?? ""
											}`,
											`${practitioner?.speciality?.value ?? ""}`,
										]}
										date={moment
											.unix(EncounterHeader?.encounterdate)
											.format("MMMM Do YYYY, h:mm a")}
										languages={this?.getLanguage(EncounterHeader)}
										padding
									/>
								</Col>
							</Row>
						) : (
							<Row id="financial_patientist_row_one">
								{" "}
								<Col
									id="financial_patientist_col_one"
									lg={12}
									md={12}
									inLineStyles={{ padding: 5 }}
								>
									<PatientListView
										parent_id={"Financial_Registration"}
										noright={true}
										Persondata={this.state.Persondata?.[0]}
									/>
								</Col>
							</Row>
						)}
					</Div>
					{this.props.financialData?.savedata?.length > 0 && (
						<Div
							className="pv-root"
							style={{
								margin: "3px 15px",
							}}
						>
							<Paper id="financial_applicable_paper" className="pl-root">
								<H6
									id="financial_applicable_label_h6"
									className="en-title space-padding-bottom"
								>
									APPLICABLE CRITERIA
								</H6>
								{[...this.props.financialData?.savedata]?.map((v, i) => {
									return (
										<Div
											id="financial_applicable_div"
											style={{ marginBottom: 16 }}
										>
											<ShowList
												parent_id={"financial_showlist"}
												onedit={this.onedit}
												ondelete={this.ondelete}
												isDelete= { v?.payer?.reciver_type?.value === "Self Pay" ? false : true}
												isEdit= { v?.payer?.reciver_type?.value === "Self Pay" ? false : true}
												data={v}
												index={i + 1}
											/>
										</Div>
									);
								})}
							</Paper>
						</Div>
					)}

					<CardDetails
						parent_id={"financial_carddetails"}
						allMasters={this.state?.allMasters}
						onSave={() => this.onSave()}
						onCancel={() => this.onCancel()}
					/>
					<ApplicableCriteria
						parent_id={"financial_applicable_criteria"}
						allMasters={this.state?.allMasters}
					/>
					<Div id="financial_continue_div" className="p-continue-action"></Div>
					</LoadingComp>
				</div>
			</Div>
		);
	}
}

// export default withRouter(withQueue(FinancialRegistration));
FinancialRegistration.contextType = AlertContext;
const mapStateToProps = (state) => ({
	oraganizationRegisterPatient:
		state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
	loggedUserInfo: state?.authSlice?.loggedUserInfo,
	encounterHeader: state?.OrganizationRegisterPatientApiSlice?.getEncounterHeader
});
export default connect(
	mapStateToProps,
	actions
)(withRouter(withQueue(withAllContexts(FinancialRegistration))));
