import React from "react";
import { Row, Col, Text, H6, Icons, Div } from "qdm-component-library";
import { Popover } from "@material-ui/core";
import { InfoIcon } from "../../../components";
import moment from "moment";
import { checkError, getData, getUserInfo, makeName, processData, UIColor, utcTOLocal } from "../../../utils";
import { LabOrderList } from "../labOrderFlow/labOrderList";
import { RadiologyList } from "../radiologyFlow/radiologyList";
import { withAllContexts } from "../../../HOCs";
import { ChangeStatus } from "../../../components";
import { actions } from "primarycare-binder";
import { TreatmentProcedureList } from "../treatmentProcedureFlow/treatmentProcedureList";
import { useDispatch, useSelector } from "react-redux";
import FollowupFotter from "../followupfooter";
import VitalSigns from "../vitalSigns";
const styles = {
  vitalsHead: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddind: 10,
    //background: UIColor.greyBackground.color,
    //borderRadius: 8,
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    //background: UIColor.greyBackground.color,
    //borderRadius: 8,
    flexWrap: "wrap",
  },
  vitailsCard: {
    border: `1px solid ${UIColor?.lineBorderFill?.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: "24px !important",
    background: "#fff"
  },

  textColor: {
    color: "#9b9b9b",
    fontSize: 12,
    paddingLeft: 6,
  },
  textColor1: {
    color: "#9b9b9b",
    fontSize: 12,
  },
  statustextColor: {
    color: UIColor?.success?.color,
    fontSize: 12,
    //fontWeight: "bold",
    paddingLeft: 6,
  },
  statusInactiveColor: {
    color: "#ec6a49",
    fontSize: 12,
    //fontWeight: "bold",
    paddingLeft: 6,
  },
  statusDraftColor: {
    color: UIColor.secondaryColor.color,
    fontSize: 12,
    //fontWeight: "bold",
    paddingLeft: 6,
  },
  statusResolvedColor: {
    color: "#2a3c50",
    fontSize: 12,
    //fontWeight: "bold",
    paddingLeft: 6,
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  editAllergyIcon: {
    padding: "6px 10px",
    background:UIColor?.greyBackground?.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
};

const statusColors = {
  Hold: { color: "#5B12BB", background: "#E3D7F3" },
  Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Active: { color: UIColor.success.color, background: "#fff" },
  Complete: {
    color: UIColor?.success?.color,
    background: "hsl(105, 62%, 90%)",
  },
  Cancel: { color: UIColor?.error?.color, background: "#FFE1E0" },
  Discontinue: { color: "#d60000", background: "#F0F0F0" },
  Continue: { color: UIColor?.primaryColor?.color, background: "#0071F2" },
  "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  // "Cancel": { color: "#FF4D4A", background: "#FFE1E0" }
};

const getColor = {
  Mild: UIColor?.primaryColor?.color,
  Moderate: UIColor?.secondaryColor?.color,
  Severe: "#FF6360",
};

const SignOffCard = (props) => {
  const dispatch = useDispatch();
  const storeDataDiagnosis = useSelector((state) => state?.DiagnosisMasterSlice);
  const masterList = useSelector((state) => state?.signOffSlice);
  const medOrderStatus = useSelector((state) => state?.MedicationMastersSlice).status_masters;
  const stateList = useSelector((state) => state?.MedicationMastersSlice);
  const signOffSlice = useSelector((state) => state?.signOffSlice);
  const personDetails = useSelector((state) => state?.personApiSlice)?.searchPractioner?.data?.[0];
  const allergiesData = useSelector((state) => state?.allergyMastersSlice);
  const chiefComplaintsCC = useSelector((state) => state?.chiefCompilenceMastersSlice);
  let allergys = props?.allergy;
  const { list } = props.Diagnosis;
  let cheifComplaints = props.cheifComplaints;
  let laborders = props.LabOrder;
  let medicationsData = props.medicationsData;
  let radiologyData = props.radiology;
  let treatmentData = props.treatment;
  let viatalsData = props.viatalsContext;
  let viatalsDatalist = processData(viatalsData?.list?.[0]);

  const getMasterData = [
    "GET_MED_WF_STATUS",
    "GET_RECORD_STATUS",
    "GET_SERVICE_WF_STATUS",
    "VERIFICATIONS_MASTERS_DIAGNOSIS",
    "USE_MASTERS",
    "DIAGNOSIS_RANK_MASTERS",
    "CLINICAL_MASTERS",
    "MANIFESTATION_MASTERS",
    "GRADE_TYPE_MASTERS",
    "ASSESMENT_MASTERS",
    "GRADE_CODE_MASTERS",
  ];

  const initFetch = async () => {
    const data = getMasterData.map(fn => dispatch(actions[fn]()));
    await Promise.all(data);
    // follow_up
    if(props?.permissions?.write?.indexOf("followup") > -1){
      let id = "follow_up";
      let selectedFormId = [...props.selectedForm];
      if (selectedFormId?.includes?.(id)) {

      } else {
        selectedFormId.push(id);
        props.selectedFormList(selectedFormId);
      }
    }
  };

  React.useEffect(() => {
    initFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const info = getUserInfo();
  const [isMedication, setIsMedication] = React.useState(false);
  const [isOrder, setIsOrder] = React.useState(false);
  const [typeClicked, setTypeClicked] = React.useState("");
  const [Openstatus, setOpenstatus] = React.useState(null);
  const [statusDetails, setStatusDetails] = React.useState({
    status: { label: "On Hold", value: "On Hold" },
    data: {},
  });

  const handleOpenStatus = (e, data, index, type, val) => {
    if (type === 'medication') {
      setTypeClicked("medication");
      setIsOrder(true);
      setIsMedication(true);
    } else {
      if (type === 'treatment' || type === 'rad' || type === 'lab') {
        setTypeClicked(type);
        setIsOrder(true);
      } else {
        setTypeClicked("");
        setIsOrder(false);
      }
      setIsMedication(false);
    }
    if (val) {
      setStatusDetails({
        status: val,
        data: data,
      });
    }
    setOpenstatus(e?.currentTarget);
  };

  const { parent_id } = props;

  return (
    <>
      {/* allergy Card */}

      <>
            {allergiesData.encounter_Based_A.data.length > 0 &&
              <Row
                id={`${parent_id}_signoff__parent_row`}
                inLineStyles={styles.vitailsCard}
              >
                {/* Add a new allergy data's */}
                <Col
                  id={`${parent_id}_signoff__parent_col`}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={styles.vitalsHead}
                >
                  <div
                    id={`${parent_id}_signoff__Allergy_div`}
                    style={styles.vitalsfieldHead}
                  >
                    <Text
                      id={`${parent_id}_signoff__Allergy_text`}
                      inLineStyles={{ fontWeight: 600, fontSize: "14px" }}
                    >
                      {"Allergy"}
                    </Text>
                  </div>
                </Col>
              {allergiesData.encounter_Based_A.data.length > 0 &&
				      	allergiesData.encounter_Based_A.data.map((val, i) => {
               // if (!val.isNew) {
               //   return <React.Fragment />;
                // }
                return (
                <Col
                  id={`${parent_id}_signoff_${i}_Allergy_col`}
                  key={i}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={{
                    padding: "14px",
                    borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                  }}
                >
                  <Row id={`${parent_id}_signoff_${i}_Allergy_row`}>
                    <Col
                      id={`${parent_id}_signoff_${i}_col`}
                      xs={12} sm={12} md={8} lg={8} xl={8}>
                      {val.allergenCode.label && (
                        <H6
                          id={`${parent_id}_signoff_${i}_${val.allergenCode.label}_h6`}
                          inLineStyles={{ fontSize: 14, paddingBottom: 6, color: UIColor.secondaryButtonColor.color, fontWeight: 400 }}>
                          {val.allergenCode.label}
                        </H6>
                      )}
                      <div
                        id={`${parent_id}_signoff_${i}_severity_div`}
                        style={{ display: "flex" }}
                      >
                        {val.severity && (
                          <Text
                            id={`${parent_id}_signoff_${i}_${val.severity.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.textColor}
                            style={{ display: "flex", padding: 0 }}
                            className={`${val.severity}`}
                          >
                            <InfoIcon
                              id={`${parent_id}_signoff_${i}_severity_infoicon`}
                              width={12}
                              style={{ marginRight: 6 }}
                              color={getColor[val.severity]}
                            />
                            {val.severity}
                          </Text>
                        )}
                        {val.type && (
                          <Text
                            id={`${parent_id}_signoff_${i}_${val.type.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.type}
                          </Text>
                        )}
                        {val.reactions.length > 0 && (
                          <Text
                            id={`${parent_id}_signoff_${i}_description_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" "}
                            {" - "}
                            {val.reactions.map((re, i) => {
                              return (
                                <>
                                  <span
                                    id={`${parent_id}_signoff_${i}_${re.description.replaceAll(
                                      " ",
                                      "_"
                                    )}_text`}
                                  >
                                    {re.description}
                                    {i + 1 > val.reactions.length &&
                                    i + 1 !== val.reactions.length
                                      ? ", "
                                      : " "}
                                  </span>
                                </>
                              );
                            })}
                          </Text>
                        )}
                        {val.verify && (
                          <Text
                            id={`${parent_id}_signoff_${i}_${val.verify.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.verify}
                          </Text>
                        )}
                        {val.currentStatus && (
                          <Text
                            id={`${parent_id}_signoff_${i}_${val.currentStatus.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.statusTag}
                          >
                            {val.currentStatus}
                          </Text>
                        )}
                        {val.clinicalStatus && (
                          <Text
                            id={`${parent_id}_signoff_${i}_${val.clinicalStatus.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={val.clinicalStatus === "Active" ? styles.statustextColor : val.clinicalStatus === "Inactive" ? styles.statusInactiveColor : styles.statusResolvedColor}
                            onClick={(e) => handleOpenStatus(e, val, i)}
                          >
                            {" •"} {val.clinicalStatus}
                          </Text>
                        )}
                      </div>
                    </Col>
                    <Col
                      id={`${parent_id}_signoff_${i}_On_col`}
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <Div id={`${parent_id}_signoff_${i}_On_div`}>
                        <div
                          id={`${parent_id}_signoff_${i}_On_sub_div`}
                          style={styles.userInfo}
                        >
                          <Text
                            id={`${parent_id}_signoff_${i}_On_text`}
                            inLineStyles={styles.textColor}
                            style={{ marginBottom: 8 }}
                          >
                            {"On "}
                            {utcTOLocal(val?.createddate,"DD MMM, YYYY")}
                          </Text>
                          <Text
                            id={`${parent_id}_signoff_${i}_On_name_text`}
                            inLineStyles={styles.textColor}
                          >
                            {/* { val?.practitioner_id?.[0]?.name?.[0]?.prefix?.[0]?.coding?.[0]?.display +	" " +
														val?.practitioner_id?.[0]?.name?.[0]?.text + " " +
														val?.practitioner_id?.[0]?.name?.[0]?.given+ " "+
														val?.practitioner_id?.[0]?.name?.[0]?.family+ " "+
														val?.practitioner_id?.[0]?.name?.[0]?.suffix?.[0]?.coding?.[0]?.display + " "+
														"(You)"} */}
                            {makeName(val?.practitioner_id?.[0]?.name?.[0] || {}) + " (You)"}
                          </Text>
                        </div>
                      </Div>
                    </Col>
                  </Row>
                </Col>
                  );
                })}
              </Row>
            }
      </>

      {/* Diagnosis card */}

        {storeDataDiagnosis?.Diagnosis_read?.data?.length > 0 &&
            <Row
              id={`${parent_id}_signoff__Diagnosis_row`}
              inLineStyles={styles.vitailsCard}
            >
              <Col
                id={`${parent_id}_signoff__Diagnosis_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.vitalsHead}
              >
                <div
                  id={`${parent_id}_signoff__Diagnosis_div`}
                  style={{ display: "flex" }}
                >
                  <Text
                    id={`${parent_id}_signoff__Diagnosis_text`}
                    style={styles.vitalsfieldHead}
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {"Diagnosis"}
                  </Text>
                </div>
              </Col>
              {storeDataDiagnosis?.Diagnosis_read?.data?.length > 0 &&
              storeDataDiagnosis?.Diagnosis_read?.data?.map((val, i) => {
                 // if (!val.isNew) {
                 //   return <React.Fragment />;
                // }
             return (
              <Col
                id={`${parent_id}_signoff_${i}_Diagnosis_parent_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  padding: "14px",
                  borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                }}
              >
                <Row id={`${parent_id}_signoff_${i}_Diagnosis_parent_row`}>
                  <Col
                    id={`${parent_id}_signoff_${i}_Diagnosis_col`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    {val.diagnosis && (
                      <H6
                        id={`${parent_id}_signoff_${i}_${val.diagnosis?.label.replaceAll(
                          " ",
                          "_"
                        )}_h6`}
                        inLineStyles={{ fontSize: 14, color: UIColor.secondaryButtonColor.color, fontWeight: 400}}
                      >
                        {val.diagnosis?.label}
                      </H6>
                    )}
                    {val.use && (
                      <Text
                        id={`${parent_id}_signoff_${i}_${val.use?.label.replaceAll(
                          " ",
                          "_"
                        )}_text`}
                        inLineStyles={styles.textColor1}
                      >
                        {val.use?.label}
                      </Text>
                    )}
                    {val.verification && (
                      <Text
                        id={`${parent_id}_signoff_${i}_${val.verification?.label.replaceAll(
                          " ",
                          "_"
                        )}_text`}
                        inLineStyles={styles.textColor}
                      >
                        {" - "}
                        {val.verification?.label}
                      </Text>
                    )}
                    {val.onset.day.length > 0 && (
                      <Text
                        id={`${parent_id}_signoff_${i}_${
                          val.onset &&
                          "_ " + val.onset?.freq?.label.replaceAll(" ", "_")
                        }_text`}
                        inLineStyles={styles.textColor}
                      >
                        {" - Since "}
                        {val.onset.day}
                        {val.onset && ", " + val.onset?.freq?.label}
                      </Text>
                    )}
                    {val?.clinicalStatus && (
                      <Text
                        id={`${parent_id}_signoff_${i}_${val?.clinicalStatus?.label.replaceAll(
                          " ",
                          "_"
                        )}_text`}
                        inLineStyles={val.clinicalStatus.label === "Active" ? styles.statustextColor : val.clinicalStatus.label === "Inactive" ? styles.statusInactiveColor : styles.statusResolvedColor}
                        onClick={(e) => handleOpenStatus(e, val, i)}
                      >
                        {" •"} {val?.clinicalStatus?.label}
                      </Text>
                    )}
                  </Col>
                  <Col
                    id={`${parent_id}_signoff_${i}_Diagnosis_on_col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Div id={`${parent_id}_signoff_${i}_Diagnosis_on_div`}>
                      <div style={styles.userInfo} className={"userHide"}>
                        <Text
                          id={`${parent_id}_signoff_${i}_Diagnosis_on_text`}
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {val?.createdAt ?? getData()}
                        </Text>
                        <Text
                          id={`${parent_id}_signoff_${i}_${val?.createdName?.prefix?.[0]?.[0]?.display}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {makeName(val?.createdName || {}) + " (You)" ?? "(You)"}
                        </Text>
                      </div>
                    </Div>
                  </Col>
                </Row>
              </Col>
              );
            })}
          </Row>
        } 

      {/* chief Complaints card */}
          {cheifComplaints.list.length > 0 &&
            <Row
              id={`${parent_id}_signoff__Chief_Complaints_row`}
              inLineStyles={styles.vitailsCard}
            >
              {/* Add a new allergy data's */}
              <Col
                id={`${parent_id}_signoff__Chief_Complaints_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.vitalsHead}
              >
                <div
                  id={`${parent_id}_signoff__Chief_Complaints_div`}
                  style={{ display: "flex" }}
                >
                  <Text
                    id={`${parent_id}_signoff__Chief_Complaints_text`}
                    style={styles.vitalsfieldHead}
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {"Chief Complaints"}
                  </Text>
                </div>
              </Col>
              
              {cheifComplaints.list.length > 0 &&
                cheifComplaints.list.map((val, i) => {
                   // if (!val.isNew) {
                  //   return <React.Fragment />;
                    // }
               return (
              <Col
                id={`${parent_id}_signoff_${i}_Chief_Complaints_sub_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  padding: "14px",
                  borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                }}
              >
                <Row id={`${parent_id}_signoff_${i}_Chief_Complaints_sub_row`}>
                  <Col
                    id={`${parent_id}_signoff_${i}_Chief_Complaints_col`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    {val.name && (
                      <H6
                        id={`${parent_id}_signoff_${i}_Chief_${val.name?.label.replaceAll(
                          " ",
                          "_"
                        )}_h6`}
                        inLineStyles={{ fontSize: 14, paddingBottom: 6, color: UIColor.secondaryButtonColor.color, fontWeight: 400 }}
                      >
                        {val.name?.label}
                      </H6>
                    )}
                    <div
                      id={`${parent_id}_signoff_${i}_Chief_${val.name?.label.replaceAll(
                        " ",
                        "_"
                      )}_h6`}
                      style={{ display: "flex" }}
                    >
                      {val.severity && (
                        <div
                          id={`${parent_id}_signoff_${i}_Chief_div`}
                          style={{
                            display: "flex",
                            padding: 0,
                            alignItems: "flex-start",
                          }}
                          className={`${val.severity}`}
                        >
                          <Text
                            id={`${parent_id}_signoff_${i}_Chief_infoicon_text`}
                          >
                            <InfoIcon
                              id={`${parent_id}_signoff_${i}_Chief_infoicon`}
                              width={12}
                              style={{ marginRight: 6 }}
                              color={getColor[val.severity]}
                            />
                          </Text>
                          <Text
                            id={`${parent_id}_signoff_${i}_Chief_${val.severity?.label.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            style={{ fontSize: 12 }}
                            className={`${val.severity}`}
                          >
                            {val.severity?.label}
                          </Text>
                        </div>
                      )}
                      <div
                        id={`${parent_id}_signoff_${i}_Chief_parent_div`}
                        inLineStyles={styles.textColor}
                        style={{ display: "flex", padding: 0 }}
                        className={`${val.severity}`}
                      >
                        {val.bodySite && (
                          <Text
                            id={`${parent_id}_signoff_${i}_Chief_${val.bodySite?.label.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.bodySite?.label}
                          </Text>
                        )}
                        {/* {val.remarks.length > 0 && <Text inLineStyles={styles.textColor}>{" - "}{val.remarks.join().replaceAll(",", ", ")}</Text>} */}
                        {val.status_verification && (
                          <Text
                            id={`${parent_id}_signoff_${i}_Chief_${val.status_verification?.label.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.status_verification?.label}
                          </Text>
                        )}
                        {val.currentStatus && (
                          <Text
                            id={`${parent_id}_signoff_${i}_Chief_${val.currentStatus?.label.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={styles.statusTag}
                            onClick={(e) => handleOpenStatus(e, val, i)}
                          >
                            {val.currentStatus?.label}
                          </Text>
                        )}
                         {val.clinicalStatus && (
                          <Text
                            id={`${parent_id}_signoff_${i}_${val.clinicalStatus.label.replaceAll(
                              " ",
                              "_"
                            )}_text`}
                            inLineStyles={val.clinicalStatus.label === "Active" ? styles.statustextColor : val.clinicalStatus.label === "Inactive" ? styles.statusInactiveColor : styles.statusResolvedColor}
                            onClick={(e) => handleOpenStatus(e, val, i)}
                          >
                            {" •"} {val.clinicalStatus.label}
                          </Text>
                        )}
                        
                      </div>
                    </div>
                  </Col>
                  <Col
                    id={`${parent_id}_signoff_${i}_Chief_On_col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Div id={`${parent_id}_signoff_${i}_Chief_On_div`}>
                      <div
                        id={`${parent_id}_signoff_${i}_Chief_On_sub_div`}
                        style={styles.userInfo}
                        className={"userHide"}
                      >
                        <Text
                          id={`${parent_id}_signoff_${i}_Chief_On_text`}
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {val?.createdAt ?? getData()}
                        </Text>
                        <Text
                          id={`${parent_id}_signoff_${i}_Chief_${(val?.createdName?.prefix?.[0]?.[0]?.display || "")?.replaceAll(
                            " ",
                            "_"
                          )}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {makeName(val?.createdName) + " (You)" ?? "(You)"}
                        </Text>
                      </div>
                    </Div>
                  </Col>
                </Row>
              </Col>
               );
              })}      
            </Row>
          }  
         
      {/* labOrder Flow */}
      {laborders.list.length > 0 && (
        <Row inLineStyles={styles.vitailsCard}>
          <Col
            id={`${parent_id}_signoff_Lab_Orders_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <div
              id={`${parent_id}_signoff_Lab_Orders_div`}
              style={{ display: "flex" }}
            >
              <Text
                id={`${parent_id}_signoff_Lab_Orders_text`}
                style={styles.vitalsfieldHead}
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                {"Lab Orders"}
              </Text>
            </div>
          </Col>
          {laborders.list.length > 0 &&
            laborders.list.map((val, i) => {
              return (
                <Col
                  id={`${parent_id}_signoff_${i}_Lab_Orders_col`}
                  key={i}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={{
                    padding: "14px",
                    borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                    overflow: "hidden",
                  }}
                >
                  <LabOrderList
                    parent_id={"signoff_comp" + i}
                    signOff
                    treatment_order={val.orderCatalog?.label}
                    priority={val?.priority?.label}
                    nature={val?.nature?.label}
                    dateTime={val.dateTime}
                    ordersData={val?.orders}
                    status={
                      val?.statusData?.status?.label ??
                      val?.childRecurrent?.[0]?.statushistory?.length > 0
                        ? val?.childRecurrent?.[0]?.statushistory?.[
                            val?.childRecurrent?.[0]?.statushistory?.length - 1
                          ]?.new_status ?? "Draft"
                        : "Draft"
                    }
                    handleOpenStatus={handleOpenStatus}
                    //onEditChart={props?.onEditChart}
                    data={val}
                    val={val}
                    IndexValue={i}
                    //deleteList={deleteList}
                    //editFun={editFun}
                    info={info}
                    getData={getData}
                    patientgetData={props.patientgetData}
                    recurrentData={val?.recurrentData}
                  />
                </Col>
              );
            })}
        </Row>
      )}

      {/* medication order card */}

      {medicationsData.list.length > 0 &&
        medicationsData.list.map((val, i) => {
          return (
            <Row
              id={`${parent_id}_signoff_${i}_Medication_Orders_row`}
              inLineStyles={styles.vitailsCard}
            >
              {/* Add a new allergy data's */}
              <Col
                id={`${parent_id}_signoff_${i}_Medication_Orders_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.vitalsHead}
              >
                <div
                  id={`${parent_id}_signoff_${i}_Medication_Orders_div`}
                  style={{ display: "flex" }}
                >
                  <Text
                    id={`${parent_id}_signoff_${i}_Medication_Orders_text`}
                    style={styles.vitalsfieldHead}
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {"Medication order"}
                  </Text>
                </div>
              </Col>
              <Col
                id={`${parent_id}_signoff_${i}_Medication_Orders_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  padding: "14px",
                  borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                }}
              >
                <Row id={`${parent_id}_signoff_${i}_Medication_Orders_row`}>
                  <Col
                    id={`${parent_id}_signoff_${i}_Medication_Orders_sub_col`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    {val.name && (
                      <H6
                        id={`${parent_id}_signoff_${i}_Medication_${(
                          val?.name?.name || ""
                        ).replaceAll(" ", "_")}_h6`}
                        inLineStyles={{ fontSize: 14, paddingBottom: 6, color: UIColor.secondaryButtonColor.color, fontWeight: 400 }}
                      >
                        {val.name?.name}
                      </H6>
                    )}
                    <div
                      id={`${parent_id}_signoff_${i}_Medication_div`}
                      style={{ display: "flex" }}
                    >
                      {val.name && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${(
                            val?.name?.drugType || ""
                          ).replaceAll(" ", "_")}_text`}
                          inLineStyles={styles.textColor}
                          style={{ display: "flex", padding: 0 }}
                        >
                          {val.name?.drugType}
                        </Text>
                      )}
                      {val.doseMeasurementValue && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${`${val.doseMeasurementValue}`?.replaceAll(
                            " ",
                            "_"
                          )}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {" - "}
                          {val.doseMeasurementValue}
                        </Text>
                      )}
                      {val.unitValue && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${(
                            val?.unitValue?.label || ""
                          ).replaceAll(" ", "_")}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {val.unitValue?.label}
                        </Text>
                      )}
                      {val.unitsTypes && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${(
                            val?.unitsTypes?.label || ""
                          ).replaceAll(" ", "_")}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {" - "}
                          {val.unitsTypes?.label}
                        </Text>
                      )}
                      {val.dosetime && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${`${val.dosetime}`.replaceAll(
                            " ",
                            "_"
                          )}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {" - "}
                          {val.dosetime}
                        </Text>
                      )}
                      {val.doseOption && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${(
                            val.doseOption?.label || ""
                          ).replaceAll(" ", "_")}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {val.doseOption.label}
                        </Text>
                      )}
                      {/* {val.status?.label && (
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${(
                            val.status?.label ?? ""
                          ).replaceAll(" ", "_")}_text`}
                          onClick={(e) => {
                            if (val?.statushistory?.length >= 1) {
                              handleOpenStatus(
                                e,
                                val,
                                i,
                                "medication",
                                val.status
                              );
                            }
                          }}
                          inLineStyles={{
                            ...styles.statusColor,
                            color: statusColors[val.status?.label].color,
                            cursor:
                              val?.statushistory?.length >= 1
                                ? "pointer"
                                : "unset",
                          }}
                        >
                          {" •"}
                          {val.status?.label ?? ""}
                        </Text>
                      )} */}
                      {val.status?.label && (
                      <Text
                        id={`${parent_id}_signoff_${i}_${val.status?.label.replaceAll(
                          " ",
                          "_"
                        )}_text`}
                        inLineStyles={val.status?.label === "Active" ? styles.statustextColor : val.status?.label === "Inactive" ? styles.statusInactiveColor : styles.statusDraftColor}
                        onClick={(e) => handleOpenStatus(e, val, i)}
                      >
                        {" •"} {val.status?.label}
                      </Text>
                    )}
                    </div>
                  </Col>
                  <Col
                    id={`${parent_id}_signoff_${i}_Medication_On_col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Div
                      id={`${parent_id}_signoff_${i}_Medication_On_div`}
                      style={{ right: props.open ? 10 : -100 }}
                    >
                      <div style={styles.userInfo}>
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_On_text`}
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {val.createdAt}
                        </Text>
                        <Text
                          id={`${parent_id}_signoff_${i}_Medication_${(
                            val.createdName || ""
                          ).replaceAll(" ", "_")}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {val.createdName}
                        </Text>
                      </div>
                    </Div>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}

      <Popover
        id={"calender-popover"}
        open={Openstatus}
        anchorEl={Openstatus}
        onClose={() => {
          setOpenstatus(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        classes={{ paper: styles.muiPopovers }}
      >
        <ChangeStatus
          isSignOff
          cancelButton="Cancel"
          cancelButtonAction={() => setOpenstatus(null)}
          saveButton="Save"
          saveButtonAction={async (data) => {
            let isUpdate = false;
            if (data?.status?.value && statusDetails?.data?.status) {
              if (isMedication) {
                if (
                  data?.status?.value !== statusDetails?.data?.status?.value
                ) {
                  isUpdate = true;
                }
              } else {
                if (data?.status?.value !== statusDetails?.data?.status) {
                  isUpdate = true;
                }
              }
            }
            if (isUpdate && isOrder) {
              const updatedStatusData = await dispatch(
                actions["UPDATE_INDIVIDUAL_STATUS"]({
                  personId: personDetails._id,
                  id: isMedication
                    ? statusDetails?.data?._id
                    : statusDetails?.data?.editData,
                  status: data?.status,
                  type: typeClicked,
                  reason: data.reason,
                  val: statusDetails.data,
                })
              );
              const { isError, errMsg } = checkError(
                updatedStatusData?.payload
              );
              if (isError) {
              } else {
                if (typeClicked === "medication") {
                  const orderData = props.medicationsData;
                  const updateContext = props.medicationsData.setMedication;
                  const newData = JSON.parse(JSON.stringify(orderData.list));
                  const dataTobeUpdated = newData.find(
                    (d) => d._id === statusDetails?.data?._id
                  );
                  const newStatus = medOrderStatus?.data.find(
                    (d) => d.value === updatedStatusData.payload?.data?.status
                  );
                  dataTobeUpdated.status = newStatus;
                  dataTobeUpdated.statushistory =
                    updatedStatusData.payload?.data?.statushistory;
                  updateContext({
                    ...orderData,
                    list: newData,
                  });
                } else {
                  let orderData;
                  let updateContext;
                  if (typeClicked === "treatment") {
                    orderData = props.treatment;
                    updateContext = props.treatment.setTreatment;
                  } else if (typeClicked === "rad") {
                    orderData = props.radiology;
                    updateContext = props.radiology.setRadiology;
                  } else if (typeClicked === "lab") {
                    orderData = props.LabOrder;
                    updateContext = props.LabOrder.setLabOrder;
                  }
                  if (orderData && updateContext) {
                    const newData = JSON.parse(JSON.stringify(orderData.list));
                    const dataTobeUpdated = newData.find(
                      (d) => d.editData === statusDetails?.data?.editData
                    );
                    dataTobeUpdated.status =
                      updatedStatusData.payload?.data?.status;
                    dataTobeUpdated.statushistory =
                      updatedStatusData.payload?.data?.statushistory;
                    updateContext({
                      ...orderData,
                      list: newData,
                    });
                  }
                }
              }
            }
            setOpenstatus(null);
          }}
          statusOption={
            isMedication
              ? stateList?.status_masters?.data
              : signOffSlice?.getRecordStatus?.data
          }
          changeStatusData={statusDetails?.status}
          statusReason={statusDetails?.statusReason}
          statusperiod={statusDetails?.statusperiod}
          statusHistory={statusDetails?.data?.statushistory ?? []}
        />
      </Popover>
      {/* Radiology */}

      {radiologyData.list.length > 0 && (
        <Row
          id={`${parent_id}_signoff_Radiology_parent_row`}
          inLineStyles={styles.vitailsCard}
        >
          {/* Add a new treatment procedures data's */}
          <Col
            id={`${parent_id}_signoff_Radiology_parent_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <div
              id={`${parent_id}_signoff_Radiology_div`}
              style={{ display: "flex" }}
            >
              <Text
                id={`${parent_id}_signoff_Radiology_text`}
                style={styles.vitalsfieldHead}
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                {"Radiology Order"}
              </Text>
            </div>
          </Col>
          {radiologyData.list.length > 0 &&
            radiologyData.list.map((val, i) => {
              // if (!val.isNew) {
              //   return <React.Fragment />;
              // }
              return (
                <Col
                  id={`${parent_id}_signoff_${i}_Radiology_text`}
                  key={i}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={{
                    padding: "14px",
                    borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                    overflow: "hidden",
                  }}
                >
                  <RadiologyList
                    parent_id={"signoff_comp"}
                    treatment_order={val?.orderCatalog?.label}
                    priority={val?.priority?.label}
                    nature={val?.nature?.label}
                    dateTime={val.dateTime}
                    ordersData={val?.orders}
                    onEditChart={props?.onEditChart}
                    handleOpenStatus={handleOpenStatus}
                    val={val}
                    status={
                      val?.statusData?.status?.label ??
                      val?.childRecurrent?.[0]?.statushistory?.length > 0
                        ? val?.childRecurrent?.[0]?.statushistory?.[
                            val?.childRecurrent?.[0]?.statushistory?.length - 1
                          ]?.new_status ?? "Draft"
                        : "Draft"
                    }
                    data={val}
                    IndexValue={i}
                    signOff
                    //   deleteList={deleteList}
                    //   editFun={editFun}
                    info={info}
                    getData={getData}
                    patientgetData={props.patientgetData}
                  />
                </Col>
              );
            })}
        </Row>
      )}

      {/* treatment procedure */}

      {treatmentData.list.length > 0 && (
        <Row
          id={`${parent_id}_signoff_Treatment_row`}
          inLineStyles={styles.vitailsCard}
        >
          {/* Add a new treatment procedures data's */}
          <Col
            id={`${parent_id}_signoff_Treatment_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <div
              id={`${parent_id}_signoff_Treatment_div`}
              style={{ display: "flex" }}
            >
              <Text
                id={`${parent_id}_signoff_Treatment_text`}
                style={styles.vitalsfieldHead}
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                {"Treatment & Procedures"}
              </Text>
            </div>
          </Col>
          {treatmentData.list.length > 0 &&
            treatmentData.list.map((val, i) => {
              return (
                <Col
                  id={`${parent_id}_signoff_${i}_Treatment_col`}
                  key={i}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={{
                    padding: "14px",
                    borderTop:  `1px solid ${UIColor?.lineBorderFill?.color}`,
                    overflow: "hidden",
                  }}
                >
                  <TreatmentProcedureList
                    parent_id={"signoff_comp"}
                    signOff
                    treatment_order={val?.orderCatalog?.label}
                    priority={val?.priority?.label}
                    nature={val?.nature?.label}
                    dateTime={val?.dateTime}
                    ordersData={val?.orders}
                    status={
                      val?.statusData?.status?.label ??
                      val?.childRecurrent?.[0]?.statushistory?.length > 0
                        ? val?.childRecurrent?.[0]?.statushistory?.[
                            val?.childRecurrent?.[0]?.statushistory?.length - 1
                          ]?.new_status ?? "Draft"
                        : "Draft"
                    }
                    onEditChart={props?.onEditChart}
                    val={val}
                    data={val}
                    handleOpenStatus={handleOpenStatus}
                    IndexValue={i}
                    //deleteList={deleteList}
                    //editFun={editFun}
                    info={info}
                    getData={getData}
                    patientgetData={props.patientgetData}
                  />
                </Col>
              );
            })}
        </Row>
      )}

      {/* vitals Card */}
      {/* {JSON.stringify(viatalsDatalist)} */}
      {(Object.keys(viatalsDatalist).length > 0 && viatalsDatalist) &&  
          <VitalSigns viewOnly />
      }

      {/* Followup Card */}
      {props?.formsList?.includes("follow_up") && (
          <FollowupFotter
            style={{borderRadius: "16px"}}
            followup_number={props.followup_number}
            followup_type={props.followup_type}
            Isdisabled={true}
            followupHnadle={props.followupHnadle}
            handleInputChange={props.handleInputChange}
            tabHandle={props.tabHandle}
            reasonData_={props.reasonData_}
            followup_reason_={props.followup_reason_}
            handleselectChange={props.handleselectChange}
            followupuom={props.followupuom}
          />
        )}
    </>
  );
};

export default withAllContexts(SignOffCard);
