/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, H6, Icons, Div, Image } from "qdm-component-library";

import { makeStyles, TextField, Typography } from "@material-ui/core";
import {
	Button,
	Chip,
	Tooltip,
	Snackbar,
	IconButton,
	Dialog,
	DialogContent,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import {
	NoAccessIcon,
	PillsIcon,
	InsightsIcon,
	InfoIcon,
	NoAllergyIcon,
} from "../../../components";
import { DialogContext } from "../../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { withAllContexts } from "../../../HOCs";
import { getData, getUserInfo, makeName, UIColor, utcTOLocal } from "../../../utils";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";

import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();
const styles = {
	vitalsHead: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		background: "#fff",
		borderRadius: 8,
    padding: "0px 10px 0px 10px",
	},
  vitalsHead1: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		background: "#fff",
		borderRadius: 8,
    padding: "12px 10px",
	},
	vitalsfieldHead: {
		padding: "6px 10px",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		background: "#fff",
		borderRadius: 8,
		flexWrap: "wrap",
	},
	vitailsCard: {
		//border: `1px solid ${UIColor.lineBorderFill.color}`,
		borderRadius: 10,
		width: "100%",
		marginBottom: "12px !important",
    backgroundColor: "#fff"
	},
	labelStyle: {
		color: UIColor.tertiaryText.color,
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
	},
	inputStyle: {
		padding: 5,
		marginBottom: 10,
	},
	inputFiledStyle: {
		borderColor: UIColor.lineBorderFill.color,
		borderRadius: "8px",
	},
	listVitals: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	tagsStyle: {
		padding: "4px 12px",
		borderRadius: "50px",
		border: `1px solid ${UIColor.primaryColor.color}`,
		color: UIColor.primaryColor.color,
		background: UIColor.differentiationBackground.color,
		fontSize: "10px",
	},
	list: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	borderStyle: {
		borderColor: UIColor.lineBorderFill.color,
		backgroundColor: `${UIColor.greyBackground.color} !important`,
		marginRight: 16,
	},
	borderNoneStyle: {
		borderColor: UIColor.greyBackground.color,
		backgroundColor: UIColor.greyBackground.color,
		marginRight: 16,
	},
	infoBell: {
		width: "50px",
		display: "flex",
		padding: "12px",
		borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 14,
	},
	icons: {
		cursor: "pointer",
	},
	flexFlow: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	noAccessTag: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "2px 6px",
		borderRadius: "4px",
		fontWeight: 400,
		marginLeft: 12,
		backgroundColor: "#FAD7D6",
		color: UIColor.error.color,
		fontSize: "12px",
	},
  textColor1: {
    fontSize: 12,
    color: UIColor.tertiaryText.color
  },
  textColor: {
    fontSize: 12,
    paddingLeft: 6,
    color: UIColor.tertiaryText.color
  },

	statusTag: {
		color: "#000",
		fontSize: 12,
		backgroundColor: `1px solid ${UIColor.lineBorderFill.color}`,
		borderRadius: "4px",
		padding: "2px 6px",
		marginLeft: 6,
		width: "fit-content",
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
	},
	editAllergyIcon: {
		padding: "6px 10px",
		//background: UIColor.greyBackground.color,
		borderRadius: "50px",
		cursor: "pointer",
	},
	floatingLable: {
		backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
		position: "absolute",
		top: "-8px",
		fontWeight: 500,
		fontSize: "10px",
	},
	knownAllergyedit: {
		backgroundColor: `1px solid ${UIColor.lineBorderFill.color}`,
		color: "#000",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "2px 6px",
		borderRadius: "4px",
		fontWeight: 400,
		marginLeft: 12,
		fontSize: "12px",
	},
	deleteHeader: {
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: 1.2,
		marginBottom: "7px",
	},
	deleteButton: {
		padding: "6px",
		backgroundColor: UIColor.error.color,
		color: "#fff",
		borderRadius: "8px",
		fontSize: "14px",
		fontWeight: "600",
		textTransform: "none",
	},
	deleteButton2: {
		padding: "6px",
		borderRadius: "8px",
		fontSize: "14px",
		fontWeight: "600",
		textTransform: "none",
	},
	dialogContent: {
		textAlign: "center",
		padding: "24px",
	},
	deleteAllergyIcon: {
		padding: "6px 10px",
		//background: UIColor.greyBackground.color,
		borderRadius: "50px",
		cursor: "pointer",
		color: "red",
		marginRight: 10,
	},
};

const getColor = {
	Mild: UIColor.primaryColor.color,
	Moderate: UIColor.secondaryColor.color,
	Severe: UIColor.error.color,
};

const initialState = {
	name: {},
	type: {},
	severity: {},
	status_verification: {},
	reactions: [],
	drugAllergy: false,
	noAccess: false,
	knownAllergy: false,
	headerShow: true,
};

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: "325px",
		margin: "auto",
	},
  autocomplete: {
    "& .MuiInputBase-input": {
        color: UIColor.secondaryText.color,
        fontSize: "12px !important",
         fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
        //height: 40,
        color: UIColor.secondaryText.color
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: `1px solid ${UIColor.lineBorderFill.color} !important`,
            borderRadius: 8
        },
        '&:hover fieldset': {
            border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        },
        '&.Mui-focused fieldset': {
            borderWidth: 1,
            border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        }
    },
    "& .MuiAutocomplete-popupIndicator": {
        transform: "none !important",
    }
}
}));

const Allergy = (props) => {
	const dispatch = useDispatch();
	const allergiesData = useSelector((state) => state?.allergyMastersSlice);
	const info = getUserInfo();
	const searchPractioner = useSelector(
		(state) => state?.personApiSlice?.searchPractioner
	);
	const encounterData = useSelector(
		(state) => state?.chiefCompilenceMastersSlice?.encounter_Details
	);

	let allergys = props?.allergy;

	const classes = useStyles();

	const [allergyEntry, setAllergyEntry] = React.useState(initialState);

	const [allergyList, setAllergyList] = React.useState({
		list: [],
		drugAllergy: false,
		noAccess: false,
		knownAllergy: false,
	});

	const [editTab, setEditTab] = React.useState(true);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [deleteOpen, setDeleteOpen] = React.useState(false);
	const dialog = React.useContext(DialogContext);
	const [masters, setMasters] = React.useState([]);

	const handleAllergy = async (v) => {
		if (v?.length > 3) {
			if (allergyEntry.drugAllergy) {
				let ccMaster = await dispatch(
					actions.ALLERGY_MASTERS_VALUE({ func: "knownallergy", input_text: v })
				);

				let ccMasters = ccMaster?.payload?.data;

				let datalist = allergys.list.map((val) => val.allergenCode.label);
				let masterData = ccMasters.filter(
					(val) => datalist.indexOf(val.label) === -1
				);

				setMasters(masterData);
			} else {
				let ccMaster = await dispatch(
					actions.ALLERGY_MASTERS_VALUE({ func: "onchange", input_text: v })
				);

				let ccMasters = ccMaster?.payload?.data;

				let datalist = allergys.list.map((val) => val.allergenCode.label);
				let masterData = ccMasters.filter(
					(val) => datalist.indexOf(val.label) === -1
				);

				setMasters(masterData);
			}
		}
	};

	React.useEffect(() => {
		getMasterData();
		dialog.setDialog({
			title: "Are you sure want to remove this?",
			body: "Some content about the Allergy",
			positiveBtn: "Yes remove",
		});
	}, []);

  React.useEffect(() => {
    setAllergyEntry({ 
      ...allergyEntry,
      headerShow : allergiesData.encounter_Based_A.data.length > 0 ? false : true
    });
  }, []);

	const getMasterData = async () => {
		let data_pay = await dispatch(actions.ENCOUNTER_BASED_A({encounter_id :encounterData?.data?.eId}))
		if(data_pay?.payload?.data){
			props.allergy.setAllergys({
				...props.allergy,
				list: JSON.parse(JSON.stringify(data_pay?.payload?.data)),
			});
		}
		await dispatch(actions.ALLERGY_MASTER());
		await dispatch(actions.TYPE_MASTERS_A());
		await dispatch(actions.SEVERITY_MASTERS_A());
		await dispatch(actions.VERIFICATIONS_MASTERS_A());
		await dispatch(actions.REACTION_MASTER_A());
	};

	const deleteList = async (index, data) => {
		dialog.setDialog({
			...dialog,
			open: false,
		});
		props.backDrop.setBackDrop({
			...props.backDrop,
			open: true,
			message: "Deleting Allergy",
		});
		let _id = allergys?.list?.[index];
		await dispatch(actions.DELETE_A({ id: _id?.editId?._id }));
		props.backDrop.setBackDrop({
			...props.backDrop,
			open: false,
			message: "",
		});
		///remove list
		allergys.list.splice(index, 1);
		allergys.setAllergys({
			...allergys,
		});
		await dispatch(actions.ENCOUNTER_BASED_A({encounter_id :encounterData?.data?.eId}))
	};

	const handleSnackClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarOpen(false);
	};

	const handleInputChange = async (e, name) => {
		if (name === "drugAllergy") {
			allergyEntry.drugAllergy = e;
			setAllergyList({
				...allergyList,
				drugAllergy: e,
				headerShow: !allergyEntry.headerShow,
			});
			if (e) {
				let ccMaster = await dispatch(
					actions.ALLERGY_MASTERS_VALUE({
						func: "knownallergy",
						input_text: "",
					})
				);
				setMasters(ccMaster?.payload?.data);
			} else {
				setMasters([]);
			}

			return false;
		}
		if (name === "noAccess") {
			allergyEntry.noAccess = e;
			setAllergyList({
				...allergyList,
				noAccess: e,
				headerShow: !allergyEntry.headerShow,
			});
			return false;
		}
		// if (name === "name") {
		//   setAllergyEntry({
		//     ...allergyEntry,
		//     type: { value: "Medication", label: "Medication" },
		//     severity: { value: "Mild", label: "Mild" },
		//     status_verification: { value: "Unconfirmed", label: "Unconfirmed" },
		//     [name]: e,
		//   });
		//   return false;
		// }

		setAllergyEntry({
			...allergyEntry,
			[name]: e,
		});
	};

	const AddAllergy = async () => {
		// screen loading true
		props.backDrop.setBackDrop({
			...props.backDrop,
			open: true,
			message: "Adding New Allergy",
		});

		setAllergyEntry({
			...allergyEntry,
			name: {},
			type: {},
			severity: {},
			status_verification: {},
			reactions: [],
			headerShow: !allergyEntry.headerShow,
		});

		// add new allergy
		let personDetails = searchPractioner?.data?.[0];
		let searchPractionerId = personDetails?.practitioner?.[0];
    debugger
		let data = {
			status_verification: allergyEntry.status_verification.label,
			name: allergyEntry?.name?.id??allergyEntry?.name?.label,
			type: allergyEntry.type.label,
			severity: allergyEntry.severity.label,
			reactions: allergyEntry?.reactions?.map((val) => val?.label) ?? [],
			patient_id: encounterData?.data?.pID,
			encounter_id: encounterData?.data?.eId,
			practitioner_id: `${searchPractionerId?._id}`,
			practitionerrole_id: `${searchPractionerId?.PractitionerRole?.[0]?._id}`,
			clinicalStatus: "Active",
			personDetails: personDetails,
		};
		await dispatch(actions.SAVE_ALLERGY({ list: data }));

		// Get Allergy list
		let AllergyData = await dispatch(
			actions.ENCOUNTER_BASED_A({ encounter_id: encounterData?.data?.eId })
		);
		let allergys = props?.allergy;
		let dataList = JSON.parse(
			JSON.stringify({
				...{
					isNew: true,
				},
				...AllergyData?.payload?.data?.[
					AllergyData.payload?.data?.length - 1 ?? 0
				],
			})
		);
		allergys.list.push(dataList);
		allergys.setAllergys({
			...allergys,
		});

		// screen loading false
		props.backDrop.setBackDrop({
			...props.backDrop,
			open: false,
			message: "",
		});
	};

	const handleDelete = () => {
		console.info("You clicked the delete icon.");
		setDeleteOpen(true);
	};
	const handleDeleteClose = () => {
		setDeleteOpen(false);
	};

	const onEditAllergy = (index,val) => {
    debugger
		let allergys = props?.allergy;
		let getFilterData = allergys.list.filter((sys) => val.editId._id === sys.editId._id)[0];
        let editindex = allergys.list.indexOf(getFilterData);
		allergys.editIndex = editindex;
		allergys.setAllergys({ ...allergys });
		props.onEditChart(index, "allergy_form");
	};

	const handleClose = ()=>{
	if (allergiesData.encounter_Based_A.data.length > 0) {
     	setAllergyEntry({
        ...allergyEntry,
        name: {},
        type: {},
        severity: {},
        status_verification: {},
        reactions: [],
        headerShow: !allergyEntry.headerShow,
      });
    }else{
      //return false;
		  props.handleClose("allergy");
	}
	 
	}
	

	const { parent_id } = props;
	return (
    <div id={`${parent_id}_allergy_parent_div`} style={{ width: "100%" }}>
      <Row
        id={`${parent_id}_allergy_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new allergy data's */}
        <Col
          id={`${parent_id}_allergy_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={allergyEntry.headerShow ? styles.vitalsHead : styles.vitalsHead1}
        >
          {allergyEntry.headerShow ? (
            <div
              id={`${parent_id}_allergy_title_div`}
              style={styles.vitalsfieldHead}
            >
              {/* {allergyEntry.noAccess || allergyEntry.knownAllergy ? (
                <>
                  <Text
                    id={`${parent_id}_allergy_title_text`}
                    inLineStyles={{ fontWeight: 600, fontSize: "14px", color: UIColor.primaryColor.color }}
                  >
                    {"Allergy"}
                  </Text>
                  <Text
                    id={`${parent_id}_allergy_entry_text`}
                    inLineStyles={
                      allergyEntry.knownAllergy
                        ? styles.knownAllergyedit
                        : styles.noAccessTag
                    }
                  >
                    {allergyEntry.knownAllergy ? (
                      <NoAllergyIcon
                        id={`${parent_id}_allergy_NoAllergy_icon`}
                        width={12}
                        color={"#000"}
                        style={{ marginRight: 5 }}
                      />
                    ) : (
                      <NoAccessIcon
                        id={`${parent_id}_allergy_NoAccess_icon`}
                        width={12}
                        color={UIColor.error.color}
                        style={{ marginRight: 5 }}
                      />
                    )}

                    {allergyEntry.knownAllergy
                      ? "No known allergy"
                      : "Unable to access"}
                  </Text>
                </>
              ) : ( */}
                <>
                  <div
                    id={`${parent_id}_allergy_div`}
                    style={{ marginRight: 14 }}
                  >
                    {/* <Text inLineStyles={styles.floatingLable}>Allergy</Text> */}
                    <Autocomplete
                      style={{ width: 170, fontSize: "14px" }}
                      // className={"search"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      //options={allergiesData?.allery_master?.data ?? []}
                      options={masters}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${parent_id}_allergy_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      handleHomeEndKeys
                      selectOnFocus
                      clearOnBlur
                      //getOptionLabel={(option) => option.label}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          filtered.push({
                            label: `${params.inputValue}`,
                            value: `${params.inputValue}`,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}_allergy_search_textfield`}
                          {...params}
                          variant="standard"
                          placeholder="Search Allergies"
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${parent_id}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option.label}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={allergyEntry.name}
                      // onChange={(e, newValue) =>
                      //   handleInputChange(newValue, "name")
                      // }
                      freeSolo
                      onInputChange={(e) =>
                        handleAllergy(e?.target?.value ?? "")
                      }
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setAllergyEntry({
                            ...allergyEntry,
                            name: {
                              label: newValue,
                            },
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setAllergyEntry({
                            ...allergyEntry,
                            name: {
                              label: newValue,
                            },
                          });
                        } else {
                          setAllergyEntry({
                            ...allergyEntry,
                            name: newValue,
                          });
                        }
                      }}
                    />
                  </div>
                  <div
                    id={`${parent_id}_allergy_Type_div`}
                    style={{ marginRight: 14 }}
                  >
                    <Autocomplete
                      style={{ width: 120 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={allergiesData?.type_master?.data ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${parent_id}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}_allergy_type_textfield`}
                          {...params}
                          variant="standard"
                          placeholder="Type"
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${parent_id}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option.label}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={allergyEntry.type}
                      onChange={(e, newValue) =>
                        handleInputChange(newValue, "type")
                      }
                    />
                  </div>
                  <div
                    id={`${parent_id}_allergy_Severity_div`}
                    style={{ marginRight: 14 }}
                  >
                    <Autocomplete
                      style={{ width: 100 }}
                      //className={"mild"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={allergiesData?.Severity_masters?.data ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${parent_id}_allergy_Severity_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}_allergy_Severity_textfield`}
                          {...params}
                          variant="standard"
                          placeholder="Severity"
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${parent_id}_allergy_Severity_${option.label}_textfield`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option.label}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={allergyEntry.severity}
                      onChange={(e, newValue) =>
                        handleInputChange(newValue, "severity")
                      }
                    />
                  </div>
                  <div
                    id={`${parent_id}_allergy_Verification_div`}
                    style={{ marginRight: 14 }}
                  >
                    <Autocomplete
                      style={{ width: 120 }}
                      //className={"verification"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={allergiesData?.Verification_masters?.data ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${parent_id}_allergy_Verification_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}_allergy_Verification_textfield`}
                          {...params}
                          variant="standard"
                          placeholder="Verification"
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${parent_id}_allergy_Verification_${option.label}_textfield`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option.label}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={allergyEntry.status_verification}
                      onChange={(e, newValue) =>
                        handleInputChange(newValue, "status_verification")
                      }
                    />
                  </div>
                  <div
                    id={`${parent_id}_allergy_Add_Reaction_div`}
                    style={{ marginRight: 14,width: "140px" }}
                  >
                    <Autocomplete
                      //className={"addReaction"}
                      className={classes.autocomplete}
                      style={{ width: 140, marginTop: 3 }}
                      multiple
                      // freeSolo
                      popupIcon={""}
                      id="size-small-standard"
                      size="small"
                      options={allergiesData?.reaction_master?.data ?? []}
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}_allergy_Add_Reaction_textfield`}
                          InputProps={{ disableUnderline: true }}
                          {...params}
                          variant="standard"
                          placeholder="Add Reaction"
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${parent_id}_allergy_Add_Reaction_typography`}
                          style={{ width: "100%" }}
                        >
                          {option.label}
                        </Typography>
                      )}
                      closeIcon={<p>T</p>}
                      renderTags={(value, getTagProps) => {
                        let reactionsData = value;
                        return reactionsData.map((item, i) => (
                          <Chip
                            id={`${parent_id}_${i}_allergy_chip`}
                            size={"small"}
                            style={{ borderRadius: 6, marginRight: "5px" }}
                            label={item.label}
                            // onDelete={handleDelete}
                          />
                        ));
                      }}
                      value={allergyEntry.reactions}
                      onChange={(e, newValue) =>
                        handleInputChange(newValue, "reactions")
                      }
                    />
                  </div>
                </>
              {/* )} */}
            </div>
          ) : (
            <div
              id={`${parent_id}_allergy_headerShow_div`}
              style={{ display: "flex",width:"80%",justifyContent:"space-between", paddingLeft: "14px" }}
            >
              <Text
                onClick={() =>
                  handleInputChange(!allergyEntry.headerShow, "headerShow")
                }
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                  color: UIColor.primaryColor.color 
                }}
              >
                {"Allergy"}
              </Text>
              {allergyList.noAccess && (
                <Text
                  id={`${parent_id}_allergy_Unable_to_access_text`}
                  inLineStyles={styles.noAccessTag}
                >
                  <NoAccessIcon
                    id={`${parent_id}_allergy_Unable_to_access_noaccess_icon`}
                    width={12}
                    color={UIColor.error.color}
                    style={{ marginRight: 5 }}
                  />{" "}
                  {"Unable to access"}
                </Text>
              )}
              {/* <div
                onClick={() =>
                  handleInputChange(!allergyEntry.headerShow, "headerShow")
                }
				style={{cursor: "pointer"}}
              >
                <img src={Addicon} alt="Add" />
              </div> */}
            </div>
          )}
          <div style={{ display: "flex" }}>
            {allergyEntry.name?.value &&
            allergyEntry.type.value &&
            allergyEntry.severity.label &&
            allergyEntry.status_verification.label &&
			allergyEntry?.headerShow ? (
              <div
                id={`${parent_id}_allergy_add_button_div`}
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginRight: "10px"
                }}
              >
                <Button
                  id={`${parent_id}_allergy_add_button`}
                  onClick={() => AddAllergy()}
                  variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "30%",
                    padding: "5px",
                    marginRight: "10px"
                  }}
                  size={"small"}
                >
                  <img src={Tick} alt="Add" />
                </Button>
				<Button
                  id={`${parent_id}_allergy_add_button`}
                  onClick={() => handleClose()}
                 variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "30%",
                    padding: "5px",
                  }}
                  size={"small"}
                >
				<img src={Cancel} alt="Cancel" />
				</Button>
              </div>
            ) : (
              <>
              <div
                id={`${parent_id}_allergy_No_Known_Drug_div`}
                style={{ width: "100%", display: "flex" }}
              >
                {/* {!allergyEntry?.noAccess && ( */}
                <Tooltip
                  id={`${parent_id}_allergy_No_Known_Drug_title_tooltip`}
                  arrow
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  title={"No Known Drug Allergy"}
                >
                  <div id={`${parent_id}_allergy_drugAllergy_pills_div`}>
                    <IconButton
                      id={`${parent_id}_allergy_drugAllergy_pillsicons_button`}
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() =>
                        handleInputChange(
                          !allergyEntry.drugAllergy,
                          "drugAllergy"
                        )
                      }
                      disabled={
                        allergyEntry.noAccess ||
                        allergyEntry.knownAllergy ||
                        allergiesData.encounter_Based_A.data.length > 0
                          ? true
                          : false
                      }
                    >
                      <PillsIcon
                        id={`${parent_id}_allergy_drugAllergy_pillsicons`}
                        width={15}
                        color={allergyEntry.drugAllergy ? UIColor.primaryColor.color : null}
                        style={{
                          opacity:
                            allergyEntry.noAccess ||
                            allergyEntry.knownAllergy ||
                            allergiesData.encounter_Based_A.data.length > 0
                              ? "0.2"
                              : null,
                          marginRight: 10,
                          ...styles.icons,
                        }}
                      />
                    </IconButton>
                  </div>
                </Tooltip>
                {/* )} */}
                <Tooltip
                  id={`${parent_id}_allergy_No_Known_title_tooltip`}
                  title={"No Known Allergy"}
                  arrow
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    id={`${parent_id}_allergy_No_Known_NoAllergyIcon_button`}
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() =>
                      handleInputChange(
                        !allergyEntry.knownAllergy,
                        "knownAllergy"
                      )
                    }
                    disabled={
                      allergyEntry.drugAllergy ||
                      allergyEntry.noAccess ||
                      allergiesData.encounter_Based_A.data.length > 0
                        ? true
                        : false
                    }
                  >
                    <div>
                      <NoAllergyIcon
                        id={`${parent_id}_allergy_No_Known_NoAllergyIcon`}
                        width={15}
                        color={allergyEntry.knownAllergy ? UIColor.primaryColor.color : null}
                        style={{
                          opacity:
                            allergyEntry.noAccess ||
                            allergiesData.encounter_Based_A.data.length > 0
                              ? "0.2"
                              : null,
                          marginRight: 10,
                          ...styles.icons,
                        }}
                      />
                    </div>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  id={`${parent_id}_allergy_Unable_to_access_title_tooltip`}
                  title={"Unable to access"}
                  arrow
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    id={`${parent_id}_allergy_No_Known_NoAccessIcon_button`}
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() =>
                      handleInputChange(!allergyEntry.noAccess, "noAccess")
                    }
                    disabled={
                      allergyEntry.drugAllergy ||
                      allergyEntry.knownAllergy ||
                      allergiesData.encounter_Based_A.data.length > 0
                        ? true
                        : false
                    }
                  >
                    <div>
                      <NoAccessIcon
                        id={`${parent_id}_allergy_Unable_to_access_NoAccessIcon`}
                        width={15}
                        color={allergyEntry.noAccess ? UIColor.primaryColor.color : null}
                        style={{
                          opacity:
                            allergyEntry.knownAllergy ||
                            allergiesData.encounter_Based_A.data.length > 0
                              ? "0.2"
                              : null,
                          ...styles.icons,
                        }}
                      />
                    </div>
                  </IconButton>
                </Tooltip>
              </div>
              
              </>
            )}
            {!allergyEntry.headerShow && <div
                onClick={() =>
                  handleInputChange(!allergyEntry.headerShow, "headerShow")
                }
				style={{cursor: "pointer", paddingLeft: 8}}
              >
                <img src={Addicon} alt="Add" />
              </div>
}
            {/* <Tooltip
              id={`${parent_id}_allergy_Insights_title_tooltip`}
              title={"Insights"}
            >
              <div style={{ ...styles.infoBell, ...styles.icons }}>
                <InsightsIcon
                  id={`${parent_id}_allergy_Insights_Icon`}
                  style={{ marginLeft: 5 }}
                  // color={UIColor.primaryColor.color}
                />
              </div>
            </Tooltip> */}
          </div>
        </Col>

        {/* drugAllergy */}
        {allergyList.drugAllergy || allergyEntry.knownAllergy || allergyEntry.noAccess ? (
          <Col
            id={`${parent_id}_allergy_Drug_Allergy_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "14px", borderTop: `1px solid ${UIColor.lineBorderFill.color}` }}
          >
            <Row id={`${parent_id}_allergy_Drug_Allergy_row`}>
              <Col
                id={`${parent_id}_allergy_Drug_Allergy_text_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={{display: "flex", alignItems: "flex-start"}}
              >
              {allergyList.drugAllergy && 
                <Text
                  id={`${parent_id}_allergy_Drug_Allergy_text`}
                  inLineStyles={{ ...styles.noAccessTag, ...styles.statusTag }}
                >
                  <PillsIcon
                    id={`${parent_id}_allergy_Drug_Allergy_pillsicon`}
                    width={12}
                    color={"#000"}
                    style={{ marginRight: 5 }}
                  />{" "}
                  {"No Known Drug Allergy"}
                </Text>
              }
              {allergyEntry.knownAllergy || allergyEntry.noAccess ?
                <Text
                    id={`${parent_id}_allergy_entry_text`}
                    inLineStyles={
                      allergyEntry.knownAllergy
                        ? styles.knownAllergyedit
                        : styles.noAccessTag
                    }
                  >
                    {allergyEntry.knownAllergy ? (
                      <NoAllergyIcon
                        id={`${parent_id}_allergy_NoAllergy_icon`}
                        width={12}
                        color={"#000"}
                        style={{ marginRight: 5 }}
                      />
                    ) : (
                      <NoAccessIcon
                        id={`${parent_id}_allergy_NoAccess_icon`}
                        width={12}
                        color={UIColor.error.color}
                        style={{ marginRight: 5 }}
                      />
                    )}

                    {allergyEntry.knownAllergy
                      ? "No known allergy"
                      : "Unable to access"}
                  </Text>
                  : null }
              </Col>
            </Row>
          </Col>
        ) :  null}

        {/* allergy list */}
        {allergiesData.encounter_Based_A.data.length > 0 &&
          allergiesData.encounter_Based_A.data.map((val, i) => {
            // if (!val.isNew) {
            // 	return <React.Fragment />;
            // }
            return (
              <Col
                id={`${parent_id}_allergy_${i}_ListHover_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={"allergyListHover"}
                inLineStyles={{
                  padding: "8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                }}
              >
                <Row id={`${parent_id}_allergy_${i}_ListHover_row`}>
                  <Col
                    id={`${parent_id}_allergy_${i}_ListHover_sub_col`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    {(val.allergenCode.label || val.allergenCode?.allergenCode) && (
                      <H6
                        id={`${parent_id}_allergy_${i}_${val.allergenCode.label}_h6`}
                        inLineStyles={{ fontSize: 14, paddingBottom: 6, color: UIColor.secondaryButtonColor.color, fontFamily:"pc_regular"  }}
                      >
                        {val.allergenCode?.label ? val.allergenCode?.label : val.allergenCode}
                      </H6>
                    )}
                    <div
                      id={`${parent_id}_allergy_${i}_severity_div`}
                      style={{ display: "flex" }}
                    >
                      {val.severity && (
                        <Text
                          id={`${parent_id}_allergy_${i}_${val.severity}_text`}
                          inLineStyles={styles.textColor}
                          style={{ display: "flex", padding: 0 }}
                          className={`${val.severity}`}
                        >
                          <InfoIcon
                            id={`${parent_id}_allergy_${i}_infoicon`}
                            width={12}
                            style={{ marginRight: 6 }}
                            color={getColor[val.severity]}
                          />
                          {val.severity}
                        </Text>
                      )}
                      {val.type && (
                        <Text
                          id={`${parent_id}_allergy_${i}_${val.type}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {" - "}
                          {val.type}
                          {" - "}
                        </Text>
                      )}
                      {val.reactions.length > 0 && (
                        <>
                          <Text
                            inLineStyles={styles.textColor}
                            className={`${val.severity}`}
                          >
                            {" "}
                            {val.severity === "Severe" ? "(S)" : "(M)"}
                          </Text>
                          <Text
                            id={`${parent_id}_allergy_${i}_reactions_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" "}
                            {val.reactions.map((re, i) => {
                              return (
                                <>
                                  <span
                                    id={`${parent_id}_allergy_${i}_reactions_span`}
                                  >
                                    {re.description}
                                    {i + 1 > val.reactions.length &&
                                    i + 1 !== val.reactions.length
                                      ? ", "
                                      : " "}
                                  </span>
                                </>
                              );
                            })}
                          </Text>
                        </>
                      )}
                      {val.verify && (
                        <Text
                          id={`${parent_id}_allergy_${i}_${val.verify}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {val.reactions.length > 0 && " - "}
                          {val.verify}
                        </Text>
                      )}
                      {val.currentStatus && (
                        <Text
                          id={`${parent_id}_allergy_${i}_${val.currentStatus}_text`}
                          inLineStyles={styles.statusTag}
                        >
                          {val.currentStatus}
                        </Text>
                      )}
                    </div>
                  </Col>
                  <Col
                    id={`${parent_id}_allergy_${i}_userInfoEdit_col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Div
                      id={`${parent_id}_allergy_${i}_userInfoEdit_div`}
                      className={"userInfoEdit"}
                    >
                      <div
                        id={`${parent_id}_allergy_${i}_On_div`}
                        style={styles.userInfo} className={"userHide"}
                      >
                        <Text
                          id={`${parent_id}_allergy_${i}_On_text`}
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {/* {moment.unix(val?.createddate).format("DD MMM, YYYY")} */}
                          {utcTOLocal(val?.createddate, "DD MMM, YYYY")}
                        </Text>
                        <Text
                          id={`${parent_id}_allergy_${i}_${
                            // val?.practitioner_id?.[0]?.name?.prefix +
                            "_"
                            // +
                            // val?.practitioner_id?.[0]?.name?.text.replaceAll(
                            // 	" ",
                            // 	"_"
                            // )
                          }_div`}
                          inLineStyles={styles.textColor}
                        >
                          {makeName(
                            val?.practitioner_id?.[0]?.name?.[0] || {}
                          ) + " (You)"}
                          {/* {info.name + " " + "(You)"} */}
                        </Text>
                      </div>
                      <div
                        id={`${parent_id}_allergy_${i}_delete_edit_div`}
                        style={{ ...styles.userInfoIcon, marginLeft: 24, display: "flex" }}
                      >
                        <Text
                          id={`${parent_id}_allergy_${i}_trash_div`}
                          inLineStyles={styles.deleteAllergyIcon}
                          onClick={() => {
                            dialog.setDialog({
                              ...dialog,
                              open: true,
                              onOk: () => deleteList(i, val),
                            });
                          }}
                        >
                          {/* <Icons
                            id={`${parent_id}_allergy_${i}_trash_Icons`}
                            fontIcon={"trash-o"}
                          /> */}
                          <Image
                              src={'images/icons8-trash_.svg'}
                              alt="delete_icon"
                              //style={{ width: 0, height: 0 }}
                            />

                        </Text>
                        <Text
                          id={`${parent_id}_allergy_${i}_edit_text`}
                          inLineStyles={styles.editAllergyIcon}
                          onClick={() => onEditAllergy(i, val)}
                        >
                          {/* <Icons
                            id={`${parent_id}_allergy_${i}_edit_Icons`}
                            fontIcon={"pencil"}
                          /> */}
                          <Image
                              src={'images/icons8-edit_.svg'}
                              alt="delete_icon"
                              //style={{ width: 0, height: 0 }}
                            />

                        </Text>
                      </div>
                    </Div>
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
      {/* snackbar */}
      <Snackbar
        id={`${parent_id}_allergy_snackbar`}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleSnackClose}
        message="1 New Allergy added"
        action={
          <React.Fragment>
            <IconButton
              id={`${parent_id}_allergy_CloseIcon_button`}
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackClose}
            >
              <CloseIcon
                id={`${parent_id}_allergy_CloseIcon`}
                fontSize="small"
              />
            </IconButton>
          </React.Fragment>
        }
      />

      {/*delete dialog */}
      <Dialog
        id={`${parent_id}_allergy_dialog`}
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="simple-dialog-title"
        classes={{ container: classes.container }}
      >
        <DialogContent
          id={`${parent_id}_allergy_remove_this?_dialogcontent`}
          style={styles.dialogContent}
        >
          <Typography
            id={`${parent_id}_allergy_remove_this?_typography`}
            style={styles.deleteHeader}
          >
            Are you sure you want to remove this?
          </Typography>
          <Typography
            id={`${parent_id}_allergy_the_order_typography`}
            style={{ fontSize: "14px", lineHeight: 1.2 }}
          >
            Some content about<br></br> the order
          </Typography>
        </DialogContent>
        <div
          id={`${parent_id}_allergy_Remove_button_div`}
          style={{ padding: "0px 24px" }}
        >
          <Button
            id={`${parent_id}_allergy_yes_Remove_button`}
            onClick={handleDeleteClose}
            style={styles.deleteButton}
            fullWidth
            variant="contained"
          >
            Yes Remove
          </Button>
        </div>
        <div
          id={`${parent_id}_allergy_No_button_div`}
          style={{ padding: "8px 24px 24px" }}
        >
          <Button
            id={`${parent_id}_allergy_No_button`}
            onClick={handleDeleteClose}
            variant="outlined"
            style={styles.deleteButton2}
            fullWidth
          >
            No
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default withAllContexts(Allergy);
