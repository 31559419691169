import React, { useState, useRef } from "react";
import { Image, TextInput } from "qdm-component-library";
import {
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Dialog,
  Grid,
  Popover,
} from "@material-ui/core";
import clsx from "clsx";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@material-ui/icons/Search";
import { withAllContexts } from "../../../HOCs";
import { orderListNav, searchList } from "./constructJson";
import OrderListsSide from "./orderListsSide";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { withRouter } from "react-router-dom";
import { makeName } from "../../../utils/helperFunctions";
import { AlertProps, UIColor } from "../../../utils";
import moment from "moment";
import { TickSelectde } from "../../../components";
import { generateJson } from "../medicationFlows/generateJson";

const useStyles = makeStyles({
  rootHeader: {
    color: UIColor.primaryText.color,
    fontFamily: "pc_semibold",
    fontSize: "14px",
    marginBottom: "8px",
  },
  paper: {
    width: "1000px",
  },
  imageDiv: {
    // backgroundColor: UIColor.secondaryButtonColor.color,
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  orderSetList: {
    fontFamily: "pc_regular",
    fontSize: "12px",
    padding: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `${UIColor.differentiationBackground.color} !important`,
      color: `${UIColor.primaryText.color} !important`,
    },
  },
  lowerHeader: {
    color: UIColor.primaryText.color,
    fontFamily: "pc_semibold",
    fontSize: "12px",
    paddingTop: "16px",
  },
  topCard: {
    backgroundColor: UIColor.greyBackground.color,
    borderRadius: "8px",
    margin: "4px 0px 0px 0px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeader: {
    fontFamily: "pc_regular",
    fontSize: "12px",
    color: UIColor.secondaryText.color,
  },
  searchIcon: {
    position: "absolute",
    top: 5,
    left: 10,
    color: UIColor.secondaryText.color,
    width: 15,
  },
  icon: {
    borderRadius: 10,
    width: 17,
    height: 17,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: UIColor.greyBackground.color,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${UIColor.lineBorderFill.color}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: UIColor.differentiationBackground.color,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: UIColor.primaryColor.color,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    boxShadow: "none",
    "&:before": {
      display: "block",
      width: 17,
      height: 17,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: UIColor.primaryColor.color,
    },
  },
  buttonEdit: {
    marginLeft: "8px",
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "8px",
    backgroundColor: UIColor.primaryColor.color,
    fontFamily: "pc_medium !important",
    color: "#fff",
    padding: "4px 12px",
    border: "none",
    "&:hover": {
      backgroundColor: UIColor.secondaryButtonColor.color,
    },
  },
});

const funcList = [
  "DRUGTYPE_MASTER",
  // "DRUGMASTER_MASTER",
  "STRENGTH_MASTER",
  "STRENGTHUOM_MASTER",
  "STRENGTHVOLUMN_MASTER",
  "STRENGTHVOLUMNUOM_MASTER",
  "DOSAGETYPEINDICATOR_MASTER",
  "DOSAGEUOM_MASTER",
  "DOSAGEVOLUMEUOM_MASTER",
  "DRUGROUTES_MASTER",
  "DOSAGEFORM_MASTER",
  "FREQUENCYMASTER_MASTER",
  "DURATIONTYPE_MASTER",
  "PRIORITY_MASTER",
  "DRUGCATEGORY_MASTER",
  "ORDERNATURE_MASTER",
  "STATUS_MASTER",
  "ORDERMODE_MASTER",
  "DRUGSTOREDISPLOC_MASTER",
  "DIAGNOSISAGAINSTENCOUNTER_MASTER",
  "REASONCODE_MASTER",
  "ORGANIZATION_MASTER",
  "PRACTITIONERROLE_MASTER",
  "PRACTITIONER_MASTER",
  "INFUSIONRATEUOM_MASTER",
  "TOTALVOLUMEUOM_MASTER",
  "INDICATION_REASON_CODE_MASTER",
  "BASESCHEDULE_MASTER",
  "PATIENT_INSTRUCTIONS_MASTER",
];

const OrderSets = (props) => {
  const classes = useStyles();
  const personDetails = useSelector(
    (state) => state?.personApiSlice?.searchPractioner
  );
  const encounterData = useSelector(
    (state) => state?.chiefCompilenceMastersSlice?.encounter_Details
  );

  const storeData = useSelector((state) => state?.DiagnosisMasterSlice);
  const stateList = useSelector((state) => state?.MedicationMastersSlice);
  let status = stateList?.status_masters?.data.filter(
    (val) => val.label === "Draft"
  )[0];

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState({
    anchorEl: null,
    id: null,
  });
  const [filter, setFilter] = React.useState({
    Speciality: null,
    Location: null,
    Diagnosis: null,
    Practitioner: null,
  });
  const [queryCritria, setQueryCriteria] = React.useState(searchList);
  const [orders, setOrders] = React.useState({
    orderSets: [],
    orderList: {},
  });
  const [orderListSearch, setOrderListSearch] = useState("");

  //orderList Search
  const searchListCall = async (val, change) => {
    const Search = await dispatch(
      actions.ORDER_SET_SEARCH({ list: val?._key, change: change })
    );
  };
  const handleOrderListSearch = (e) => {
    setOrderListSearch(e.target.value);
    // searchListCall(selectedOrderSet,e.target.value)
  };

  // check data function
  const handleCheckChange = (e, value, i, index) => {
    orders.orderList[selectedOrderSet?.value][index].order[i].checked =
      !orders.orderList?.[selectedOrderSet?.value][index].order[i].checked;
    setOrders({ ...orders });
  };

  // onLoad dropDown Load
  const dispatch = useDispatch();
  const queryCall = async () => {
    const dataArray = [
      dispatch(actions.SPECIALTY_DROPDOWN()),
      dispatch(actions.LOCATION_DROPDOWN()),
      dispatch(actions.PRACTITIONER_DROPDOWN()),
      dispatch(actions.STATUS_MASTER()),
    ];
    const [specialityData, locationData, practitionerData] = await Promise.all(
      dataArray
    );
    queryCritria.forEach((data) => {
      if (data.header === "Speciality") {
        data.types = specialityData?.payload?.data;
      } else if (data.header === "Location") {
        data.types = locationData?.payload?.data;
      } else if (data.header === "Practitioner") {
        data.types = practitionerData?.payload?.data;
      } else if (data.header === "Diagnosis") {
        data.types = storeData?.Diagnosis_read?.data.map(
          (itm, i) => itm.diagnosis
        );
      }
    });
    setQueryCriteria([...queryCritria]);
  };

  const orderSetCall = async () => {
    if (
      filter?.Speciality?.value &&
      filter?.Location?.value &&
      filter?.Diagnosis?.value &&
      filter?.Practitioner?.value
    ) {
      let list = {
        speciality: filter?.Speciality?.value,
        location: filter?.Location?.value,
        diag: filter?.Diagnosis?.value,
        pract: filter?.Practitioner?.value,
      };
      const orderSetFun = await dispatch(
        actions.ORDER_SET_READ({ list: list })
      );
      setOrders({
        ...orders,
        orderSets: orderSetFun?.payload?.data?.orderSet,
        orderList: JSON.parse(
          JSON.stringify(orderSetFun?.payload?.data?.orderList)
        ),
      });
    }
  };

  React.useEffect(() => {
    queryCall();
  }, []);

  React.useEffect(() => {
    orderSetCall();
  }, [filter]);

  // React.useEffect(()=>{
  //   searchListCall(selectedOrderSet);
  // },[orderListSearch])

  React.useEffect(() => {
    let isSelectedAll = true;
    let selectedCount = 0;
    if (orders.orderList[selectedOrderSet.value]) {
      orders.orderList[selectedOrderSet.value].forEach((v) => {
        v.order.forEach((o) => {
          if (o.checked) {
            selectedCount = selectedCount + 1;
          } else {
            isSelectedAll = false;
          }
        });
      });
      if (isSelectedAll) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      setSelectedCount(selectedCount);
    }
  }, [orders]);

  //  Dialog open
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Popper Filter
  const handleClick = (e, val) => {
    setAnchorEl({ anchorEl: e.target, id: val.Type });
  };

  const handlePopClose = () => {
    setAnchorEl({ anchorEl: null, id: null });
  };

  // Filter Select & diagnosis search
  const handleFilterSelect = (val, value) => {
    setFilter({ ...filter, [val.header]: value });
    setAnchorEl({ anchorEl: null, id: null });
  };
  const handleDiagnosisFilter = async (e, val) => {
    let savedDiag = storeData?.Diagnosis_read?.data.map(
      (itm, i) => itm.diagnosis
    );
    if (e?.target?.value?.length > 3) {
      const diagnosisData = await dispatch(
        actions.DIAGNOSIS_DROPDOWN({ type: e.target.value })
      );
      queryCritria.forEach((data) => {
        if (data.header === "Diagnosis") {
          data.types = [...savedDiag, ...diagnosisData?.payload?.data];
        }
      });
      setQueryCriteria([...queryCritria]);
    }
  };

  // Selected
  const [selectedState, setSelectedState] = React.useState(false);
  const [selectedCounts, setSelectedCount] = useState(0);
  const handleSelectedList = () => {
    setSelectedState(!selectedState);
  };

  //selected orderSet
  const [selectedOrderSet, setSelectedOrderset] = React.useState({});

  const handleOrderSetSelect = async (val, i) => {
    if (orders.orderList[selectedOrderSet.value]) {
      orders.orderList[selectedOrderSet.value].forEach((val) => {
        val.order.forEach((o) => {
          o.checked = false;
        });
      });
      setOrders({ ...orders });
    }

    setOrderListSearch("");
    setSelectedOrderset(val);
    let change = "";
    // searchListCall(val,change)
  };

  const handleSelectAll = (e) => {
    orders.orderList[selectedOrderSet.value].forEach((val) => {
      val.order.forEach((o) => {
        o.checked = e.target.checked;
      });
    });
    setOrders({ ...orders });
  };

  const getMasterData = async () => {
    return new Promise(async (resolve, reject) => {
      let calls = funcList.map(async (val) => {
        return await dispatch(
          actions?.[val]({
            orgId: personDetails?.data[0]?.OrgID[0],
            EId: encounterData?.data?.eId,
          })
        );
      });
      Promise.all(calls).then(() => {
        resolve(true);
      });
    });
  };

  const [btnEnable, setBtnDisable] = useState(false);
  // final Save
  const handleSave = async () => {
    let newObj = [];
    setBtnDisable(true);
    orders.orderList[selectedOrderSet.value].forEach((val) => {
      const selectedOrder = val.order.filter((o) => o.checked);
      if (selectedOrder.length > 0) {
        newObj.push({ header: val.header, order: selectedOrder });
      }
    });
    const additionalData = {
      org_id: personDetails?.data[0]?.org_id,
      accessionNum: makeName(personDetails?.data[0]?.name?.[0]),
      encounterId: encounterData?.data?.eId,
      encounterType: encounterData?.data?.type,
      patientId: encounterData?.data?.pID,
      PersonID: personDetails?.data[0]?.name?.[0]?.given
        ? personDetails?.data[0]?.name?.[0]?.given +
          " " +
          personDetails?.data[0]?.name?.[0]?.family +
          "/" +
          personDetails?.data[0]?.name?.[0]?.use
        : personDetails.data[0]?.telecom.value +
          "/" +
          personDetails?.data[0]?.name?.[0]?.use,
      new_status: "Draft",
      old_status: "Draft",
      fromperiod: moment().unix(),
      toperiod: moment().unix(),
      status: stateList?.status_masters?.data.filter(
        (val) => val.label === "Draft"
      )[0],
    };
    const upsertSave = await dispatch(
      actions.ORDER_SET_SAVE({ list: newObj, info: additionalData })
    );
    if (upsertSave?.payload?.error === false && !upsertSave?.payload?.data?.error) {
      let datatreatment = await dispatch(
        actions.TRAEMENT_READ({
          encounter_id: encounterData?.data?.eId,
        })
      );
      let labordersData = await dispatch(
        actions.LABORDER_READ({
          encounter_id: encounterData?.data?.eId,
        })
      );
      let datatradiology = await dispatch(
        actions.RAD_READ({
          encounter_id: encounterData?.data?.eId,
        })
      );
      await getMasterData();
      const { pId } = props?.location?.state;
      let medicationData = await dispatch(
        actions.GET_MEDICATIONS({
          id: pId,
        })
      );
      let medData = generateJson(medicationData?.payload?.data, stateList);
      const { setMedication } = props.medicationsData;
      setMedication({
        ...props.medicationsData,
        list: medData,
      });
      const { setRadiology } = props.radiology;
      setRadiology({
        ...props.radiology,
        list: datatradiology?.payload?.data,
      });
      const { setLabOrder } = props.LabOrder;
      setLabOrder({
        ...props.LabOrder,
        list: labordersData?.payload?.data,
      });
      const { setTreatment } = props.treatment;
      setTreatment({
        ...props.treatment,
        list: datatreatment?.payload?.data,
      });
      props.handlingClose();
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Orders added successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleErrorInsert = () => {
    props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.error,
      msg: "Please select the orders",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
      tone: true,
    });
  };
  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="lg"
        classes={{ paper: classes.paper }}
      >
        <div style={{ padding: "15px", borderBottom: `1px solid ${UIColor.lineBorderFill.color}` }}>
          <Typography className={classes.rootHeader}>
            Order Set Placing
          </Typography>
          <Typography className={classes.cardHeader}>
            Search Criteria
          </Typography>

          <Grid container spacing={1}>
            {searchList.map((val, i) => (
              <Grid item xs={3}>
                <div className={classes.topCard}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.imageDiv}>
                      <Image
                        src={val.image}
                        alt=""
                        style={{ width: 15, height: 15 }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <Typography
                        className={classes.cardHeader}
                        style={{ color: UIColor.secondaryText.color }}
                      >
                        {val.header}
                      </Typography>
                      <Typography
                        style={{ fontFamily: "pc_medium", fontSize: "14px" }}
                      >
                        {filter?.[val.header]?.label ?? val.Type}
                      </Typography>
                    </div>
                  </div>
                  <div
                    onClick={(e) => handleClick(e, val)}
                    style={{ cursor: "pointer" }}
                  >
                    <ExpandMoreIcon />
                  </div>
                </div>
                <Popover
                  open={val.Type === anchorEl.id}
                  anchorEl={anchorEl.anchorEl}
                  onClose={handlePopClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{ height: "300px", width: "250px", padding: "10px" }}
                  >
                    <div
                      style={{
                        display: val.header === "Diagnosis" ? "flex" : "none",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <TextInput
                        id="sidemenu_search_input_textinput"
                        value={filter?.diagnosisSearch}
                        style={{
                          padding: "8px 0px 8px 35px",
                          borderRadius: 8,
                          borderColor: UIColor.lineBorderFill.color,
                          backgroundColor: UIColor.greyBackground.color,
                          height: "30px",
                          fontFamily: "pc_medium",
                        }}
                        variant={"outlined"}
                        label=""
                        placeholder="Search"
                        hoverColor={UIColor.lineBorderFill.color}
                        onChange={(e) => handleDiagnosisFilter(e, val)}
                      />
                      <SearchIcon className={classes.searchIcon} />
                    </div>
                    {val?.types?.map((value, i) => (
                      <div>
                        <Typography
                          className={classes.orderSetList}
                          onClick={(e) => handleFilterSelect(val, value)}
                        >
                          {/* {val?.header === "Diagnosis" &&
                          storeData?.Diagnosis_read?.data?.length > 0
                            ? storeData?.Diagnosis_read?.data.map(
                                (itm, i) => itm.diagnosis.label
                              )
                            : null} */}
                          {value.label}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </Popover>
              </Grid>
            ))}
          </Grid>
        </div>

        {/* Left Nav */}
        <Grid container style={{ paddingLeft: "15px" }}>
          <Grid
            item
            xs={3}
            style={{ borderRight: `1px solid ${UIColor.lineBorderFill.color}`, maxWidth: "21%" }}
          >
            <Typography className={classes.lowerHeader}>Order Sets</Typography>
            <div style={{ height: "calc(60vh - 20px)", overflow: "auto" }}>
              {orders?.orderSets?.map((val, i) => (
                <div
                  className={classes.orderSetList}
                  style={{
                    color: selectedOrderSet === val ? UIColor.primaryColor.color : "#000",
                    backgroundColor:
                      selectedOrderSet === val ? UIColor.differentiationBackground.color : "#fff",
                  }}
                  onClick={() => handleOrderSetSelect(val, i)}
                >
                  <Typography
                    style={{ fontFamily: "pc_regular", fontSize: "12px" }}
                  >
                    {val?.code}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>

          {/* Right List */}
          <Grid item xs={9} style={{ maxWidth: "79%", flexBasis: "79%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 10px 0px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography
                  className={classes.lowerHeader}
                  style={{ paddingTop: "6px" }}
                >
                  Order List
                </Typography>
                <div
                  style={{
                    backgroundColor: selectedState ? UIColor.primaryColor.color : UIColor.lineBorderFill.color,
                    borderRadius: "8px",
                    marginLeft: "12px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                  onClick={handleSelectedList}
                >
                  <TickSelectde color={selectedState ? "#fff" : UIColor.secondaryText.color} />
                  <Typography
                    className={classes.lowerHeader}
                    style={{
                      padding: "6px",
                      color: selectedState ? "#fff" : UIColor.tertiaryText.color,
                      fontFamily: "pc_medium",
                      // backgroundColor: selectedState ? UIColor.primaryColor.color : ""
                    }}
                  >
                    {"Selected Orders" + " "}
                    <span
                      style={{
                        color: selectedState ? "#fff" : UIColor.primaryColor.color,
                        fontFamily: "pc_medium",
                      }}
                    >
                      ({selectedCounts})
                    </span>
                  </Typography>
                </div>
              </div>
              <div
                style={{ display: "flex", position: "relative", width: "40%" }}
              >
                <TextInput
                  id="sidemenu_search_input_textinput"
                  value={""}
                  style={{
                    padding: "8px 0px 8px 35px",
                    borderRadius: 8,
                    borderColor: UIColor.lineBorderFill.color,
                    backgroundColor: UIColor.greyBackground.color,
                    height: "30px",
                    fontFamily: "pc_medium",
                  }}
                  variant={"outlined"}
                  label=""
                  placeholder="Search"
                  hoverColor={UIColor.lineBorderFill.color}
                  onChange={(e) => handleOrderListSearch(e)}
                  value={orderListSearch}
                />
                <SearchIcon className={classes.searchIcon} />
              </div>
            </div>

            <div style={{ height: "300px", overflow: "auto" }}>
              <OrderListsSide
                initalData={orders?.orderList?.[selectedOrderSet?.value] ?? []}
                handleCheckChange={handleCheckChange}
                selectedState={selectedState}
                orderListSearch={orderListSearch}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
              }}
            >
              <>
                {orders?.orderSets?.length > 0 && selectedOrderSet?.label ? (
                  <FormControlLabel
                    onChange={handleSelectAll}
                    control={
                      <Checkbox
                        className={classes.root}
                        checked={selectAll}
                        disableRipple
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                      />
                    }
                    label={
                      <Typography
                        style={{ fontFamily: "pc_regular", fontSize: "14px" }}
                      >
                        Select All
                      </Typography>
                    }
                  />
                ) : (
                  <div></div>
                )}
                <div>
                  <Button
                    style={{
                      color: UIColor.primaryText.color,
                      backgroundColor: "#fff",
                      border: `1px solid ${UIColor.lineBorderFill.color}`,
                    }}
                    className={classes.buttonEdit}
                    onClick={() => props.handlingClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.buttonEdit}
                    onClick={
                      selectedCounts > 0 ? handleSave : handleErrorInsert
                    }
                    disabled={btnEnable}
                  >
                    {btnEnable ? "Inserting Orders" : " Insert Order"}
                  </Button>
                </div>
              </>
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default withAllContexts(withRouter(OrderSets));
