import React, { useState } from "react";
import {
  Div,
  Card,
  ListItem,
  Avatar,
  Icons,
  Divider,
  Text,
  H6,
  Image,
  Button
} from "qdm-component-library";
import { useSelector } from "react-redux";
import { getImgUrl, UIColor } from "../../../utils";
import LoopIcon from '@material-ui/icons/Loop';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Tooltip, IconButton } from '@material-ui/core';

const styles = {
  rowFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column'
  },
  h6: {
    fontSize: 14,
    color: '#101010',
    margin: 0
  },
  span: {
    fontSize: 12,
    color: '#6F6F6F',
    margin: 0
  },
  cardStyle: {
    boxShadow: '0px 10px 25px #0000000A',
    borderRadius: 8,
    padding: 10
  },
  avatar: {
    margin: 0,
    marginRight: 15,
    width: 48,
    height: 48,
    borderRadius: 8,
    backgroundColor: UIColor.differentiationBackground.color
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 10
  },
  status: {
    borderRadius: 4,
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 7,
    fontFamily: 'pc_regular',
    fontSize: 12,
    color: UIColor.success.color,
    backgroundColor: "#3bb21329"
  },
  status1: {
    borderRadius: 4,
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 7,
    fontFamily: 'pc_regular',
    fontSize: 12,
    border: "1px solid #F58B00",
    color: "#F58B00"
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: UIColor.success.color,
    borderRadius: '50%',
    marginRight: '5px',
  },
  inactiveDot: {
    width: 6,
    height: 6,
    backgroundColor: "#F58B00",
    borderRadius: '50%',
    margin: '0 5px',
  },
  editIcon: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    padding: "6px 10px"
  }
}

function PatientCard(props) {
  const { loading, data } = useSelector((state) => state?.patientReducer?.PatientDetails);
  const [state, setState] = React.useState({
    callcen: false
  })
  const { rowFlex, columnFlex, h6, span, cardStyle, avatar, img, status, status1, dot, editIcon, inactiveDot } = styles;
  const { name = "# ---", pid = "PID ---", mrn = "ID ---", gender = "gen", imgUrl } = props?.patientDetails || {};
  const {
    addMember = () => null,
    isAddBtn = false,
    memberList = [],
    parent_id,
    permissionData
  } = props;


  React.useEffect(() => { }, [])
  let imgageUrl = null;

  if (imgUrl) {
    imgageUrl = getImgUrl(imgUrl)
  }

  const handleEditForm = () => {
    props.handleEdit(true)
  }
  return (
    <React.Fragment>

      <Card
        id={`${parent_id}_patientcard_parent_card`}
        style={cardStyle}
      >
        <div style={rowFlex}>
          <Div
            id={`${parent_id}_patientcard_parent_div`}
            style={rowFlex}>
            {props?.pic ?
              <Avatar
                id={`${parent_id}_patientcard_avatar_one`}
                src={props?.pic} variant="rounded" inLineStyles={avatar} /> :
              imgageUrl ?
                <Avatar
                  id={`${parent_id}_patientcard_avatar_one`}
                  src={imgageUrl} variant="rounded" inLineStyles={avatar} />
                :
                <Avatar
                  id={`${parent_id}_${name?.[0]}_patientcard_avatar`}
                  variant="rounded" inLineStyles={avatar}>{name?.[0] || props.nameDetails?.[0]}</Avatar>
            }
            <Div
              id={`${parent_id}_patientcard_sub_div`}
              style={columnFlex}>
              <Text
                id={`${parent_id}_patientcard_text`}
                style={rowFlex}>
                <H6
                  id={`${parent_id}_${name}_patientard_name_h6`}
                  className="pc_semibold" style={h6}>{props.nameDetails || name}</H6>
                <Text
                  id={`${parent_id}_patientcard_dot_text`}
                  className="pc_regular" style={{ ...span, margin: '0px 5px' }}>|</Text>
                <Text
                  id={`${parent_id}_patientinfocard_gender_text`}
                  className="pc_regular" style={span}>{props.age + ", " + gender}</Text>
                {(!loading && data.length > 0 && permissionData?.write?.indexOf("patientBannerStatus") > -1) && <Div style={props.activeData ? status1 : (props.active || data?.[0]?.active) ? status : status1}>
                  <div style={props.activeData ? inactiveDot : (props.active || data?.[0]?.active) ? dot : inactiveDot}></div>
                  {props.activeData ? props.activeData : (props.active || data?.[0]?.active) ? "Active" : data?.[0]?.activereason?._key ? "Inactive" : "Deseased"}
                </Div>}
              </Text>
              <Div
                id={`${parent_id}_patientcard_doc_div`}
                style={{ ...rowFlex, marginTop: 7 }}>
                {
                  props.patientDetails?.allData?.Patientname?.length > 0 && (
                    <Div
                      id={`${parent_id}_patientcard_doc_sub_div`}
                      style={{ ...rowFlex, marginRight: 15 }}>
                      <Image
                        id={`${parent_id}_patientcard_identification_doc_image`}
                        style={img} src="images/icons8-identification-documents.svg" alt="id" />
                      <Text
                        id={`${parent_id}_${mrn}_patientcard_text`}
                        className="pc_regular" style={span}>{(props?.mrnVal) ? (props?.mrnVal) : mrn}</Text>
                    </Div>
                  )
                }
                {!props.notOpen && <Div
                  id={`${parent_id}_patientcard_group_div`}
                  style={rowFlex}>
                  <Image
                    id={`${parent_id}_patientcard_group_image`}
                    style={img} src="images/Group 90503.svg" alt="group" />
                  <Text
                    id={`${parent_id}_${pid}_patientcard_text`}
                    className="pc_regular" style={span}>{(props?.pidVal) ? (props?.pidVal) : pid}</Text>
                </Div>}
              </Div>
            </Div>
          </Div>
          <div style={{ flexGrow: 1 }}></div>
          {
            (!props.noedit && props.patientDetails?.allData?.Patientname?.length > 0 && permissionData?.write?.indexOf("patientBannerEditIcon") > -1) ? (
              <div style={editIcon} onClick={handleEditForm}>
                  <EditOutlinedIcon style={{ cursor: 'pointer', color:'#4071c3' }} id={`${parent_id}_image_edit_image`}/>
              </div>
            ) : (<div>
              <Tooltip title="loading">
                <IconButton aria-label="loading">
                  <LoopIcon />
                </IconButton>
              </Tooltip>
            </div>)
          }
        </div>
      </Card>

      {/* {(isAddBtn || memberList.length > 0) && (
        <Divider
          id={`${parent_id}_patientcard_bottom_divider`}
          key={"1"}
          label=""
          borderColor=""
          textColor=""
          orientation="vertical"
          variant="middle"
          style={{
            height: "34px",
            borderColor: "#ffffff",
            backgroundColor: "#ffffff",
            marginRight: 0,
            marginLeft: 10,
          }}
          className={"pc_medium"}
        ></Divider>
      )}
      {(isAddBtn || memberList.length > 0) && (
        <Div
          id={`${parent_id}_patientcard_member_div`}
          inLineStyles={{ display: "flex", alignItems: "center" }}
        >
          {memberList.slice(0, 4).map((val) => {
            return (
              <React.Fragment>
                {val.src ? (
                  <Avatar
                    id={`${parent_id}_patientcard_member_avatar`}
                    variant="rounded"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      color: "#6F6F6F",
                      margin: "0px 1px 0px 9px",
                      fontSize: "16px",
                    }}
                    src={val.src}
                    className={"pc_medium"}
                  />
                ) : (
                  <Avatar
                    id={`${parent_id}_patientcard_member_label_avatar`}
                    variant="rounded"
                    style={{
                      height: "40px",
                      width: "40px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      color: "#6F6F6F",
                      margin: "0px 1px 0px 9px",
                      fontSize: "16px",
                    }}
                    letter={val.label}
                    className={"pc_medium"}
                  />
                )}
              </React.Fragment>
            );
          })}
          {memberList.length - 4 > 0 && (
            <Div
              id={`${parent_id}_patientcard_memberlist_div`}
              variant="rounded"
              style={{
                height: "40px",
                width: "40px",
                cursor: "pointer",
                backgroundColor: "#fff",
                color: "#101010",
                margin: "0px 1px 0px 9px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: 6,
              }}
              className={"pc_medium"}
            >
              +{memberList.length - 4} More
            </Div>
          )}
          {isAddBtn && (
            <Avatar
              id={`${parent_id}_patientcard_memberlist_avatar`}
              variant="rounded"
              style={{
                height: "40px",
                width: "40px",
                cursor: "pointer",
                backgroundColor: "#fff",
                margin: "0px 1px 0px 9px",
                color: "#6F6F6F",
                fontSize: "16px",
              }}
              letter="+"
              onClick={() => addMember(true)}
              className={"pc_medium"}
            />
          )}
        </Div>
      )} */}
    </React.Fragment>
  );
}

export default PatientCard;
