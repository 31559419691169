/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Div, Icons, Image } from "qdm-component-library";
import {
  VitalSigns,
  TemperatureGray,
  PadGray,
  LungsGray,
  OxygenGray,
  WeightKgGray,
  Allergies,
  InfoIcon,
  RiceBowl,
} from "../../components";
import { withAllContexts } from "../../HOCs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { withRouter } from "react-router-dom";

const diagnosisData = [
  {
    id: 1,
    bulletColor: "#38C20A",
    hoverColor: "#CDF0C1",
    status: "Active",
    value: "Benign essential hypertension",
    subData: [
      {
        value: "Rank 3",
        status: "severe",
      },
      {
        value: "POA (yes)",
        showDot: true,
      },
      {
        value: "Confirmed",
        showDot: true,
      },
    ],
  },
];

const getallergyData = (data) => {
  if (data.length === 0) {
    return [];
  }
  let list = data?.map((val, i) => {
    let clicnicalstatushistory = val.statushistory ? val.statushistory : [];
    let old_status =
      clicnicalstatushistory[clicnicalstatushistory.length - 1]?.old_status ??
      "";
    let new_status =
      clicnicalstatushistory[clicnicalstatushistory.length - 1]?.new_status ??
      "";
    return {
      id: i + 1,
      bulletColor: "#F58B00",
      hoverColor: "#FEF3E5",
      value: val?.allergenCode?.label,
      isNew: val.isNew ? true : false,
      isModified: val?.isModified ?? false,
      old_status: old_status,
      new_status: new_status,
      //status: "Active",
      subData: [
        {
          icon: <InfoIcon width={12} />,
          value: val.severity,
          status: val.severity,
        },
        {
          icon: <RiceBowl width={12} />,
          value: val.type,
        },
        {
          value: val.verify,
          showDot: true,
        },
      ],
      ...val,
    };
  });
  console.log("Allergy widget----->", list);
  return list;
};

const getchiefComplaintsData = (data) => {
  let list = data?.map((val, i) => {
    // let clicnicalstatushistory = val.clicnicalstatushistory
    //   ? val.clicnicalstatushistory
    //   : [];
    // let old_status =
    //   clicnicalstatushistory[clicnicalstatushistory.length - 1]?.old_status ??
    //   "";
    // let new_status = clicnicalstatushistory[clicnicalstatushistory.length - 1]?.new_status ?? "";
    return {
      id: i + 1,
      bulletColor: "#F58B00",
      hoverColor: "#FEF3E5",
      value: val?.name?.label,
      isNew: val.isNew ? true : false,
      old_status: val.clinicalStatus.label,
      new_status: val.clinicalStatus.label,
      subData: [
        {
          icon: <Icons fontIcon="info-circle" />,
          value: val.severity?.label,
          status: "moderate",
        },
        {
          showDot: true,
          value: val?.bodySite?.label,
        },
        {
          value: val?.clinicalStatus?.label,
          showDot: true,
        },
      ],
      ...val,
    };
  });

  return list;
};
const getDiagnosisData = (data) => {
  data = Array.isArray(data) ? data : []
  let list = data?.map((val, i) => {
    // let clicnicalstatushistory = val.clicnicalstatushistory
    //   ? val.clicnicalstatushistory
    //   : [];
    // let old_status =
    //   clicnicalstatushistory[clicnicalstatushistory.length - 1]?.old_status ??
    //   "";
    // let new_status =
    //   clicnicalstatushistory[clicnicalstatushistory.length - 1]?.new_status ??
    //   "";
    return {
      id: i + 1,
      bulletColor: "#F58B00",
      hoverColor: "#FEF3E5",
      value: val?.diagnosis?.label,
      isNew: val.isNew ? true : false,
      old_status: val.clinicalStatus.label,
      new_status: val.clinicalStatus.label,
      //status: "Active",
      subData: [
        {
          icon: <Icons fontIcon="info-circle" />,
          value: val.rank?.label,
          status: "moderate",
        },
        {
          showDot: true,
          value: val?.use?.label,
        },
        {
          value: val?.verification?.label,
          showDot: true,
        },
      ],
      ...val,
    };
  });
  return list;
};

const WidgetsList = (props) => {
  const dispatch = useDispatch();
  const { permissions } = props;
  let cheifComplaints = props.cheifComplaints;
  let diagnosis = props.Diagnosis;
  let viatalsContext = props.viatalsContext;
  let treatment = props.treatment;

  let encounterData = useSelector(
    (states) => states.chiefCompilenceMastersSlice.encounter_Details
  );
  let allergys = props?.allergy ?? [];

  React.useEffect(() => {
    // Get Allergy list
    getMasterData();
  }, []);

  const getMasterData = async () => {
    let getEncounterId = props?.location?.state;
    let data = await dispatch(
      actions.PATIENTID_BASED_A({ patientId: getEncounterId?.pId })
    );
    let allergys = props?.allergy;
    allergys.setAllergys({
      ...allergys,
      list: JSON.parse(JSON.stringify(data.payload.data)),
    });

    // Get Allergy master data
    await dispatch(actions.TYPE_MASTERS_A());
    await dispatch(actions.ALLERGY_MASTER());
    await dispatch(actions.SEVERITY_MASTERS_A());
    await dispatch(actions.VERIFICATIONS_MASTERS_A());
    await dispatch(actions.ASSERTBY_MASTER_A());
    await dispatch(actions.RELATIONSHIP_MASTERS_A());
    await dispatch(actions.CLINIC_STATUS_MASTERS_A());
    await dispatch(actions.REACTION_MASTER_A());
    await dispatch(actions.SUBSTANCE_CODE_MASTER_A());
    await dispatch(actions.REACTION_ROUTE_MASTER_A());
  };

  const editWidgetCeifComplaintsList = (data, componentId, index) => {
    let cheifComplaints = props.cheifComplaints;
    cheifComplaints.editIndex = index;
    cheifComplaints.setCheifComplaints({
      ...cheifComplaints,
    });
    props.editWidget(data, componentId, index);
  };
  const editWidgetDiagnosis = (data, componentId, index) => {
    let diagnosis = props.Diagnosis;
    diagnosis.editIndex = index;
    diagnosis.setDiagnosis({
      ...diagnosis,
    });
    props.editWidget(data, componentId);
  };
  const editWidgetAllergyList = (data, componentId, index) => {
    let allergys = props?.allergy;
    allergys.editIndex = index;
    allergys.setAllergys({
      ...allergys,
    });
    props.editWidget(data, componentId);
  };
  const treatmentData = (data) => {
    let list = data?.map((val, i) => {
      console.log("props.patientDatas", val?.dateTime);
      return {
        id: i + 1,
        bulletColor: "#F58B00",
        hoverColor: "#FEF3E5",
        value: val?.treatment_order?.label,
        isNew: val.isNew ? true : false,
        status: "Active",
        subData: [
          {
            icon: <Icons fontIcon="info-circle" />,
            value: val?.priority?.label,
            status: "moderate",
          },
          {
            showDot: true,
            value: val?.nature?.label,
          },
          {
            value: "Active",
            showDot: true,
          },
        ],
        ...val,
      };
    });
    return list;
  };
  const editWidgetTreatment = (data, componentId, index) => {
    treatment.editIndex = index;
    treatment.setTreatment({
      ...treatment,
    });
    props.editWidget(data, componentId);
  };
  const { parent_id } = props
  return (
    <>
      {permissions?.write?.indexOf("vitals") > -1 && <Div id={`${parent_id}_widgets_parent_div`} style={{ marginBottom: 20, color: "#323232" }}>
        <VitalSigns
          parent_id={'widget_vitalsigns'}
          eId={encounterData?.data?.eId}
          title="Vital Signs"
          data={viatalsContext?.list}
        />
      </Div>}
      {/* <Div style={{ marginBottom: 20 }}>
        <Allergies
          editWidget={editWidgetTreatment}
          componentId={"treatment_procedure_form"}
          title="Treatment & Procedures"
          icon={
            <Image
              src="images/diagnosis.svg"
              alt="allergy"
              style={{ width: 15, height: 15 }}
            />
          }
          iconBackground="#E5F1FE"
          data={treatmentData(treatment?.list ?? [])}
        />
      </Div> */}
      {permissions?.write?.indexOf("allergy") > -1 && <Div
        id={`${parent_id}_widgets_Allergies_div`} style={{ marginBottom: 20 }}>
        <Allergies
          parent_id={'widget_Allergies'}
          editWidget={editWidgetAllergyList}
          componentId={"allergy_form"}
          title="Allergies"
          icon={
            <Image
              id={`${parent_id}_widgets_Allergies_image`}
              src="images/allergy_.svg"
              alt="allergy"
              style={{ width: 15, height: 15 }}
            />
          }
           isCC={true}
          iconBackground=""
          data={getallergyData(allergys?.list ?? [])}
        />
      </Div>}
      {permissions?.write?.indexOf("diagnosis") > -1 && <Div style={{ marginBottom: 20 }}>
        <Allergies
          parent_id={'widget_Diagnosis'}
          editWidget={editWidgetDiagnosis}
          componentId={"diagnonsis_form"}
          title="Diagnosis"
          icon={
            <Image
              id={`${parent_id}_widgets_diagnosis_image`}
              src="images/icons8-diagnosis.svg"
              alt="allergy"
              style={{ width: 15, height: 15 }}
            />
          }
          isCC={true}
          iconBackground=""
          data={getDiagnosisData(diagnosis.list)}
        //data={diagnosisData}
        />
      </Div>}
      {permissions?.write?.indexOf("chiefComplaints") > -1 && <Div style={{ marginBottom: 20 }}>
        <Allergies
          parent_id={'widget_Chief_Complaints'}
          editWidget={editWidgetCeifComplaintsList}
          componentId={"cheif_complaints_form"}
          title="Chief Complaints"
          icon={
            <Image
              id={`${parent_id}_widgets_chief_complaints_image`}
              src="images/icons8-chief complaints.svg"
              alt="allergy"
              style={{ width: 15, height: 15 }}
            />
          }
          isCC={true}
          iconBackground=""
          data={getchiefComplaintsData(cheifComplaints.list)}
        // data={state.chiefComplaintsData}
        />
      </Div>}
    </>
  );
};

export default withRouter(withAllContexts(WidgetsList));
