import React from "react";
import { actions } from "primarycare-binder";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EjectIcon from "@material-ui/icons/Eject";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import Eye from "../../assets/img/eye.svg";
import CancelAppointment from "../../assets/img/cancel_appointment.svg";
import closeConsultation from "../../assets/img/closeConsultation.svg";
import Modify from "../../assets/img/modify.svg";
import { Routes } from "../../router/routes";
import { withStyles, Popover } from "@material-ui/core";
import HighAlertPopUp from "../highAlertPopUp";
import iconHigh from "../../assets/img/icons8-high priority red.svg";
import iconHighAlert from "../../assets/img/icons8-high priority white.svg";
import { QueueManagementContext } from "../../contexts";
import { CamIcon, PersonIcon } from "../";
import { Divider, Text, Div, Image, H2 } from "qdm-component-library";
import { removeFromHold, pushToTop, AlertProps, getUtcTime } from "../../utils";
import { withAllContexts } from "../../HOCs";
import moment from "moment";

const materialStyles = (theme) => ({
  // "& .tool":{
  //   display: "none",
  // },
  paper: {
    padding: theme.spacing(2),
    width: "290px",
    height:"250px"
  },
  tooltip: {
    // display: "none",
    height: "auto",
    justifyContent: "center",
    background: "#444",
    borderRadius: "4px",
    color: "#fff",
    margin: "-26px auto 0",
    fontSize: "13px",
    padding: "1px 4px",
    width: "auto",
    position: "absolute",
  },
  main: {
    cursor: "pointer",
    padding: "6px",
    border: "1px solid #eee",
    borderRadius: "50%",
    marginRight: "4px",
    height: "30px",
    width: "30px",
    display: "flex",
    "& .tool": {
      display: "block !important",
    },
  },
  parentDiv: {
    "&:hover": {
      "& .reassign": {
        display: "block",
      },
      "& .highalert": {
        display: "block",
      },
    },
  },
});

class Queue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null,
      modify: null,
      cancel: null,
      anchorEl: null,
    };
    this.removeFromHold = removeFromHold.bind(this);
    this.pushToTop = pushToTop.bind(this);
  }

  handleHighAlertPop = (event) => {
    this.setState({ ...this.state, anchorEl: event.target });
  };

  handleHighAlertPopClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  handlePriority = async (list, _key, app,dropdownValue) => {
    const upsertdata = await this.props.UPSERT_PRIORITIES({ list, _key });
  
    const masterData = [...this.props.queueManagement.data];
    const newData = [...this.props.queueManagement.displayData];
    const newOnHoldData = [...this.props.queueManagement.onHoldData];
    let masterDatavariable = masterData.find((d) => d.token === app[0].id);
    let newDataVariable = newData.find((d) => d.token === app[0].id);
    let newOnHoldDataVariable = newOnHoldData.find(
      (d) => d.token === app[0].id
    );
    if (masterDatavariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      masterDatavariable["patientPriority"] = patientPriority;
    }
    if (newDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newDataVariable["patientPriority"] = patientPriority;
    }
    if (newOnHoldDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newOnHoldDataVariable["patientPriority"] = patientPriority;
    }
    this.props.queueManagement.setData(masterData);
    this.props.queueManagement.setDisplayData(newData);
    this.props.queueManagement.setOnHoldData(newOnHoldData);
    //window.location.href = "/";
    const patientData = await this.props.HIGH_PRIORITY_PATIENT({
      _role: this.props.role_ === "nurse" ? 2 : 1,
      practionerId: this.props?.loggedUserInfo?.data?.practionerId,
      org_id: this.props?.loggedUserInfo?.data?.org_id,
      startDate: getUtcTime(moment().startOf("day")),
      endDate: getUtcTime(moment().endOf("day")),
    });
  };

  componentDidMount() {}

  shouldComponentUpdate(props, state) {
    return true;
  }
  setIsShown = (n, v) => {
    if (!n && !v) {
      this.setState({
        view: null,
        modify: null,
        cancel: null,
      });
      return;
    }
    this.setState({
      [n]: v,
    });
  };
  render() {
    const {
      isHold,
      type,
      showBtn,
      profile_pic,
      name,
      role,
      token,
      isEmergency,
      time,
      id,
      parent_id,
      markHighPriority,
      assignReassignPractitioner,
      totalData,
      nocancelVisit,
      nomodifyVisit,
      noviewVisit,
    } = this.props;
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    console.log("cancelPropsFind", this.props.val);
    const priorityTotalData = {
      key: totalData?.encounter,
      patientPriority: totalData?.patientPriority,
      highPriority:
        totalData?.patientPriority?.priority?.value?.length > 0 ? true : false,
      appointmentId: [totalData?.info],
    };
    return (
      <div id={`${parent_id}_div`}>
        <Div
          id={`${parent_id}_sub_div`}
          className={classes.parentDiv}
          style={{ ...qdmstyles.row, padding: 10 }}
        >
          <Div
            id={`${parent_id}_sublevel_div`}
            style={{ ...qdmstyles.row, flex: 1 }}
          >
            {profile_pic && (
              <Div id={`${parent_id}_avatar_div`} style={qdmstyles.avatar}>
                <Image
                  id={`${parent_id}_avatar_image`}
                  src={profile_pic}
                  alt={name ?? "profilePic"}
                  style={{
                    border: isEmergency ? "1px dashed #FF4D4A" : "0",
                  }}
                />
                {isEmergency && (
                  <Div
                    id={`${parent_id}_emergency_div`}
                    style={qdmstyles.emergencyDiv}
                  >
                    <Text
                      id={`${parent_id}_emergency_text`}
                      className="pc_medium"
                      style={qdmstyles.emergencyText}
                    >
                      EMERGENCY
                    </Text>
                  </Div>
                )}
              </Div>
            )}
            <Div>
              {name && (
                <H2
                  id={`${parent_id}_previousNextBodyName_h2`}
                  className="pc_semibold"
                  style={{"margin":"0px",
                  "fontSize": "14px",
                  "marginBottom":"4px", textAlign:"left"  }}
                >
                  {name ? name : ""}
                </H2>
              )}

              {role?.role && (
                <Text
                  name={role?.role ?? false}
                  id={`${parent_id}_previousNextBodyRole_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  {role?.role ?? ""}
                </Text>
              )}

              {role?.role && <br />}
              {time && (
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  {time ? time : ""}
                </Text>
              )}
            </Div>
          </Div>
          {this.props.role__}
          {!assignReassignPractitioner && (
            <Div
              className={"reassign"}
              style={{ marginLeft: "auto" }}
              onClick={
                this.props?.assignButtonAction
                  ? () => this.props.assignButtonAction()
                  : null
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  background: "#ebebeb",
                  height: "35px",
                  width: "35px",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  inLineStyles={{
                    height: "17px",
                    width: "16px",
                    marginLeft: "10px",
                  }}
                  src={this.props.assignButtonIcon}
                />
              </div>
            </Div>
          )}
          {/* CLOSE CONSULTATION ICON FOR ROLE=NURSE, TAB= COMPLETED, STATUS= CONSULTATION COMPLETED */}
          {this.props.completedView && this.props.val?.comment === "completed" && (
            <Div
              className={"reassign"}
              style={{ marginLeft: "auto" }}
              onClick={
                this.props?.closeButtonAction
                  ? () => this.props.closeButtonAction()
                  : null
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  background: "#ebebeb",
                  height: "35px",
                  width: "35px",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  inLineStyles={{
                    height: "17px",
                    width: "16px",
                    marginLeft: "10px",
                  }}
                  src={closeConsultation}
                />
              </div>
            </Div>
          )}
          {!markHighPriority && (
            <Div style={qdmstyles.highAlertPosition}>
              <Div
                style={{
                  backgroundColor:
                    priorityTotalData?.patientPriority?.priority?.value ===
                    "High"
                      ? "#FF4D4A"
                      : "#F9E6E6",
                  padding: "5px 8px 3px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={(event) => this.handleHighAlertPop(event)}
              >
                <Image
                  inLineStyles={{ height: "17px", width: "16px" }}
                  src={
                    priorityTotalData?.patientPriority?.priority?.value ===
                    "High"
                      ? iconHighAlert
                      : iconHigh
                  }
                />
              </Div>
            </Div>
          )}
          {this.props.completedView && (
            <Div
              style={{ display: "flex" }}
              id={`${parent_id}_token_number_div`}
              className="pc_medium"
            >
              {this.props.id === this.state.view && (
                <div className={classes.tooltip}>View Visit</div>
              )}
              {/* {JSON.stringify(this.props.val.appointmentType)} */}
              {!noviewVisit && (
                <Div
                  className={classes.main}
                  onMouseEnter={() => this.setIsShown("view", this.props.id)}
                  onMouseLeave={() => this.setIsShown(null, null)}
                >
                  <img
                    onClick={() => {
                      this.props.history.push({
                        pathname: Routes.patientVisitModify,
                        state: {
                          role: "view",
                          appointmentId: this.props.val.info.id,
                          patientId: this.props.val.patientId,
                          walkin: this.props.val.appointmentType === "walkin",
                          personId: this.props.val.personId,
                          appointment_Id: this.props.val.info._id,
                          encounter_id: this.props.val.encounter_id,
                          mobileNo: this.props.val.mobileNo,
                        },
                      });
                    }}
                    id={`${parent_id}_viewcard_view_eye_img`}
                    alt={"Eye"}
                    src={Eye}
                  />
                </Div>
              )}
              &nbsp;
              {this.props.id === this.state.modify && (
                <div className={classes.tooltip}>Revise Visit</div>
              )}
              {!nomodifyVisit && (
                <Div
                  className={classes.main}
                  onMouseEnter={() => this.setIsShown("modify", this.props.id)}
                  onMouseLeave={() => this.setIsShown(null, null)}
                >
                  {" "}
                  <img
                    onClick={() =>{
                      if (
                        this.props?.val?.encounter_status?.toLowerCase() ===
                          "checked-in" &&
                        this.props?.val?.info?.encounter?.[0]?.activestatus
                      ) {
                        this.props.history.push({
                          pathname: Routes.patientVisitModify,
                          state: {
                            role: "modify",
                            appointmentId: this.props.val.info.id,
                            patientId: this.props.val.patientId,
                            walkin: this.props.val.appointmentType === "walkin",
                            personId: this.props.val.personId,
                            appointment_Id: this.props.val.info._id,
                            encounter_id: this.props.val.encounter_id,
                            mobileNo: this.props.val.mobileNo,
                          },
                        });
                      } else {
                        this.props.alert.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: this.props?.val?.info?.encounter?.[0]
                            ?.activestatus
                            ? "Consult in-progress can't be Modified"
                            : "Visit has been cancelled cannot modify",
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                        });
                      }
                    }
                    }
                    id={`${parent_id}_viewcard_title_Modify_img`}
                    alt={"Modify"}
                    src={Modify}
                  />
                </Div>
              )}
              &nbsp;
              {this.props.id === this.state.cancel && (
                <div className={classes.tooltip}>Cancel Visit</div>
              )}
              {!nocancelVisit && (
                <Div
                  className={classes.main}
                  onMouseEnter={() => this.setIsShown("cancel", this.props.id)}
                  onMouseLeave={() => this.setIsShown(null, null)}
                >
                  
                  <img
                    onClick={() =>{
                   if (
                     this.props?.val?.encounter_status?.toLowerCase() ===
                       "checked-in" &&
                     this.props?.val?.info?.encounter?.[0]?.activestatus
                   ) {
                     this.props.history.push({
                       pathname: Routes.patientVisitCancel,
                       state: {
                         role: "cancel",
                         appointmentId: this.props.val.info.id,
                         patientId: this.props.val.patientId,
                         walkin: this.props.val.appointmentType === "walkin",
                         personId: this.props.val.personId,
                         appointment_Id: this.props.val.info._id,
                         encounter_id: this.props.val.encounter_id,
                         mobileNo: this.props.val.mobileNo,
                       },
                     });
                   } else {
                     this.props.alert.setSnack({
                       open: true,
                       severity: AlertProps.severity.error,
                       msg: this.props?.val?.info?.encounter?.[0]
                            ?.activestatus ? "Consult in-progress can't be cancelled" :"Visit Already cancelled",
                       vertical: AlertProps.vertical.top,
                       horizontal: AlertProps.horizontal.right,
                     });
                   }
                    }
                    }
                    id={`${parent_id}_viewcard_title_Cancel_Appointment_img`}
                    alt={"CancelAppointment"}
                    src={CancelAppointment}
                  />
                </Div>
              )}
              &nbsp;
            </Div>
          )}
          {token && (
            <Div
              id={`${parent_id}_token_number_div`}
              className="pc_medium"
              style={qdmstyles.tokenNumber}
            >
              {token ? token : ""}
            </Div>
          )}
          {type === "All" && (
            <Div
              id={`${parent_id}_all_div`}
              onClick={() => {
                if (showBtn) {
                  this.pushToTop(id);
                }
              }}
              className="pc_medium"
              style={{
                marginLeft: "10px",
                color: showBtn ? "#000" : "#d3d3d3",
              }}
            >
              <EjectIcon />
            </Div>
          )}
          {isHold && (
            <Div
              id={`${parent_id}_hold_div`}
              onClick={async() => {
                
                this.removeFromHold(
                  id,
                  this.props?.role_ === "nurse"
                    ? "completed_assemble"
                    : this.props?.role_ === "doctor"
                    ? "completed_nurse"
                    : "string",
                  this.props?.role_,
                  this.props?.role_ === "nurse" ? 2 : 1,
                  this.props.loggedUserInfo
                );
              }}
              className="pc_medium"
              style={{
                marginLeft: "10px",
              }}
            >
              <PlayArrowIcon />
            </Div>
          )}
        </Div>
        <Divider id={`${parent_id}_hold_divider`} style={qdmstyles.divider} />

        <Popover
          id="high_priority_pop_over"
          style={{ zIndex: "10000000" }}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={this.handleHighAlertPopClose}
          disableRestoreFocus
        >
          <HighAlertPopUp
            handleHighAlertPopClose={this.handleHighAlertPopClose}
            fullDataQueve={totalData}
            // priorityTotalData={key: totalData}
            priorityTotalData={priorityTotalData}
            handlePriority={this.handlePriority}
          />
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

export default withStyles(materialStyles)(
  connect(mapStateToProps, actions)(withRouter(withAllContexts(Queue)))
);
