/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, H6, Icons, Div, Image } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField, Typography } from "@material-ui/core";
import { Button, Chip, Tooltip } from "@material-ui/core";
import { InsightsIcon, InfoIcon, DeleteModal } from "../../../components";
import { DialogContext } from "../../../contexts";
import { withAllContexts } from "../../../HOCs";
import ChiefComplaintsJson from "./json";
import { useSelector, useDispatch } from "react-redux";
import {
  getData,
  getUserInfo,
  makeName,
  UIColor,
  AlertProps,
} from "../../../utils";
import { actions } from "primarycare-binder";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
const getColor = {
  Mild: UIColor.primaryColor.color,
  Moderate: UIColor.secondaryColor.color,
  Severe: UIColor.error.color,
};

const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 0px 10px",
    background: "#fff",
    borderRadius: 8,
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 10px 12px 20px",
    background: "#fff",
    borderRadius: 8,
  },

  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    flexWrap: "wrap",
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    //marginBottom: "24px !important",
    backgroundColor: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  inputStyle: {
    padding: 5,
    marginBottom: 10,
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: `1px solid ${UIColor.primaryColor.color}`,
    color: UIColor.primaryColor.color,
    background: "#F5FAFF",
    fontSize: "10px",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  borderStyle: {
    borderColor: UIColor.lineBorderFill.color,
    backgroundColor: `${UIColor.greyBackground.color} !important`,
    marginRight: 16,
  },
  borderNoneStyle: {
    borderColor: UIColor.greyBackground.color,
    backgroundColor: UIColor.greyBackground.color,
    marginRight: 16,
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 14,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noAccessTag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 6px",
    borderRadius: "4px",
    fontWeight: 400,
    marginLeft: 12,
    backgroundColor: "#FAD7D6",
    color: "#FF5654",
    fontSize: "12px",
  },
  textColor: {
    color: UIColor.tertiaryText.color,
    fontSize: 12,
    paddingLeft: 6,
  },
  textColor1: {
    fontSize: 12,
    color: UIColor.tertiaryText.color,
  },

  statusTag: {
    color: "#000",
    fontSize: 12,
    backgroundColor: UIColor.lineBorderFill.color,
    borderRadius: "4px",
    padding: "2px 6px",
    marginLeft: 6,
    width: "fit-content",
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  userInfoIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
  deleteAllergyIcon: {
    //padding: "6px 10px",
    //background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
    color: UIColor.error.color,
    marginRight: 10,
  },
  editAllergyIcon: {
    //padding: "6px 10px",
    //background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-11px",
  },
};

const errorCheck = [
  "name",
  "bodySite",
  "severity",
  "status_verification",
  "remarks",
];

const initialState = {
  complaint: "",
  name: "",
  bodySite: "",
  severity: "",
  assertedBy: "",
  relationName: "",
  onset: {
    day: "",
    freq: {},
    currentDate: "",
    isCalenderEnabled: false,
    calenderIconEnabled: true,
  },
  abatement: {
    day: "",
    freq: {},
    currentDate: "",
    isCalenderEnabled: false,
    calenderIconEnabled: true,
  },
  modifying_factor: "",
  status_verification: "",
  clinicalStatus: "",
  notes: "",
  remarks: [],
  statusType: "Inactive",
  headerShow: true,
};

const CheifComplaints = (props) => {
  const dispatch = useDispatch();
  let cheifComplaints = props.cheifComplaints;
  const dialog = React.useContext(DialogContext);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const storeData = useSelector((state) => state?.chiefCompilenceMastersSlice);
  const [cheifComplaintsEntry, setCheifComplaintsEntry] = React.useState({
    ...initialState,
  });
  const [masters, setMasters] = React.useState([]);
  console.log("storeData", storeData);

  let ccMasters = storeData?.cc_master_data?.data ?? [];
  const encounterData = storeData?.encounter_Details;
  const getChiefData = async () => {
    await dispatch(
      actions.ENCOUNTER_BASED_CC({ encounter_id: encounterData?.data?.eId })
    );
  };

  React.useEffect(() => {
    getChiefData();
    let datalist = cheifComplaints.list.map((val) => val?.name?.value || "");
    let masterData = ccMasters.filter(
      (val) => datalist.indexOf(val.value) === -1
    );
    setMasters(masterData);
  }, [ccMasters, cheifComplaints.list]);

  React.useEffect(() => {
    let datalist = cheifComplaints.list.map((val) => val?.name?.value || "");
    let masterData = ccMasters.filter(
      (val) => datalist.indexOf(val.value) === -1
    );
    setMasters(masterData);
  }, [storeData]);

  React.useEffect(() => {
    dialog.setDialog({
      title: "Are you sure want to remove this?",
      body: "Some content about the cheif complaints",
      positiveBtn: "Yes remove",
    });
  }, []);

  const [, setCheifComplaints] = React.useState({
    list: [],
  });

  React.useEffect(() => {
    if (ChiefComplaintsJson?.length) {
      setCheifComplaints({ list: ChiefComplaintsJson ?? [] });
      setCheifComplaintsEntry({
        ...cheifComplaintsEntry,
        headerShow: false,
      });
    }
  }, [ChiefComplaintsJson]);

  React.useEffect(() => {
    setCheifComplaintsEntry({
      ...cheifComplaintsEntry,
      headerShow: storeData.encounter_Based_CC.data.length > 0 ? false : true,
    });
  }, [storeData]);

  const deleteList = async (i, data) => {
    ////create addable complaint if last list
    if (cheifComplaints.list.length === 1) {
      setCheifComplaintsEntry({
        ...cheifComplaintsEntry,
        headerShow: true,
      });
    }
    dialog.setDialog({
      ...dialog,
      open: false,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Deleting Cheif Complaints",
    });
    await dispatch(actions.DELETE_CC({ id: data.editData._id }));
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    ///remove list
    cheifComplaints.list.splice(i, 1);
    cheifComplaints.setCheifComplaints({
      ...cheifComplaints,
    });
  };

  const handleInputChange = (e, name) => {
    if (name === "name") {
      setCheifComplaintsEntry({
        ...cheifComplaintsEntry,
        [name]: e,
      });
    } else {
      setCheifComplaintsEntry({
        ...cheifComplaintsEntry,
        [name]: e,
      });
    }
  };

  const addDisable = () => {
    let isDsabled = false;
    errorCheck.map((val) => {
      if (!cheifComplaintsEntry[val]) {
        isDsabled = true;
      }
    });
    return isDsabled;
  };

  const AddAllergy = async () => {
    debugger;
    if (addDisable() === false) {
      debugger;
      let CS = storeData?.clinicalStatus_masters?.data.filter(
        (val) => val.label === "Active"
      )?.[0];
      const info = getUserInfo();
      let list = {
        ...cheifComplaintsEntry,
        name: cheifComplaintsEntry.name,
        clinicalStatus: CS,
        createdName: info.name,
        createdAt: getData(),
        bodySite: cheifComplaintsEntry.bodySite,
        severity: cheifComplaintsEntry.severity,
        status_verification: cheifComplaintsEntry.status_verification,
        remarks: cheifComplaintsEntry?.remarks,
      };
      await props.saveCheifComp([list]);
      setCheifComplaintsEntry({
        ...cheifComplaintsEntry,
        name: "",
        bodySite: "",
        severity: "",
        status_verification: "",
        remarks: [],
        headerShow: !cheifComplaintsEntry.headerShow,
      });
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    // cheifComplaints.setCheifComplaints({ ...cheifComplaints })
  };

  const editFun = (index, val) => {
    let getFilterData = cheifComplaints.list.filter(
      (sys) => val.editData._id === sys.editData._id
    )[0];
    let editindex = cheifComplaints.list.indexOf(getFilterData);
    cheifComplaints.editIndex = editindex;
    cheifComplaints.setCheifComplaints({ ...cheifComplaints });
    forceUpdate();
    props.onEditChart({}, "cheif_complaints_form");
  };
  const handleClose = () => {
    if (storeData.encounter_Based_CC.data.length <= 0)
      return props.handleClose("cheif_complaints");

    setCheifComplaintsEntry({
      ...cheifComplaintsEntry,
      name: {},
      bodySite: {},
      severity: {},
      status_verification: {},
      remarks: [],
      headerShow: !cheifComplaintsEntry.headerShow,
    });
  };
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_cheif_complaints_parent_div`}
      style={{ width: "100%" }}
    >
      <Row
        id={`${parent_id}_cheif_complaints_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new allergy data's */}
        <Col
          id={`${parent_id}_cheif_complaints_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={
            cheifComplaintsEntry.headerShow
              ? styles.vitalsHead
              : styles.vitalsHead1
          }
        >
          {cheifComplaintsEntry.headerShow ? (
            <div
              id={`${parent_id}_cheif_complaints_div`}
              style={styles.vitalsfieldHead}
            >
              <>
                <div
                  id={`${parent_id}_cheif_complaints_title_div`}
                  style={{ marginRight: 16 }}
                >
                  {/* <Text
                                        id={`${parent_id}_cheif_complaints_title_text`}
                                        inLineStyles={styles.floatingLable} >Chief Complaints</Text> */}
                  <Autocomplete
                    style={{ width: 170 }}
                    id="size-small-standard"
                    size="small"
                    options={masters}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_cheif_complaints_Search_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Search Chief Complaints"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_cheif_complaints_${option.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    classes={{ popper: "muiPopperDropdown" }}
                    value={cheifComplaintsEntry.name}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "name")
                    }
                  />
                </div>
                <div
                  id={`${parent_id}_cheif_complaints_Body_site_div`}
                  style={{ marginRight: 16 }}
                >
                  <Autocomplete
                    style={{ width: 110 }}
                    id="size-small-standard"
                    size="small"
                    options={storeData?.bodysite_masters?.data ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_cheif_complaints_Body_site_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Body site"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_cheif_complaints_Body_site_${option.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    classes={{ popper: "muiPopperDropdown" }}
                    value={cheifComplaintsEntry.bodySite}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "bodySite")
                    }
                  />
                </div>
                <div
                  id={`${parent_id}_cheif_complaints_Severity_div`}
                  style={{ marginRight: 16 }}
                >
                  <Autocomplete
                    style={{ width: 100 }}
                    id="size-small-standard"
                    size="small"
                    options={storeData?.Severity_masters?.data ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_cheif_complaints_Severity_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Severity"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_cheif_complaints_Severity_${option.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    classes={{ popper: "muiPopperDropdown" }}
                    value={cheifComplaintsEntry.severity}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "severity")
                    }
                  />
                </div>
                <div
                  id={`${parent_id}_cheif_complaints_Verification_div`}
                  style={{ marginRight: 16 }}
                >
                  <Autocomplete
                    style={{ width: 120 }}
                    id="size-small-standard"
                    size="small"
                    options={storeData?.Verification_masters?.data ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_cheif_complaints_Verification_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Verification"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_cheif_complaints_Verification_${option.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option.label}
                      </Typography>
                    )}
                    classes={{ popper: "muiPopperDropdown" }}
                    value={cheifComplaintsEntry.status_verification}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "status_verification")
                    }
                  />
                </div>
                <div
                  id={`${parent_id}_cheif_complaints_Add_Remarks_div`}
                  style={{
                    marginRight: 16,
                    width: "100px",
                    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
                  }}
                >
                  {/* <Autocomplete
                                        className={"addReaction"}
                                        style={{ width: 150 }}
                                        multiple
                                        freeSolo
                                        id="size-small-standard"
                                        size="small"
                                        options={[]}
                                        disableClearable
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(params) => (
                                            <TextField InputProps={{ disableUnderline: true }} {...params} variant="standard" placeholder="Add Remarks" />
                                        )}
                                        renderOption={(option) => <Typography style={{ width: "100%" }}>{option.label}</Typography>}
                                        closeIcon={<p>T</p>}
                                        renderTags={(value, getTagProps) => {
                                            return (<Chip size={"small"} style={{ borderRadius: 6 }} label={"Show +" + value.length} />)
                                        }
                                        }
                                        value={cheifComplaintsEntry.remarks}
                                        onChange={(e, newValue) => handleInputChange(newValue, "remarks")}
                                    /> */}
                  <TextField
                    id={`${parent_id}_cheif_complaints_Add_Remarks_textfield`}
                    InputProps={{ disableUnderline: true }}
                    variant="standard"
                    onChange={(e) =>
                      setCheifComplaintsEntry({
                        ...cheifComplaintsEntry,
                        notes: e.target.value,
                      })
                    }
                    value={cheifComplaintsEntry.notes}
                    placeholder="Add Remarks"
                  />
                </div>
              </>
            </div>
          ) : (
            <div
              id={`${parent_id}_cheif_complaints_headerShow_div`}
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "space-between",
              }}
            >
              <Text
                id={`${parent_id}_cheif_complaints_headerShow_text`}
                onClick={() =>
                  handleInputChange(
                    !cheifComplaintsEntry.headerShow,
                    "headerShow"
                  )
                }
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                  color: UIColor.primaryColor.color,
                }}
              >
                {"Chief Complaints"}
              </Text>
              <div
                onClick={() =>
                  handleInputChange(
                    !cheifComplaintsEntry.headerShow,
                    "headerShow"
                  )
                }
                style={{ cursor: "pointer", display: "flex" }}
              >
                <img src={Addicon} alt="Add" />
              </div>
            </div>
          )}
          <div
            id={`${parent_id}_cheif_complaints_cheifComplaintsEntry_div`}
            style={styles.flexFlow}
          >
            {cheifComplaintsEntry.headerShow && (
              <>
                {/* {addDisable() ? */}
                <div
                  id={`${parent_id}_cheif_complaints_Add_button_div`}
                  style={{
                    width: "100%",
                    display: "flex",
                    marginRight: "10px",
                  }}
                >
                  <Button
                    id={`${parent_id}_cheif_complaints_Add_button`}
                    onClick={() => AddAllergy()}
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "30%",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                    size={"small"}
                  >
                    <img src={Tick} alt="Add" />
                  </Button>
                  <Button
                    id={`${parent_id}_allergy_add_button`}
                    onClick={() => handleClose()}
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "30%",
                      padding: "5px",
                    }}
                    size={"small"}
                  >
                    <img src={Cancel} alt="Cancel" />
                  </Button>
                </div>
                {/* :
                                <div
                                    id={`${parent_id}_cheif_complaints_Add_text_div`}
                                    style={{ width: "100%" }}>
                                    <Text
                                        id={`${parent_id}_cheif_complaints_Add_text`}
                                        inLineStyles={{ color: UIColor.primaryColor.color, fontWeight: 600, minWidth: "30%",
                                        padding: "5px",
                                        marginRight: "10px"
                   }} ><img src={Tick} alt="Add" /></Text>
                                </div>
                            } */}
              </>
            )}
            {/* <Tooltip
                            id={`${parent_id}_cheif_complaints_Insights_tooltip`}
                            title={"Insights"}>
                            <div
                                id={`${parent_id}_cheif_complaints_Insights_icon_div`}
                                style={{ ...styles.infoBell, ...styles.icons }}>
                                <InsightsIcon
                                    id={`${parent_id}_cheif_complaints_Insights_icon`}
                                    style={{ marginLeft: 5 }}
                                // color={UIColor.primaryColor.color} 
                                />
                            </div>
                        </Tooltip> */}
          </div>
        </Col>

        {/* allergy list */}
        {storeData.encounter_Based_CC.data.length > 0 &&
          storeData.encounter_Based_CC.data.map((val, i) => {
            // if (!val.isNew) {
            //     return <React.Fragment />
            // }
            return (
              <Col
                id={`${parent_id}_cheif_complaints_severity_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={"allergyListHover"}
                inLineStyles={{
                  padding: "8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                }}
              >
                <Row id={`${parent_id}_cheif_complaints_severity_row`}>
                  <Col
                    id={`${parent_id}_cheif_complaints_severity_sub_col`}
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                  >
                    {val.name && (
                      <H6
                        id={`${parent_id}_cheif_complaints_severity_${val.name?.label}_h6`}
                        inLineStyles={{
                          fontSize: 14,
                          paddingBottom: 6,
                          color: UIColor.secondaryButtonColor.color,
                          fontFamily: "pc_regular",
                        }}
                      >
                        {val.name?.label}
                      </H6>
                    )}
                    <div
                      id={`${parent_id}_cheif_complaints_severity_${val.severity?.label}_div`}
                      style={{ display: "flex" }}
                    >
                      {val.severity && (
                        <div
                          id={`${parent_id}_cheif_complaints_severity_${val.severity?.label}_sub_div`}
                          style={{
                            display: "flex",
                            padding: 0,
                            alignItems: "flex-start",
                            color: UIColor.tertiaryText.color,
                          }}
                          className={`${val.severity}`}
                        >
                          <Text>
                            <InfoIcon
                              id={`${parent_id}_cheif_complaints_severity_infoicon`}
                              width={12}
                              style={{ marginRight: 6 }}
                              color={getColor[val.severity]}
                            />
                          </Text>
                          <Text
                            id={`${parent_id}_cheif_complaints_${val.severity?.label}_text`}
                            style={{ fontSize: 12 }}
                            className={`${val.severity}`}
                          >
                            {val.severity?.label}
                          </Text>
                        </div>
                      )}
                      <div
                        id={`${parent_id}_cheif_complaints_bodysite_div`}
                        inLineStyles={styles.textColor}
                        style={{ display: "flex", padding: 0 }}
                        className={`${val.severity}`}
                      >
                        {val.bodySite && (
                          <Text
                            id={`${parent_id}_cheif_complaints_${val.bodySite?.label}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.bodySite?.label}
                          </Text>
                        )}
                        {/* {val.remarks.length > 0 && <Text inLineStyles={styles.textColor}>{" - "}{val.remarks.join().replaceAll(",", ", ")}</Text>} */}
                        {val.status_verification && (
                          <Text
                            id={`${parent_id}_cheif_complaints_${val.status_verification?.label}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.status_verification?.label}
                          </Text>
                        )}
                        {val.currentStatus && (
                          <Text
                            id={`${parent_id}_cheif_complaints_${val.currentStatus?.label}_text`}
                            inLineStyles={styles.statusTag}
                          >
                            {val.currentStatus?.label}
                          </Text>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col
                    id={`${parent_id}_cheif_complaints_userInfoEdit_col`}
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <Div
                      id={`${parent_id}_cheif_complaints_userInfoEdit_div`}
                      className={"userInfoEdit_"}
                    >
                      <div
                        id={`${parent_id}_cheif_complaints_on_div`}
                        style={styles.userInfo}
                        className={"userHide"}
                      >
                        <Text
                          id={`${parent_id}_cheif_complaints_${
                            val?.createdAt ?? getData()
                          }_text`}
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {val?.createdAt ?? getData()}
                        </Text>
                        <Text
                          id={`${parent_id}_cheif_complaints_${val?.createdName}_text`}
                          inLineStyles={styles.textColor}
                        >
                          {makeName(val?.createdName || {}) + " (You)" ??
                            "(You)"}
                        </Text>
                      </div>
                      <div
                        id={`${parent_id}_cheif_complaints_delete_edit_div`}
                        style={{ ...styles.userInfoIcon, marginLeft: 24 }}
                      >
                        <Text
                          id={`${parent_id}_cheif_complaints_delete_text`}
                          inLineStyles={styles.deleteAllergyIcon}
                          onClick={() => {
                            dialog.setDialog({
                              ...dialog,
                              open: true,
                              onOk: () => deleteList(i, val),
                            });
                          }}
                        >
                          {/* <Icons
                                                    id={`${parent_id}_cheif_complaints_delete_icons`}
                                                    fontIcon={"trash-o"} /> */}
                          <Image
                            src={"images/icons8-trash_.svg"}
                            alt="delete_icon"
                            // style={{ width: 100, height: 35 }}
                          />
                        </Text>
                        <Text
                          id={`${parent_id}_cheif_complaints_edit_text`}
                          inLineStyles={styles.editAllergyIcon}
                          onClick={() => editFun(i, val)}
                        >
                          {/* <Icons
                                                    id={`${parent_id}_cheif_complaints_edit_icons`}
                                                    fontIcon={"pencil"} /> */}
                          <Image
                            src={"images/icons8-edit_.svg"}
                            alt="delete_icon"
                            // style={{ width: 100, height: 35 }}
                          />
                        </Text>
                      </div>
                    </Div>
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default withAllContexts(CheifComplaints);
