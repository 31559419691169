import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getDateAndTime } from "../../utils";
import TableComponent from "../cashierBillingDetails/tableComponent";
import BillingClinicDetails from "./billingClinicDetails";
import BillingInfo from "./billingInfo";
import { dataType, Header, tableData } from "./dummyJson";
import PatientBillingDetails from "./patientBillingDetails";
import { useStyles } from "./styles";

const CashierBillingSummary = (props) => {
  const classes = useStyles();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  // const dataPass = useSelector(
  //   (state) => state?.billSettlementSlice?.bill_reciept_read
  // );
  const dataPass = props?.location?.state?.demoRecieptDetails?.payload;

  console.log("dataPass", dataPass);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [height, setheight] = useState("0px");
  const [pages, setPages] = useState(0);
  console.log("pages", pages);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    // debugger;
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");
    debugger;
    const height = componentRef.current.clientHeight;
    const page = height / 850;
    setPages(Math.ceil(page));
    setheight(componentRef.current.clientHeight);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const extra = [
    "",
    "",
    "Total",
    dataPass?.data?.totgrossamt,
    dataPass?.data?.totdiscamt,
    dataPass?.data?.totnetamt,
    dataPass?.data?.totpatientshare,
    dataPass?.data?.totpayershare,
  ];
  const currenTableData = dataPass?.data?.billDetails;
  console.log(
    "demoRecieptDetailsIn Page",
    props?.location?.state?.demoRecieptDetails
  );
  return (
    <>
      <div ref={componentRef}>
        <div className={classes.root}>
          {/* <header> */}
            <BillingClinicDetails dataPass={dataPass.data} />
          {/* </header> */}
          <section>
            <div className={classes.patientInfoSection}>
              <PatientBillingDetails
                dataPass={dataPass.data}
                encounterNo={props?.location?.state?.encounterNo}
              />
            </div>
            <div className={classes.patientInfoSection}>
              <BillingInfo dataPass={dataPass.data} forInsurance={true} />
            </div>
            <TableComponent
              dataType={dataType}
              Header={Header}
              tableData={currenTableData}
              headerSize={"12px"}
              isPrintTotalData={true}
              printTotalData={extra}
            />
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 10px 0px" }}
              id="lower-print"
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888" }}
              >
                Net Payable Amt :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {dataPass?.data?.totpayershare}
              </Typography>
            </div>
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 0px 0px" }}
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888", textAlign: "right" }}
              >
                Total Paid Amt :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {0.0}
              </Typography>
            </div>
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 0px 0px" }}
              id="payerLast"
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888" }}
              >
                Outstanding Amt :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {dataPass?.data?.totpayershare}
              </Typography>
            </div>

            {/* for Patient */}
            <div id="payerBill">
                <BillingClinicDetails dataPass={dataPass.data} />{" "}
              </div>
            <div className={classes.patientInfoSection} id="payer">
              <PatientBillingDetails
                dataPass={dataPass.data}
                encounterNo={props?.location?.state?.encounterNo}
              />
            </div>
            <div className={classes.patientInfoSection}>
              <BillingInfo dataPass={dataPass.data} forInsurance={false} />
            </div>
            <TableComponent
              dataType={dataType}
              Header={Header}
              tableData={currenTableData}
              headerSize={"12px"}
              isPrintTotalData={true}
              printTotalData={extra}
            />
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 10px 0px" }}
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888" }}
              >
                Bill Rounding Amt : :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {dataPass?.data?.roundoffamt}
              </Typography>
            </div>
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 10px 0px" }}
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888" }}
              >
                Net Payable Amt :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {dataPass?.data?.billamt}
              </Typography>
            </div>
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 0px 0px" }}
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888", textAlign: "right" }}
              >
                Total Paid Amt :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {dataPass?.data?.paidamt}
              </Typography>
            </div>
            <div
              className={classes.alignItems}
              style={{ justifyContent: "end", padding: "10px 30px 0px 0px" }}
            >
              <Typography
                className={classes.label}
                style={{ color: "#6A7888" }}
              >
                Outstanding Amt :{" "}
              </Typography>
              <Typography
                className={classes.labelValue}
                style={{ width: "80px", textAlign: "right" }}
              >
                {dataPass?.data?.balamt}
              </Typography>
            </div>
            <div style={{paddingLeft:"15px",marginBottom:"20px"}}>
              <Typography style={{ color: "#2A3C50", fontSize: "12px",paddingBottom:"8px" }}>
                Payment details
              </Typography>
              <div style={{display:"flex",gap:"8px"}}>
              <table style={{border:"1px solid #DEE5EC",borderCollapse:"collapse"}}>
                <tr>
                  <td style={{border:"1px solid #DEE5EC",borderCollapse:"collapse",padding:"8px"}}><Typography style={{ color: "#2A3C50", fontSize: "12px" }}>Receipt No</Typography></td>
                  <td style={{border:"1px solid #DEE5EC",borderCollapse:"collapse",padding:"8px"}}><Typography style={{ color: "#001C3C", fontSize: "12px",fontWeight:600 }}>{dataPass?.data?.recieptNo} / {dataPass?.data?.recieptDate}</Typography></td>
                </tr>
                <tr style={{border:"1px solid #DEE5EC",borderCollapse:"collapse"}}>
                  <td style={{border:"1px solid #DEE5EC",borderCollapse:"collapse",padding:"8px"}}>{""}</td>
                  <td style={{border:"1px solid #DEE5EC",borderCollapse:"collapse",padding:"8px"}}>{""}</td>
                </tr>
              </table>
              <Typography style={{ color: "#2A3C50", fontSize: "12px",paddingTop:"8px" }}>{dataPass?.data?.paidamt}</Typography>
              </div>
            </div>
          </section>
          {/* --------------- footer ----------------- */}
          <footer>
            <div
              style={{
                margin: "10px 16px 12px", 
                backgroundColor: "#DEE5EC",
                borderRadius: "4px",
                padding: "6px 8px",
              }}
              id="footer-break"
            >
              <div className={classes.alignItems} style={{ gap: "8px" }}>
                <Typography className={classes.queryText}>
                  For any query on your bill, Please call - (917-29988899)
                </Typography>
                <img
                  src="images/icons8-clock-black.svg"
                  alt="clock"
                  width={"10px"}
                />
                <div className={classes.alignItems} style={{ gap: "0px" }}>
                  <Typography className={classes.queryText}>
                    (MON - FRI)
                  </Typography>
                  <Typography
                    className={classes.queryText}
                    style={{ fontWeight: 600 }}
                  >
                    9:00 - 17:00
                  </Typography>
                </div>
                <img
                  src="images/icons8-clock-black.svg"
                  alt="clock"
                  width={"10px"}
                />
                <div className={classes.alignItems} style={{ gap: "0px" }}>
                  <Typography className={classes.queryText}>(SAT)</Typography>
                  <Typography
                    className={classes.queryText}
                    style={{ fontWeight: 600 }}
                  >
                    {" "}
                    9:00 - 13:00
                  </Typography>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ECF0F7",
                padding: "11px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="page"
            >
              <div className={classes.alignItems}>
                <h2 id="page-number"  className={classes.queryText}  >Page :</h2>
                {/* <Typography className={classes.queryText}>{pages}</Typography> */}

                <div className="pageFooter" style={{ fontWeight: 600 }}></div>
              </div>
              <div className={classes.alignItems}>
                <Typography className={classes.queryText}>Cashier :</Typography>
                <Typography
                  className={classes.queryText}
                  style={{ fontWeight: 600 }}
                >
                  Nancy Willis
                </Typography>
              </div>
              <div className={classes.alignItems}>
                <Typography className={classes.queryText}>Counter :</Typography>
                <Typography
                  className={classes.queryText}
                  style={{ fontWeight: 600 }}
                >
                  -
                </Typography>
              </div>
              <div className={classes.alignItems}>
                <Typography className={classes.queryText}>
                  Print Date :
                </Typography>
                <Typography
                  className={classes.queryText}
                  style={{ fontWeight: 600 }}
                >
                  {getDateAndTime(Math.floor(new Date() / 1000), false, false)}
                </Typography>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <Button variant="contained" onClick={handlePrint}>
        Print
      </Button>
    </>
  );
};

export default CashierBillingSummary;
